import React, { useState, useMemo, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { getMoment } from "Libs/NwMoment";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Alert, Modal } from 'antd'; //Spin
import styled from 'styled-components';
//import Axios from 'axios';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import BookingCalendarHeader from 'Components/Schedule/Calendar/BookingCalendarHeader';
import EventDrawer from 'Components/EventDrawer/EventDrawer';
//import MonthEventCard from 'Components/Schedule/Calendar/MonthEventCard';
import EventCard from 'Components/Schedule/Calendar/EventCard';
import CalendarWrapper from 'Components/Schedule/Calendar/CalendarUI';
//import AddNewEventModal from 'Components/Schedule/Calendar/ModelCalendar/AddNewEventModal';
//import { NwButton } from 'Components/Gui/NwButton';
import NwIcon from 'Components/Gui/NwIcon';
import { eventPropGetter, modelCalendarParseEvents } from 'Libs/Calendar';
import { ConfigContext } from 'Contexts/ConfigContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
//import { useMoveTravel, useMoveGroupedTravel, forceReloadTravel } from 'Hooks/Event/UseTravel';
//import { forceReloadFollowup, useMoveFollowup, useMoveGroupedFollowup } from 'Hooks/Event/UseFollowup';
//import { forceReloadExternalJob, useMoveExternalJob } from 'Hooks/Event/UseExternalJob';
import { getContactName } from 'Hooks/Contact/UseContactsList';
//import { forceReloadEvent, useMoveBookingEvent } from 'Hooks/Event/UseEvent';
import { getAgendaDefaultDate, getCalendarDefaultDate, getScheduleEvents } from 'Components/Schedule/ScheduleUtils';
//import { Loading } from 'Components/Gui/Loading';
import DailyCalendarDrawer from 'Components/Schedule/Calendar/DailyCalendarDrawer';
import ContactAvatar from 'Components/ContactAvatarGroup/ContactAvatar';
import ModelCalendarHeader from 'Components/Schedule/Calendar/ModelCalendarHeader';
import ModelDrawer from 'Components/ContactDetails/ModelDetails/Drawer/ModelDrawer';
import { UserContext } from 'Contexts/UserContext';
import MonthEventCard from '../MonthEventCard';
import { useStateValue } from 'Contexts/StateProvider';
import moment from 'moment';
import { UpdateCalendarContext } from 'Contexts';

const ModelBigCalendar = Calendar
const localizer = momentLocalizer(moment);

// const CalendarView = styled.div`
//     position: relative;

//     .ant-spin {
//         max-height: unset !important;
//     }
// `;

// const StyledAlert = styled(Alert)`
//     position: absolute;
//     z-index: 20;
//     top: 40%;
//     left: 50%;
//     box-shadow: 0px 6px 16px rgb(0 0 0 / 12%);
//     transform: translate(-50%, -50%);

//     .ant-alert-message {
//         margin-right: 8px;
//     }
// `;

const StyledModal = styled(Modal)`
    .ant-modal-header {
        border-bottom: none;
        padding-bottom: 0;
    }

    .multi-event-modal-container {
        ul > li {
            font-weight: bold;
        }
        
        .multi-event-modal-buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-height: 5rem;
        }
    }
`;

const CalendarBefore = styled.div`
    padding-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    position: sticky;
    top: 4rem;
    background-color: white;
    z-index: 1000;

    .calendar-before-back {
        font-size: 2rem;
        border-right: ${props => props.theme.DefaultBorder};
        padding: 0 1rem 0 .25rem;
        margin-right: 1rem;
    }

    .calendar-before-avatar {
        cursor: pointer;
        padding-right: 1rem;
    }

    .calendar-before-name {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;

        h3 {
            font-size: 1.5rem;
            line-height: 1em;
            font-weight: bold;
            margin: 0 0 1rem 0;
            text-transform: capitalize;
            cursor: pointer;
        }
        svg {
            margin-left: 1rem;
        }
    }
`

const ModelCalendarNoTimeGrid = ({
    afterReload,
    bookingSection,
    calendarStyle,
    contactId,
    filters,
    onClearContactID,
    onReload,
    onScheduleRangeChange,
    params,
    previewMode,
    reload,
    scheduleRange,
    setTimeGrid,
}) => {

    const [{ areaContext }] = useStateValue();
    const history = useHistory();

    const [selectedEvent, setSelectedEvent] = useState();
    const [showEventDrawer, setShowEventDrawer] = useState(false);
    //const [showAddNewEventDialog, setShowAddNewEventDialog] = useState(false);
    const [defaultEventDate, setDefaultEventDate] = useState();
    const [allDay, setAllDay] = useState(false);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [isEventDrawer, setIsEventDrawer] = useState(false);
    const [eventType, setEventType] = useState();

    const { userGuiConfig } = useContext(ConfigContext);
    const { currentUser } = useContext(UserContext);

    const breakpoints = useNwBreakPoints();
    const [expanded, setExpanded] = useState(true);
    //const [showAlert, setShowAlert] = useState(false);
    //const [showMultiAddModal, setShowMultiAddModal] = useState(false);
    const [errorModal, setErrorModal] = useState("");

    //const [changeableEvent, setChangeableEvent] = useState();
    const [isFetching, setIsFetching] = useState(false);

    const [modelDetailsDrawerID, setModelDetailsDrawerID] = useState(null)

    const [dayViewDate, setDayViewDate] = useState(false)

    const updateCalendarContext = useContext(UpdateCalendarContext)

    // useEffect(() => {
    //     if (userGuiConfig.calendar.weekFormat === 'monday') {
    //         moment.locale('us', {
    //             week: {
    //                 dow: 1,
    //                 doy: 1
    //             }
    //         });
    //     } else {
    //         moment.locale('us', {
    //             week: {
    //                 dow: 0,
    //                 doy: 1
    //             }
    //         });
    //     }
    // }, [userGuiConfig.calendar.weekFormat])

    moment.locale('us', {
        week: {
            dow: 1,
            doy: 1
        }
    });

    useEffect(() => {
        if (scheduleRange || updateCalendarContext.updateCalendar) {
            fetchEvents()
            if (updateCalendarContext.updateCalendar) {
                updateCalendarContext.setUpdateCalendar(false)
            }
        }
    }, [params, scheduleRange, updateCalendarContext.updateCalendar])

    useEffect(() => {
        if (scheduleRange && reload) {
            fetchEvents()
        }
    }, [reload])

    const fetchEvents = async () => {
        setIsFetching(true)
        const scheduleEvents = await getScheduleEvents(params, scheduleRange)
        setCalendarEvents(modelCalendarParseEvents(scheduleEvents))
        setIsFetching(false)
        afterReload()
    }

    const reloadCalendar = () => {
        //console.log("calendar has to be reloaded")
        fetchEvents()
    }

    const onSelectEvent = event => {
        if (event.ID && event.elType !== 'Package' && !previewMode) {
            setShowEventDrawer(true)
            setSelectedEvent(event)
        }
    }

    const handleCloseEventDrawer = () => {
        reloadCalendar()
        setShowEventDrawer(false)
        setSelectedEvent(null)
    }

    const handleCloseEventDrawerNoReload = () => {
        setShowEventDrawer(false)
        setSelectedEvent(null)
    }

    const afterRemoveEvents = () => {
        setShowEventDrawer(false)
        setSelectedEvent(null)
        reloadCalendar()
    }

    // const handleSelectSlot = timeSlot => {
    //     setDefaultEventDate(timeSlot.start)
    //     if (timeSlot.slots.length === 1) {
    //         setAllDay(true)
    //     }
    //     setShowAddNewEventDialog(true)
    // }

    // const handleCloseAddNewEventDialog = () => {
    //     setDefaultEventDate()
    //     setAllDay(false)
    //     setShowAddNewEventDialog(false)
    // }

    // const handleCreateEvent = (type) => {
    //     setShowAddNewEventDialog(false)
    //     setEventType(type)
    //     setIsEventDrawer(true)
    // }

    const handleRangeChange = (event) => {
        if (event.start && event.end) {
            onScheduleRangeChange(event.start, event.end)
        } else {
            onScheduleRangeChange(event[0], event[event.length - 1])
        }
    }

    const filteredEvents = useMemo(() => {
        let filteredEvents = [...calendarEvents]
        if (filters) {
            if (filters.filterAcknowledgement === 'checked') {
                filteredEvents = filteredEvents.filter(event => event.Acknowledgment === 'Checked')
            } else if (filters.filterAcknowledgement === 'tocheck') {
                filteredEvents = filteredEvents.filter(event => event.Acknowledgment === 'NotChecked')
            }
            if (filters.filterMine === 'mine') {
                //filteredEvents = filteredEvents.filter(event => event.UserCreated === currentUser.UserID);
                filteredEvents = filteredEvents.filter(event => event.UserCreated === currentUser.UserID || (event.Project && event.Project.UserCreated === currentUser.UserID));
            }
            if (filters.hideCancelled) {
                filteredEvents = filteredEvents.filter(event => !event.Cancel)
            }
        }
        return filteredEvents
    }, [calendarEvents, filters])

    useEffect(() => {
        const isInvisibleEvent = filteredEvents.find(event => (getMoment(event.start).hour() < 6 || getMoment(event.end).hour() >= 22) && !event.allDay)
        if (isInvisibleEvent && !expanded) {
            setExpanded(true)
        }
    }, [filteredEvents])

    const switchToAgenda = () => {
        const agendaSelectedDate = getAgendaDefaultDate(scheduleRange)
        onScheduleRangeChange(agendaSelectedDate.toDate(), agendaSelectedDate.toDate())
    }

    const getDailyDrawerScheduleRange = () => {
        return { start: dayViewDate, end: dayViewDate }
    }

    const getCalendarHeader = (toolbar) => {
        if (bookingSection) {
            return (<BookingCalendarHeader
                calendarStyle={calendarStyle}
                model
                onSwitchToAgenda={switchToAgenda}
                params={params}
                previewMode={previewMode}
                setTimeGrid={setTimeGrid}
                timeGrid={false}
                toolbar={toolbar}
            />)
        } else {
            return (<ModelCalendarHeader
                calendarStyle={calendarStyle}
                onSwitchToAgenda={switchToAgenda}
                previewMode={previewMode}
                toolbar={toolbar}
            />)
        }
    }

    const openModelDrawer = () => {
        setModelDetailsDrawerID(contactId)
    }

    const goToModelPage = () => {
        if (areaContext.area === 'booking') {
            history.push(`/models/${contactId}`);
        } else {
            history.push(`/${areaContext.area}-models/${contactId}`);
        }
    };

    return (
        <CalendarWrapper
            showTimeGrid={false}
            breakpoints={breakpoints}
            agendaWeeklyView={!expanded && calendarStyle === 'week'}
            minHeight={bookingSection ? null : 'calc(100vh - 25rem)'}
            bookingSection={bookingSection}
        >
            <div className='general-calendar model-calendar'>
                <div className={`calendar${calendarStyle === 'week' ? ' calendar-custom-week' : ''}`}>
                    <CalendarBefore className="calendar-before">
                        <div className="calendar-before-back">
                            <NwIcon pointer icon={light('arrow-left-to-line')} onClick={onClearContactID} />
                        </div>
                        <div className="calendar-before-avatar">
                            <ContactAvatar onClick={goToModelPage} contactID={contactId} />
                        </div>
                        <div className="calendar-before-name">
                            <h3 onClick={goToModelPage}>{getContactName(contactId).toLowerCase()}</h3>
                            <NwIcon pointer icon={light('sidebar-flip')} onClick={openModelDrawer} />
                        </div>
                    </CalendarBefore>
                    {
                        (scheduleRange && scheduleRange.start && scheduleRange.end)
                            ?
                            <ModelBigCalendar
                                popup
                                formats={{
                                    timeGutterFormat: userGuiConfig.calendar.timeFormat === '24' ? 'H:mm' : 'h:mm a'
                                }}
                                selectable="ignoreEvents"
                                events={filteredEvents}
                                step={30}
                                timeslots={2}
                                localizer={localizer}
                                //scrollToTime={getMoment(startDate).toDate()}
                                views={{ week: true, month: true }}
                                defaultView={calendarStyle}
                                tooltipAccessor={null}
                                components={{
                                    event: (event) => {
                                        if (calendarStyle === 'month') {
                                            return (<MonthEventCard model event={event.event} />)
                                        } else {
                                            return (
                                                <EventCard
                                                    event={event.event}
                                                    model
                                                    modelNoTimeGrid
                                                    agendaView />
                                            )
                                        }
                                    },
                                    toolbar: (toolbar) => getCalendarHeader(toolbar)
                                }}
                                allDayAccessor={(event) => {
                                    return event.onAllDaySection
                                }}
                                defaultDate={getCalendarDefaultDate(scheduleRange).toDate()}
                                onSelectEvent={onSelectEvent}
                                //onSelectSlot={handleSelectSlot}
                                onRangeChange={handleRangeChange}
                                eventPropGetter={eventPropGetter}
                                onDrillDown={value => {
                                    setDayViewDate(value)
                                }}
                            />
                            :
                            <></>
                    }
                </div>
            </div>
            {dayViewDate &&
                <DailyCalendarDrawer
                    params={params}
                    filters={filters}
                    scheduleRange={getDailyDrawerScheduleRange()}
                    onClose={() => setDayViewDate(null)}
                />
            }
            {showEventDrawer && selectedEvent &&
                <EventDrawer
                    eventType={selectedEvent.TypeName}
                    eventID={selectedEvent.ID}
                    modelId={contactId}
                    eventObject={selectedEvent}
                    onCloseNoReload={handleCloseEventDrawerNoReload}
                    onClose={handleCloseEventDrawer}
                    afterUpdate={() => {
                        //moved to onClose
                        //fetchEvents()
                    }}
                    afterRemove={afterRemoveEvents} />
            }
            {/* {showAddNewEventDialog &&
                <AddNewEventModal
                    contactId={contactId}
                    defaultEventDate={defaultEventDate}
                    calendarEvents={calendarEvents}
                    onCreateEvent={handleCreateEvent}
                    onFetchEvents={fetchEvents}
                    onCloseModal={handleCloseAddNewEventDialog} />
            } */}
            {isEventDrawer &&
                <EventDrawer
                    eventType={eventType.type}
                    show={true}
                    modelId={contactId}
                    eventInfo={eventType.eventInfo}
                    viewMode='create'
                    allDay={allDay}
                    dateSelected={getMoment(defaultEventDate)}
                    onClose={() => {
                        setIsEventDrawer(false);
                        setEventType();
                        setDefaultEventDate();
                        setAllDay(false);
                    }}
                    afterUpdate={() => {
                        fetchEvents();
                    }}
                    afterRemove={() => {
                        setIsEventDrawer(false);
                        fetchEvents();
                        setDefaultEventDate();
                        setAllDay(false);
                    }} />
            }
            {errorModal &&
                <StyledModal
                    open
                    width={480}
                    title="Error on drag"
                    footer={null}
                    closable
                    onCancel={() => setErrorModal("")}
                >
                    <div className="multi-event-modal-container">
                        <p>{errorModal}</p>
                    </div>
                </StyledModal>
            }
            {modelDetailsDrawerID &&
                <ModelDrawer
                    modelId={modelDetailsDrawerID}
                    onClose={() => setModelDetailsDrawerID(null)}
                />
            }
        </CalendarWrapper>
    )
}

export default ModelCalendarNoTimeGrid
