import React, { useContext, useState } from "react";
import { Row, Col } from "antd";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getMoment } from "Libs/NwMoment";

import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import {
    NWContactSelector,
    NWSelect,
    NWInput,
    NWInputNumber,
    NWTextAreaCharCounter,
    NWDatePicker,
    NWSwitch,
    NWAgencyTypeSelector
} from "Components/Gui/NWForm/NwFormItems";
import { ListContext } from "Contexts/ListContext";
import { capitalize } from "Libs/NwUtils";
import { useAddAgency, useEditAgency } from 'Hooks/Contact/UseContactAgencies';
import { getCustomer, useModelsList } from "Hooks/Contact/UseContactsList";
import NwDivider from "Components/Gui/NwDivider";
import { datePickerBeforeOnChange } from "Libs/NwMoment";

const ModelAgencyForm = ({
    ModelAgency,
    contactID,
    agencyID,
    hideMother,
    exclusions = [],
    excludedModels,
    onCancel,
    onSave,
    onTouchForm
}) => {

    const { cachedList } = useContext(ListContext);
    const [isDropped, setIsDropped] = useState(ModelAgency ? ModelAgency.Dropped : false);
    const modelAgencyLabels = cachedList.modelAgencyLabels.items;
    const newEnter = ModelAgency ? false : true;
    const { data: modelList } = useModelsList();
    let disableMother = hideMother;

    const { mutateAsync: onAddAgency } = useAddAgency()
    const { mutateAsync: onEditAgency } = useEditAgency()

    const initialValues = {
        TypeName: hideMother ? "Other" : "Mother",
        DateIn: null,
        DateOut: null,
        ContractDate: null,
        OnGross: true,
        Dropped: false,
        ModelAgencyLabelID: null,
        Notes: "",
        Booker: "",
        Percentage: 0,
        Towns: [],
        ModelID: contactID,
        AgencyID: ''
    };

    if (agencyID) {
        initialValues.AgencyID = agencyID;
    }

    if (ModelAgency) {
        initialValues.AgencyID = ModelAgency.AgencyID;
        initialValues.ID = ModelAgency.ID;
        initialValues.TypeName = ModelAgency.TypeName;
        initialValues.DateIn = ModelAgency.DateIn;
        initialValues.DateOut = ModelAgency.DateOut;
        initialValues.ContractDate = ModelAgency.ContractDate;
        initialValues.OnGross = ModelAgency.OnGross;
        initialValues.Dropped = ModelAgency.Dropped;
        initialValues.ModelAgencyLabelID = modelAgencyLabels.findIndex(
            label => label.ModelAgencyType === ModelAgency.TypeName && label.ID === ModelAgency.ModelAgencyLabelID
        ) >= 0 ? ModelAgency.ModelAgencyLabelID : null;
        initialValues.Notes = ModelAgency.Notes;
        initialValues.Percentage = ModelAgency.Percentage;
        initialValues.Booker = ModelAgency.Booker;
        initialValues.Towns = ModelAgency.Towns
            ? ModelAgency.Towns.split(",") // Data comes in this format: "City1,City2,..."
            : [];
    }

    // Merge cities from cachedList with existing cities
    const cities = Array.from([
        ...new Set([
            ...cachedList.cities.items.map(city => city.Name),
            ...initialValues.Towns
        ])
    ]);

    const getDefaultCity = ID => {
        const currentCustomer = getCustomer(ID);
        return currentCustomer.C;
    };

    const saveModelAgencies = async (values, setSubmitting) => {
        const ID = initialValues.ID;
        const data = {
            ModelID: values.ModelID,
            AgencyID: values.AgencyID,
            Type: values.TypeName,
            DateIn: values.DateIn,
            DateOut: values.DateOut,
            ContractDate: values.ContractDate,
            OnGross: values.OnGross,
            Dropped: values.Dropped,
            ModelAgencyLabelID: values.ModelAgencyLabelID,
            Notes: values.Notes,
            Booker: values.Booker,
            Percentage: values.Percentage,
            Towns: values.Towns.join(",")
        };

        if (ID) {
            data.ID = ID
            await onEditAgency(data);
            onTouchForm(false);
            setSubmitting(false)
            onSave()
        } else {
            await onAddAgency(data);
            onTouchForm(false);
            setSubmitting(false)
            onSave()
        }
    };

    return (
        <div style={{ margin: "20px 0" }}>
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    saveModelAgencies(values, setSubmitting);
                }}
                validationSchema={Yup.object().shape({
                    TypeName: Yup.mixed().required("Content is required"),
                    ModelID: Yup.string().required('Content is required'),
                    AgencyID: Yup.string().required('Content is required'),
                    DateOut: Yup.date().nullable().when("DateIn", (DateIn, schema) => DateIn ? schema.min(DateIn, "DATE OUT must be greater than DATE IN") : schema)
                })}
            >
                {({ isSubmitting, values, setFieldValue, handleSubmit }) => (
                    <NwForm
                        values={values}
                        onTouchForm={onTouchForm}
                        onFinish={handleSubmit}
                        layout="vertical">


                        <Field
                            component={NWAgencyTypeSelector}
                            label="Type"
                            name="TypeName"
                            required
                            value={values.TypeName}
                            hideMother={disableMother}
                            onAfterChange={() => {
                                setFieldValue("ModelAgencyLabelID", null);
                            }}
                        />
                        <Field
                            component={NWSelect}
                            label="Label"
                            name="ModelAgencyLabelID"
                            allowClear={true}
                            value={values.ModelAgencyLabelID}
                            options={modelAgencyLabels.filter(label => label.ModelAgencyType === values.TypeName).map(el => {
                                return { key: el.ID, label: el.Name, value: el.ID };
                            })}
                        />

                        {/* AgencyID */}
                        <Row gutter={10}>
                            <Col lg={16} md={16} sm={12} xs={24}>
                                {!agencyID &&
                                    <Field
                                        component={NWContactSelector}
                                        label="Agency"
                                        disabled={!newEnter}
                                        exclusions={exclusions}
                                        name="AgencyID"
                                        activeTypes={['age']}
                                        activableTypes={['cli', 'srv']}
                                        value={values.AgencyID}
                                        onAfterChange={
                                            val => 
                                            {
                                                // If the agency has a default city, add it to towns
                                                const defaultCity = getDefaultCity(val);
                                                if (defaultCity) {
                                                    setFieldValue("Towns", [...values.Towns, defaultCity]);
                                                }
                                            }
                                        }
                                    />
                                }
                                {!contactID &&
                                    <Field
                                        component={NWContactSelector}
                                        label="Model"
                                        name="ModelID"
                                        activeTypes={['mod', 'sco']}
                                        value={values.ModelID}
                                        exclusions={excludedModels}
                                        onAfterChange={value => {
                                            const model = modelList.find(model => model.ID === value);
                                            if (model && model.MAID) {
                                                setFieldValue("TypeName", "Other");
                                                disableMother = true;
                                            } else {
                                                disableMother = false;
                                            }
                                        }}
                                    />
                                }
                            </Col>
                            <Col lg={8} md={8} sm={12} xs={24}>
                                <Field
                                    component={NWSwitch}
                                    label="Dropped"
                                    name="Dropped"
                                    defaultChecked={initialValues.Dropped ? true : false}
                                    checked={Boolean(values.Dropped)}
                                    onAfterChange={(value) => {
                                        setIsDropped(value)
                                        if (value) {
                                            setFieldValue("Percentage", 0)
                                            setFieldValue("OnGross", true)
                                            if (!values.DateOut) {
                                                setFieldValue("DateOut", getMoment(new Date()))
                                            }
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* Towns */}
                        <Field
                            component={NWSelect}
                            label="Towns"
                            name="Towns"
                            mode="tags"
                            value={values.Towns}
                            manipulateValue={val => {
                                return val.map(v => capitalize(v));
                            }}
                            options={cities.map(city => {
                                return { key: city, label: city, value: city };
                            })}
                        />
                        <Row gutter={10}>
                            <Col lg={8} md={8} sm={12} xs={24}>
                                {/* DateIn */}
                                <Field
                                    component={NWDatePicker}
                                    label="Date In"
                                    name="DateIn"
                                    format="MMM DD, YYYY"
                                    style={{ width: "100%" }}
                                    value={values.DateIn ? getMoment(values.DateIn) : null}
                                    manipulateValue={datePickerBeforeOnChange}
                                />
                            </Col>
                            <Col lg={8} md={8} sm={12} xs={24}>
                                {/* DateOut */}
                                <Field
                                    component={NWDatePicker}
                                    label="Date Out"
                                    name="DateOut"
                                    format="MMM DD, YYYY"
                                    style={{ width: "100%" }}
                                    value={values.DateOut ? getMoment(values.DateOut) : null}
                                    manipulateValue={datePickerBeforeOnChange}
                                />
                            </Col>
                        </Row>
                        <Field
                            component={NWInput}
                            label="Booker"
                            name="Booker"
                            type="text"
                            style={{ width: "100%" }}
                            value={values.Booker}
                        />
                        <Field
                            component={NWTextAreaCharCounter}
                            label="Notes"
                            name="Notes"
                            type="text"
                            value={values.Notes}
                            maxLength={2000}
                            autoSize={{ minRows: 2, maxRows: 10 }}
                        />
                        <NwDivider>Commission</NwDivider>
                        <Row gutter={10}>
                            <Col lg={4} md={4} sm={12} xs={24}>
                                {/* OnGross */}
                                <Field
                                    component={NWSwitch}
                                    label="On Gross"
                                    name="OnGross"
                                    defaultChecked={initialValues.OnGross ? true : false}
                                    checked={Boolean(values.OnGross)}
                                    disabled={isDropped}
                                />
                            </Col>
                            <Col lg={4} md={4} sm={12} xs={24}>
                                {/* Percentage */}
                                <Field
                                    component={NWInputNumber}
                                    label="Percentage"
                                    name="Percentage"
                                    min={0}
                                    max={100}
                                    step={0.01}
                                    value={values.Percentage}
                                    disabled={isDropped}
                                />
                            </Col>
                            <Col lg={8} md={8} sm={12} xs={24} offset={4}>
                                {/* ContractDate */}
                                <Field
                                    style={{ width: "100%" }}
                                    component={NWDatePicker}
                                    label="Contract Date"
                                    name="ContractDate"
                                    format="MMM DD, YYYY"
                                    value={
                                        values.ContractDate ? getMoment(values.ContractDate) : null
                                    }
                                    manipulateValue={datePickerBeforeOnChange}
                                />
                            </Col>
                        </Row>
                        <br />
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        onTouchForm(false);
                                        onCancel();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                        {/* <Button disabled={isSubmitting} onClick={onCancel}>
              Cancel
            </Button>
            &nbsp;
            <NwButton
              htmlType="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              label="Save"
              loadingLabel="Updating..."
            /> */}
                    </NwForm>
                )}
            </Formik>
        </div>
    );
};

export default ModelAgencyForm;
