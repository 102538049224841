import React, { useState, useMemo, useContext } from "react";
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import NwIcon, { NwBoolIcon } from 'Components/Gui/NwIcon';
import { getSortedValues, wrongGenderSpecificMetric } from "Libs/NWFeatures";
import { useGlobalFeatures, useGlobalConversions } from 'Hooks/Features/UseFeatures';
import { ConfigContext } from 'Contexts/ConfigContext';
import { ListContext } from 'Contexts/ListContext';
import { getContact } from "Hooks/Contact/UseContactsList";
import NwDivider from "Components/Gui/NwDivider";
import FlexContainer from "Components/Gui/FlexContainer";
import EditFeatureDrawer from 'Components/Features/EditFeatureDrawer';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { FeatureBoxContainer, FeatureContentWrapper, FeatureGroupContainer } from "./FeaturesUI";
import { Tooltip } from "antd";
import { isOlderThan, showLog } from "Libs/NwMoment";

const FeaturesGroup = ({ featuresGroup, contactFeatures, contactID, contactType, readOnly }) => {
    const initialValues = {};
    const { userGuiConfig } = useContext(ConfigContext);
    const { data: globalFeatures } = useGlobalFeatures();
    const contact = getContact(contactID);
    const { data: globalConversions } = useGlobalConversions();
    const [updatedFieldIDs, setUpdatedFieldIDs] = useState([]);
    const [selectedMetrics, setSelectedMetrics] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [editFeatureName, setEditFeatureName] = useState();
    const breakpoints = useNwBreakPoints();

    const { GetUserName } = useContext(ListContext);

    featuresGroup.GroupFeatures.forEach(feature => {
        const contactFeature = contactFeatures && contactFeatures.find(contactFeature => contactFeature.FeatureID === feature.FeatureID);
        initialValues[feature.ID] = contactFeature && contactFeature.Value;
    });

    const changeMetric = (featureid, metricindex) => {
        const selectedMetricNoCurrent = selectedMetrics.filter(sm => {
            return sm.FeatureID !== featureid
        })
        setSelectedMetrics([...selectedMetricNoCurrent, { FeatureID: featureid, SelectedIndex: metricindex }])
    }

    const groupFeatures = useMemo(() => {
        let groupFeatures = featuresGroup.GroupFeatures.map(groupFeature => {
            let feature = globalFeatures && globalFeatures.find(feature => feature.ID === groupFeature.FeatureID);
            if (feature && feature.TypeName === 'Conversion') {
                feature = {
                    ...feature,
                    conversion: globalConversions && globalConversions.find(conversion => conversion.ID === feature.ConversionID)
                }
            }
            return {
                ...groupFeature,
                Feature: feature
            };
        });

        groupFeatures = groupFeatures.filter(feature => !((feature.Feature.GenderSpecificName === 'Male' && contact.G === 1)
            || (feature.Feature.GenderSpecificName === "Female" && contact.G === 2)));
        return groupFeatures;
    }, [featuresGroup, globalFeatures, globalConversions, contact]);

    const getItemSpan = (feature, itemFeature) => {
        switch (itemFeature.TypeName.toLowerCase()) {
            case "conversion":
                if (itemFeature.Name.length > 10) {
                    return 2
                }
                return 1
            case "boolean":
                if (itemFeature.Name.length > 10) {
                    return 2
                }
                return 1
            case "choice":
                const choicevalue = feature.DisplayValues[0].value;
                if (choicevalue.length > 80) {
                    return 4
                }
                if (choicevalue.length > 20) {
                    return 2
                }
                if (itemFeature.Name.length > 10) {
                    return 2
                }
                return 1
            case "multichoice":
                return 4
            default:
                const text = feature.Value;
                if (text.length > 80) {
                    return 4
                }
                if (text.length > 20) {
                    return 2
                }
                if (itemFeature.Name.length > 10) {
                    return 2
                }
                return 1
        }
    }

    //   const FeatureBox = ({ children, featureType, isActive, label, span }) => {
    //     return (
    //       <div className={`feature-box span-${span} feature-${featureType} ${isActive ? "active" : ""}`}>
    //         <div className="feature-box-header">
    //           {label}
    //         </div>
    //         <div className="feature-box-content">
    //           {children}
    //         </div>
    //       </div>
    //     )
    //   }

    const handleEditFeatureContent = featureName => {
        setEditFeatureName(featureName);
        setIsEdit(true);
    }


    const FeatureContent = ({ feature, itemFeature, featureName }) => {
        let textsize = "";
        switch (itemFeature.TypeName.toLowerCase()) {
            case 'conversion':
                const sortedValues = feature && getSortedValues(
                    feature.DisplayValues,
                    feature.ConversionName,
                    userGuiConfig
                )
                let metricIndex = 0;
                if (selectedMetrics.length > 0) {
                    const hasSelectedMetric = selectedMetrics.filter(obj => {
                        return obj.FeatureID === itemFeature.ID
                    })
                    if (hasSelectedMetric.length > 0) {
                        metricIndex = hasSelectedMetric[0].SelectedIndex
                    }
                }


                return (breakpoints === 1 ?
                    <span className="feature-value" onClick={() => handleEditFeatureContent(featureName)}>
                        {sortedValues[metricIndex].value} {sortedValues[metricIndex].metric}
                    </span>
                    : <>
                        <span className="feature-value" onClick={() => handleEditFeatureContent(featureName)}>
                            {sortedValues[metricIndex].value}
                        </span>
                        <span className="feature-metrics">
                            {sortedValues.map((sv, index) => {
                                if (!wrongGenderSpecificMetric(sv.metric, contact ? contact.G : null)) {
                                    return (<span key={sv.metric} className={(index === metricIndex) ? "active-metric" : ""} onClick={() => changeMetric(itemFeature.ID, index)}>{sv.metric}</span>)
                                }
                                return null
                            })}
                        </span>
                    </>
                )
            case 'boolean':
                return (
                    <span className="feature-value" onClick={() => handleEditFeatureContent(featureName)}>
                        {(feature.Value.toLowerCase() === 'true') ?
                            <NwBoolIcon value={true} fontSize="1.5em" />
                            :
                            <NwBoolIcon value={false} fontSize="1.5em" />
                        }
                    </span>
                )
            case 'choice':
            case 'multichoice':
                const myval = feature.DisplayValues[0].value
                if (myval && myval.length > 6) {
                    if (myval.length > 20) {
                        textsize = "text-small"
                    } else {
                        textsize = "text-medium"
                    }
                }
                if (myval.indexOf(" - ") >= 0) {
                    return (
                        <span className={`feature-value ${textsize} text-list`} onClick={() => handleEditFeatureContent(featureName)}>
                            {myval.split(" - ").map((item, index) => (
                                <em key={index}>&bull; {item}</em>
                            ))}
                        </span>
                    )
                } else {
                    return (
                        <span className={`feature-value ${textsize}`} onClick={() => handleEditFeatureContent(featureName)}>
                            {myval}
                        </span>
                    )
                }
            case 'rating':
                return (
                    <FlexContainer mg={breakpoints !== 1 && '8px 0 0'} onClick={() => handleEditFeatureContent(featureName)}>
                        {Array(parseInt(feature.Value)).fill(null).map((rating, key) => {
                            return (
                                <React.Fragment key={`star-${key}`}><NwIcon icon={solid('star')} style={{ fontSize: 13 }} />&nbsp;</React.Fragment>
                            )
                        })}
                    </FlexContainer>
                )
            default:
                if (feature.Value && feature.Value.length > 6) {
                    if (feature.Value.length > 20) {
                        textsize = "text-small"
                    } else {
                        textsize = "text-medium"
                    }
                }
                return (
                    <span className={`feature-value ${textsize}`} onClick={() => handleEditFeatureContent(featureName)}>
                        {feature.Value}
                    </span>
                )
        }
    }

    const handleEdit = () => {
        setIsEdit(true);
    }

    const getHistoryTooltipContent = (feature) => {
        let content = "";
        if (feature.DateModified) {
            content += `last updated on: ${showLog(feature.DateModified)}`
            if (feature.UserModified) {
                content += ` by ${GetUserName(feature.UserModified)}`
            }
        } else {
            content += `added on: ${showLog(feature.DateCreated)}`
            if (feature.UserCreated) {
                content += ` by ${GetUserName(feature.UserCreated)}`
            }
        }
        return content
    }

    return (
        (!globalFeatures || !globalConversions) ? <div />
            : <React.Fragment key={featuresGroup.Name}>
                <FeatureGroupContainer>
                    <NwDivider
                        dark
                        textSize="medium"
                        action={!readOnly && handleEdit}
                    >
                        {featuresGroup.Name}
                    </NwDivider>
                    {breakpoints === 1 ?
                        <>
                            {groupFeatures.map(item => {
                                const feature = contactFeatures && contactFeatures.find(feature => feature.FeatureID === item.FeatureID);
                                const isActive = updatedFieldIDs.find(fieldName => fieldName === item.ID)
                                if (feature) {
                                    return (
                                        <FeatureContentWrapper
                                            key={item.ID}
                                            featureType={item.Feature.TypeName.toLowerCase()}
                                            isActive={isActive}>
                                            <div>{item.Feature.Name}</div>
                                            <FeatureContent feature={feature} itemFeature={item.Feature} featureName={item.Feature.Name} />
                                        </FeatureContentWrapper>
                                    )
                                }
                                return null;
                            })}
                        </>
                        :
                        <FeatureBoxContainer>
                            {groupFeatures.map(item => {
                                const feature = contactFeatures && contactFeatures.find(feature => feature.FeatureID === item.FeatureID);
                                const isActive = updatedFieldIDs.find(fieldName => fieldName === item.ID)
                                if (feature) {
                                    const itemSpan = getItemSpan(feature, item.Feature)
                                    return (
                                        <div className={`feature-box span-${itemSpan} feature-${item.Feature.TypeName.toLowerCase()} ${isActive ? "active" : ""}`}>
                                            <div className="feature-box-header">
                                                <span>{item.Feature.Name}</span>
                                                {(feature.DateModified || feature.DateCreated)
                                                    ?
                                                    <Tooltip
                                                        title={getHistoryTooltipContent(feature)}
                                                        arrowPointAtCenter={true}
                                                        placement="bottomRight"
                                                    >
                                                        <NwIcon icon={light('clock-rotate-left')} pointer danger={isOlderThan(feature.DateModified || feature.DateCreated, 6)} />
                                                    </Tooltip>

                                                    :
                                                    <Tooltip
                                                        title="No information available"
                                                        arrowPointAtCenter={true}
                                                        placement="bottomRight"
                                                    >
                                                        <NwIcon disabled icon={light('clock-rotate-left')} pointer />
                                                    </Tooltip>
                                                }
                                            </div>
                                            <div className="feature-box-content">
                                                <FeatureContent feature={feature} itemFeature={item.Feature} featureName={item.Feature.Name} />
                                            </div>
                                        </div>
                                    )

                                    //return (
                                    // <FeatureBox
                                    //     key={item.ID}
                                    //     featureType={item.Feature.TypeName.toLowerCase()}
                                    //     isActive={isActive}
                                    //     label={<span>{item.Feature.Name}</span>}
                                    //     span={itemSpan}>
                                    //     <FeatureContent feature={feature} itemFeature={item.Feature} featureName={item.Feature.Name} />
                                    // </FeatureBox>
                                    //)
                                }
                                return null;
                            })}
                        </FeatureBoxContainer>
                    }
                </FeatureGroupContainer>
                {isEdit &&
                    <EditFeatureDrawer
                        featuresGroup={featuresGroup}
                        contactFeatures={contactFeatures}
                        contactID={contactID}
                        contactType={contactType}
                        editFeatureName={editFeatureName}
                        updatedFieldIDs={updatedFieldIDs}
                        setUpdatedFieldIDs={setUpdatedFieldIDs}
                        onClose={() => {
                            setIsEdit(false);
                            setEditFeatureName();
                        }} />
                }
            </React.Fragment>
    )
};

export default FeaturesGroup;
