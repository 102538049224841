import React, { useContext } from "react";
import styled from "styled-components";
import { getMoment } from "Libs/NwMoment";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import FlexContainer from "Components/Gui/FlexContainer";

//import FilterIcon from "Components/Gui/FilterIcon";
import NwIcon from 'Components/Gui/NwIcon';
import { placeholderToLabel } from "Configs/FILTERS_PLACEHOLDERS";
import { getDisplayValues, groupConversionValues } from "Libs/NWFeatures";

import { ListContext } from "Contexts/ListContext";
import { ConfigContext } from 'Contexts/ConfigContext';
import { useGlobalConversions, useGlobalFeatures } from 'Hooks/Features/UseFeatures';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import useDataPlaceholder from "Hooks/UseDataPlaceholder";
import { showDate, toISODate } from "Libs/NwMoment";
import { getOrdinal } from "Libs/NwUtils";

const DisplayFilterItemStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: ${props => props.view !== 'compact' && '100%'};
  margin: ${props => props.view !== 'compact' ? '0.5em auto' : '0 0.5rem 0 0'};
  font-size: 0.8em;
`;

const FilterLabel = styled.div`
  font-weight: bold;
  margin-left: 0.5em;
`;

const FilterValue = styled.div`
  width: ${props => (props.full ? "100%" : "initial")};
  .filter-value {
    padding-left: 1.5em;
  }
`;

const DisplayFilterItem = ({ filter, view, customFields, filterList }) => {
    const { cachedList } = useContext(ListContext);
    const { userGuiConfig } = useContext(ConfigContext);
    const { data: globalFeatures } = useGlobalFeatures();
    const { data: globalConversions } = useGlobalConversions();
    const replacePlaceHolder = useDataPlaceholder();
    const enumTransactions = cachedList.enumerators.items.find(item => item.Name === "JobTransactionStatusEnum");
    const allTx = [{ Value: "All", Name: "All Status" }, ...enumTransactions.Values];
    const families = cachedList.transactionFamilies.items;
    const labels = [];

    families.map(item => {
        labels.push(...item.TransactionLabels);
        return null;
    });

    const formatFilterValue = (type, value) => {
        let retVal;
        let full = false;
        switch (type) {
            case "category":
                const item = cachedList.projectCategories.items.find(el => el.ID === value);
                if (item && item.Name)
                    retVal = item.Name;
                break;
            case "department":
                cachedList.accountingGroups.items.map((item) => {
                    item.NetworkAgencies.map((agency) => {
                        agency.Departments.map((department) => {
                            if (department.ID === value)
                                retVal = `${agency.Name} / ${department.Name}`;
                            return null;
                        });
                        return null;
                    });
                    return null;
                });
                break;
            case "currency-range":
                {
                    const { Currency, FromAmount, ToAmount } = value;
                    retVal = `${Currency} ${FromAmount} - ${ToAmount}`;
                }
                break;
            case "currency-range-withtax":
                {
                    const { Currency, FromAmount, ToAmount, Taxed } = value;
                    retVal = `${Currency} ${FromAmount} - ${ToAmount}, Taxed: ${Taxed}`;
                }
                break;
            case "period":
                if (typeof value === "string") {
                    const range = replacePlaceHolder(value);
                    const toRenderrange = range[0] === range[1]
                        ? getMoment(range[0]).format('DD MMM YYYY')
                        : `${getMoment(range[0]).format('DD MMM YYYY')} - ${getMoment(range[1]).format('DD MMM YYYY')}`;
                    const match = value.match(/\{(\w+)\}/);
                    retVal = match ? placeholderToLabel(match[0]) : value;
                    return (
                        <div style={{ marginLeft: "10px" }}>
                            <div>{retVal}</div>
                            <div>({toRenderrange})</div>
                        </div>
                    )
                } else {
                    retVal = `(${getMoment(value[0]).format('DD MMM YYYY')} - ${getMoment(value[1]).format('DD MMM YYYY')})`;
                }
                break;
            case "date":
                if (typeof value === "string") {
                    const match = value.match(/\{(\w+)\}/);
                    retVal = match ? placeholderToLabel(match[0]) : value;
                } else {
                    retVal = value ? getMoment(value).format('MMM DD YYYY') : '';
                }
                break;
            case "bookerList":
                const booker = cachedList.users.items.find(el => el.ID === value);
                retVal = `${booker.Name} ${booker.Surname}`;
                break;
            case "modelList":
            case "customerList":
            case "serviceList":
            case "contactList":
            case "clientList":
                retVal = getContactName(value);
                break;
            case "filter-type":
                retVal = value === true ? 'Yes' : value === false ? 'No' : 'All';
                break;
            case "gender":
                retVal = value;
                break;
            case "projects-tag":
            case "jobs-tag":
            case "customers-tag":
            case "tag":
                const tags = [];
                const cachedtags = cachedList.publictags.items.concat(cachedList.privatetags.items)
                value.forEach(t => {
                    const tag = cachedtags.find(el => el.ID === t);
                    tags.push(tag.Name);
                });
                retVal = `${tags}`;
                break;
            case "JobsTransactionStatus":
                const txs = [];
                const txStatuses = cachedList.enumerators.items.find(item => item.Name === "JobTransactionStatusEnum").Values;
                value.forEach(t => {
                    const tx = txStatuses.find(el => el.Value === t);
                    txs.push(tx.Name);
                });
                retVal = `${txs}`;
                break;
            case "countriesList":
                const countries = [];
                value.forEach(c => {
                    const country = cachedList.countries.items.find(el => el.Code === c);
                    countries.push(country.Name);
                });
                retVal = `${countries}`;
                break;
            case "citiesList":
                return value.join(', ');
            case "departmentsList":
                const departments = [];
                cachedList.accountingGroups.items.forEach(ag => {
                    ag.NetworkAgencies.forEach(na => {
                        na.Departments.forEach(dp => {
                            if (value.includes(dp.ID)) departments.push(dp.Name);
                        });
                    });
                });
                retVal = `${departments}`;
                break;
            case "boolean":
                return value ? "yes" : "no";
            case 'event-casting':
                if (value.eventSubtypeCode && value.eventSubtypeCode.length > 0) {
                    return value.eventSubtypeCode.join(', ');
                }
                return "all";
            case 'event-job':
                let stringtypes = "all types";
                let stringoptions = "jobs and any option number";
                if (value.eventSubtypeCode && value.eventSubtypeCode.length > 0) {
                    stringtypes = value.eventSubtypeCode.join(', ');
                }
                if (value.optionNumber) {
                    if (value.optionNumber === 10) {
                        stringoptions = "job";
                    } else {
                        stringoptions = `only ${value.optionNumber}${getOrdinal(value.optionNumber)} options`;
                    }
                }
                return (
                    <div style={{ marginLeft: "10px" }}>
                        <div>{stringtypes}</div>
                        <div>{stringoptions}</div>
                    </div>
                );
            case 'event-followup':
                if (value.labels && value.labels.length > 0) {
                    return value.labels.join(', ');
                }
                return "all";
            case 'event-travel':
                if (value.travelTypes && value.travelTypes.length > 0) {
                    return value.travelTypes.join(', ');
                }
                return "all";
            case 'event-nosub':
                return "yes";
            case "featuresList":
                full = true;
                retVal = value.map((el, i) => {
                    const feature = globalFeatures.find(feature => feature.ID === el.FeatID);
                    switch (feature.TypeName) {
                        case 'Conversion':
                            const conversion = userGuiConfig.metrics.find(
                                conversion => conversion.ID === feature.ConversionID
                            );
                            const groupedConversionValues = groupConversionValues(conversion.ConversionValues);
                            const conversionValues = groupedConversionValues.map(conversionValue => {
                                return {
                                    ...conversionValue,
                                    Metrics: conversionValue.Metrics.map(metric => ({ ...metric, metric: metric.Metric, value: metric.Value }))
                                }
                            });

                            const stepMin = conversionValues.find(
                                step => step.Key === el.MinVal
                            );
                            const stepMax = conversionValues.find(
                                step => step.Key === el.MaxVal
                            );

                            const minVal = getDisplayValues(
                                stepMin.Metrics,
                                conversion.Name,
                                false,
                                userGuiConfig,
                                " - "
                            );

                            const maxVal = getDisplayValues(
                                stepMax.Metrics,
                                conversion.Name,
                                false,
                                userGuiConfig,
                                " - "
                            );

                            return (
                                <div className='filter-value' key={i}>
                                    <b>{feature.Name}:</b> Min {minVal} - Max {maxVal}
                                </div>
                            );
                        case 'Numeric':
                            return (
                                <div className='filter-value' key={i}>
                                    <b>{feature.Name}:</b> Min {el.MinVal} - Max {el.MaxVal}
                                </div>
                            );
                        case "Multichoice":
                            const multiChoices = feature.Choices.filter(choice => el.Choices.includes(choice.ID));
                            return (
                                <div className='filter-value' key={i}>
                                    <b>{feature.Name}:</b>{" "}
                                    {multiChoices.map(choice => choice.Value).join(el.Operator === 'and' ? " + " : " or ")}
                                </div>
                            );
                        case "Choice":
                            const choices = feature.Choices.filter(choice => el.Choices.includes(choice.ID));
                            return (
                                <div className='filter-value' key={i}>
                                    <b>{feature.Name}:</b>{" "}
                                    {choices.map(choice => choice.Value).join(", ")}
                                </div>
                            );
                        case "Boolean":
                            return (
                                <div className='filter-value' key={i}>
                                    <b>{feature.Name}:</b> {el.Checked === null && <>?</>}
                                    {el.Checked === false && <>No</>}
                                    {el.Checked === true && <>Yes</>}
                                </div>
                            );
                        case "Rating":
                            return (
                                <div className='filter-value' key={i}>
                                    <b>{feature.Name}:</b>&nbsp;
                                    {el.MinVal && Array(parseInt(el.MinVal)).fill(null).map((rating, key) => {
                                        return (
                                            <React.Fragment key={`star-${key}`}><NwIcon icon={solid('star')} style={{ fontSize: 13 }} />&nbsp;</React.Fragment>
                                        )
                                    })}
                                </div>
                            );
                        default:
                            return (
                                <div className='filter-value' key={i}>
                                    <b>{feature.Name}:</b> {el.TextVal}
                                </div>
                            );
                    }
                });
                break;
            case "transaction-filter":
                retVal = "";
                break;
            case "family-selector":
                if (value) {
                    retVal = "";
                    value.map((val, index) => {
                        retVal += ((index ? ", " : "") + families.find(family => family.Code === val).Name)
                        return null;
                    })
                }
                break;
            case "label-selector":
                if (value) {
                    retVal = "";
                    value.map((val, index) => {
                        retVal += ((index ? ", " : "") + labels.find(label => label.ID === val).Label)
                        return null;
                    })
                }
                break;
            case "transaction-selector":
                if (value === null) retVal = 'All Status';
                else retVal = allTx.find(status => status.Value === value).Name;
                break;
            case "contact-selector":
                if (value.StatementType !== "Selected")
                    retVal = value.StatementType;
                else
                    retVal = getContactName(value.ContactID);
                break;
            case "model-playlist-selector":
                if (value.modelID)
                    retVal = getContactName(value.modelID);
                break;
            case "status-selector":
                if (value)
                    retVal = "Completed";
                else if (value === false)
                    retVal = "To Complete";
                else retVal = "All";
                break;
            default:
                retVal = value;
                break;
        }
        return <FilterValue full={full}>{retVal}</FilterValue>;
    };

    const filterObj = filterList.filters.find(el => el.name === filter.name);

    if (filter.name.includes('custom_fields')) {
        const filterName = filter.name.replace('custom_fields_', '');
        const customField = customFields.find((cf) => {
            return cf.Name === filterName
        });
        let value = filter.value;
        if (customField.TypeName === "MultipleChoice" || customField.TypeName === "Choice") {
            value = value.join(', ');
        } else if (customField.TypeName === 'Numeric') {
            value = value.join(' - ');
        } else if (customField.TypeName === 'Boolean') {
            value = filter.value ? 'True' : 'False';
        } else if (customField.TypeName === 'Date') {
            if (Array.isArray(filter.value)) {
                value = `${toISODate(filter.value[0])} / ${toISODate(filter.value[1])}`;
            } else {
                value = filter.value ? showDate(filter.value) : '';
            }
        }
        return (
            <DisplayFilterItemStyle view={view}>
                {/* <NwIcon icon={light('circle-info')} /> */}
                <FilterLabel>{filterName.replace('_',' ')}</FilterLabel>&nbsp;{value}
            </DisplayFilterItemStyle>
        )
    }

    return (
        <DisplayFilterItemStyle view={view}>
            {filterObj &&
                <>
                    <FlexContainer>
                        {/* <FilterIcon name={filterObj.area} /> */}
                        <FilterLabel>{filterObj.label}&nbsp;</FilterLabel>
                    </FlexContainer>
                    {globalConversions && formatFilterValue(filterObj.type, filter.value)}
                </>
            }
        </DisplayFilterItemStyle>
    );
};

export default DisplayFilterItem;
