import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router";
import styled from "styled-components";
import { PageHeader, Row, Col } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import ContactDetailsHeaderAnagraphical from "Components/ContactDetails/ContactDetailsHeader/ContactDetailsHeaderAnagraphical";
import ContactDetailsHeaderConnections from "Components/ContactDetails/ContactDetailsHeader/ContactDetailsHeaderConnections";
import ModelDetailsHeaderLegal from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderLegal";
import ContactHeaderBillingInfos from 'Components/ContactDetails/ContactDetailsHeader/ContactHeaderBillingInfos';
import ContactDetailsBankAccounts from 'Components/ContactDetails/ContactDetailsHeader/ContactDetailsBankAccounts';
import ModelDetailsHeaderAgencies from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderAgencies";
import ModelDetailsHeaderFooter from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderFooter';
import AnagraphicalDrawer from "Components/ContactDetails/CommonComponents/Anagraphical/AnagraphicalDrawer";
import { Loading } from "Components/Gui/Loading";
import AddToFolderButton from "Components/OpenFolders/AddToFolderButton";
import AlertDrawer from "Components/AlertDrawer/AlertDrawer";
import NwIcon from "Components/Gui/NwIcon";
import ContactUsers from "Components/ContactUsers/ContactUsers";
import ModelInTown from "Components/ModelInTown/ModelInTown";
import FlexContainer from "Components/Gui/FlexContainer";
import ObjectLogHistories from "Components/ObjectLogHistories/ObjectLogHistories";
import NwDrawer from "Components/Gui/NwDrawer";
import ContactAlert from 'Components/ContactAlert/ContactAlert';
import { modelFullName } from "Hooks/Contact/UseContactsList";
import ModelDetailsCover from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsCover';
import PermissionBlock from "Components/PermissionBlock";
import { UserContext, useUserPermission } from "Contexts/UserContext";
import { useStateValue } from "Contexts/StateProvider";
import usePlaceholderImageFixed from "Hooks/UsePlaceholderImageFixed";
import { useContact } from "Hooks/Contact/UseContact";
import { useAddContactTag, useRemoveContactTag } from "Hooks/Contact/UseContactTags";
import useLocalStorageState from "Hooks/UseLocalStorageState";
import { saveAlerts } from 'Libs/NwAlerts';
import NwDividerVertical from "Components/Gui/NwDividerVertical";
import ContactAlertDrawer from "Components/ContactAlert/ContactAlertDrawer";
import TagManagerDrawer from "Components/TagManager/TagManagerDrawer";
import ContactDepartments from "Components/ContactDepartments/ContactDepartments";

const ModelPageHeader = styled(PageHeader)`
    height: ${props => props.$collapseView && '8rem !important'};

    .ant-page-header-heading {
        padding-left: ${props => props.$collapseView && '6.5rem !important'};
        padding-top: ${props => props.$collapseView && '0.5rem !important'};
    }

    .header-content {
        padding-left: ${props => props.$collapseView && '6.5rem !important'};
        .header-body {
            padding-left: ${props => props.$collapseView && '0 !important'};
        }
    }
    .ant-page-header-heading-title {
        width: 100%;
    }
    .ant-page-header-heading-left {
        width: 100%;
    }
`;

const NameWrapper = styled.div`
    font-size: ${props => props.$collapseView ? '1.4rem' : '2rem'};
    line-height: 1.25;
    text-transform: capitalize;
    padding-right: 1em;
    cursor: pointer;

    h1 {
        font-size: ${props => props.$collapseView ? '1.4rem' : '2rem'};
        margin: 0;
        font-weight: 600;
    }

    &.name-with-alert {
        margin-left: 24px;
    }
`;

const ModelDetailsHeaderDrawer = (props) => {
    const {
        onOpenModelAgencies,
        onOpenStatements,
        selectedModel
    } = props;
    const { data: currentContact } = useContact(selectedModel);
    const [{ areaContext }] = useStateValue();
    const { mutate: addTag } = useAddContactTag();
    const { mutate: removeTag } = useRemoveContactTag();
    const { currentUser } = useContext(UserContext);
    const [IsLoading, setIsLoading] = useState(true);
    const [showAlertDrawer, setShowAlertDrawer] = useState(false);
    const [readAlerts, setReadAlerts] = useLocalStorageState(`${areaContext.area}-read-alerts`, []);
    const [isOpenHistory, setIsOpenHistory] = useState(false);
    const alertOnModels = useUserPermission('AlertOnModels', currentContact && currentContact.Users.map(user => user.UserID));
    const tagsOnContacts = useUserPermission('TagsOnContacts', currentContact && currentContact.Users.map(user => user.UserID));
    const [anagraphicalDrawer, setAnagraphicalDrawer] = useState(false);

    const handleOpenAnagraphicalDrawer = () => {
        setAnagraphicalDrawer(true);
    };

    useEffect(() => {
        if (currentContact) {
            setIsLoading(false);
        }
    }, [currentContact]);

    // const handleOpenHistory = () => {
    //     setIsOpenHistory(true);
    // };

    const handleCloseHistory = () => {
        setIsOpenHistory(false);
    };

    const ModelNameLine = () => (
        <FlexContainer wrap="wrap" justifyContent="space-between" fullWidth>
            <FlexContainer wrap="wrap">
                <NameWrapper>
                    <h1 onClick={handleOpenAnagraphicalDrawer}>{modelFullName(currentContact).toLowerCase()}</h1>
                    {anagraphicalDrawer && 
                        <AnagraphicalDrawer 
                            contactID={currentContact.ID} 
                            type={currentContact.ContactType === 'Customer' ? 0 : 1} 
                            onClose={() => {
                                setAnagraphicalDrawer(false)
                            }} />
                    }
                </NameWrapper>
                <span className="name-icon">
                    <AddToFolderButton ids={[currentContact.ID]} type="model" size='lg' showBadge />
                </span>
                <span className="name-icon">
                    {alertOnModels ? 
                        <NwIcon
                            icon={light("comment-alt-exclamation")}
                            plus={!currentContact.Alert}
                            edit={currentContact.Alert}
                            pointer
                            onClick={handleOpenAlertDrawer}
                        />
                        : 
                        <ContactAlert
                            contactId={currentContact.ID}
                            readOnly
                            icon={
                                <NwIcon                                
                                    size='lg' 
                                    icon={light("comment-alt-exclamation")}
                                    pointer
                                />
                            }
                        />
                    }
                </span>
                <span className="name-icon">
                    <PermissionBlock
                        permission={tagsOnContacts}
                        content={
                            <TagManagerDrawer
                                readOnly={!tagsOnContacts}
                                itemType={currentContact.ContactType}
                                tags={currentContact.Tags}
                                onAddTag={tagsOnContacts && onAddTag}
                                onRemoveTag={tagsOnContacts && onRemoveTag}
                                maxTagsShown={2}
                            />
                        }
                    />
                </span>
            </FlexContainer>
        </FlexContainer>
    );

    const onAddTag = (tag) => {
        addTag({ tag: tag, contactID: currentContact.ID });
    };

    const onRemoveTag = (tag) => {
        removeTag({ tag: tag, contactID: currentContact.ID });
    };

    const handleOpenAlertDrawer = () => {
        setShowAlertDrawer(true);
    };

    const handleCloseAlertDrawer = (updated) => {
        if (updated) {
            const alerts = readAlerts.filter(alert => alert.Author !== currentUser.UserID || alert.ContactID !== currentContact.ID);
            setReadAlerts(alerts);
            saveAlerts(alerts);
        }
        setShowAlertDrawer(false);
    };

    console.log("currentContact", currentContact);

    const coverguid = currentContact && currentContact.NetworkAgencies[0] && currentContact.NetworkAgencies[0].CoverGuid;

    const [urlbg, isPlaceholder] = usePlaceholderImageFixed(
        coverguid,
        currentContact.SubType
    );

    const GetModelPageHeader = () => (
        <ModelPageHeader
            onBack={null}
            title={ModelNameLine()}
            subTitle={false}
        >   
            <ModelDetailsCover 
                urlBg={urlbg} 
                isPlaceholder={isPlaceholder} 
                currentContact={currentContact}
                readOnly
            />
            <div className="header-content">
                <div className="header-body">
                    <Row>
                        <Col span={24}>
                            <div className="header-body-boxes-container">
                                <ModelInTown
                                    modelID={currentContact.ID}
                                    modelInTownSchedule={
                                        currentContact.InTownSchedule
                                    }
                                />
                                <NwDividerVertical />
                                <ModelDetailsHeaderAgencies
                                    model={currentContact}
                                    onOpenModelAgencies={onOpenModelAgencies}
                                />
                                <NwDividerVertical />
                                <div className="header-body-boxes header-body-boxes-hoverable">
                                    <ContactUsers
                                        users={currentContact.Users}
                                        contactID={currentContact.ID}
                                    />
                                </div>
                                <NwDividerVertical />
                                <div className="header-body-boxes">
                                    <ContactDepartments
                                        departments={currentContact.Departments}
                                        contactID={currentContact.ID}
                                    />
                                </div>
                            </div>
                            <div className="header-body-boxes-container model-info-line">
                                <ContactDetailsHeaderAnagraphical
                                    contact={currentContact}
                                />
                                <div className="header-models-counters">
                                    <NwDividerVertical />
                                    <ModelDetailsHeaderLegal currentContact={currentContact} />
                                    <NwDividerVertical />
                                    <ContactHeaderBillingInfos type="model" currentContact={currentContact} />
                                    <NwDividerVertical />
                                    <ContactDetailsBankAccounts currentContact={currentContact} />
                                </div>
                            </div>
                        </Col>
                            <Col span={24}>
                                <ContactDetailsHeaderConnections
                                    contact={currentContact}
                                />
                            </Col>
                    </Row>
                </div>
            </div>
            {getModelPageFooter()}
        </ModelPageHeader>
    );

    const getModelPageFooter = () => (
        <ModelDetailsHeaderFooter
            currentContact={currentContact}
            onOpenModelAgencies={onOpenModelAgencies}
            onOpenStatements={onOpenStatements}
            disableStatement={areaContext.area === 'art' || areaContext.area === 'scouting'} 
            hideActions
        />
    )

    return currentContact ? (
        IsLoading ? (
            <Loading />
        ) : (
            <>
                <ContactAlertDrawer contactId={currentContact.ID} />
                <div className="details-header-container details-header-container-drawer">
                    {GetModelPageHeader()}
                </div>
                {showAlertDrawer && (
                    <AlertDrawer
                        contactID={currentContact.ID}
                        onClose={handleCloseAlertDrawer}
                    />
                )}
                {isOpenHistory && (
                    <NwDrawer title="Model Logs" onClose={handleCloseHistory}>
                        <ObjectLogHistories
                            objectId={currentContact.ID}
                            type="model"
                        />
                    </NwDrawer>
                )}
            </>
        )
    ) : (
        <Loading />
    );
};

export default withRouter(ModelDetailsHeaderDrawer);
