import React, { useMemo, useState } from 'react';
import { Alert, Checkbox, Table } from 'antd';

import Money from 'Components/Gui/Money';
import { NwButton, NwEditButton } from 'Components/Gui/NwButton';
import BillingInfo from 'Components/BillingInfo/BillingInfo';
import { getMoment } from "Libs/NwMoment";
import TransactionEvent from 'Components/Transactions/TransactionEvent';
import TransactionTitle from 'Components/Transactions/TransactionTitle';
import VerticalDate from 'Components/Dates/VerticalDate';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import CreateInvoiceDrawer from 'Components/Invoices/CreateInvoiceDrawer';
import StatementStatusIcon from 'Components/Transactions/StatementStatusIcon';
import { useUserPermission } from 'Contexts/UserContext';
import InvoiceDrawer from 'Components/Invoices/InvoiceDrawer';
import useConfigurations from 'Hooks/UseConfigurations';
import NwLink from 'Components/Gui/NwLink';
import NwDrawer from 'Components/Gui/NwDrawer';
import TransactionContact from 'Components/Transactions/TransactionsDrawer/TransactionDetailForms/TransactionContact';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import PackageEditDrawer from 'Components/PackageDrawer/PackageHeader/PackageEditDrawer';
import { is } from '../../../../../../../Library/Caches/typescript/5.5/node_modules/@babel/types/lib/index';

const ProjectGroupedStatementsBillingInfo = ({
    contactID,
    contactName,
    statementType,
    billingInfo,
    isSingleBillingInfo,
    onEditTransaction,
    project,
    readOnly,
}) => {

    console.log("statementType", statementType);

    const [selectedStatementIDs, setSelectedStatementIDs] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [showCreateInvoiceDrawer, setShowCreateInvoiceDrawer] = useState(false);
    const [showInvoiceDrawer, setShowInvoiceDrawer] = useState();
    const [showChangeContactDrawer, setShowChangeContactDrawer] = useState();
    const [currentInvoiceData, setCurrentInvoiceData] = useState();
    const [transactionsIDsForContactChange, setTransactionsIDsForContactChange] = useState([]);
    const accessAccounting = useUserPermission('AccessAccounting');

    const configurations = useConfigurations()

    const isMainFee = (statement) => {
        return !statement.isCommission && !statement.isClientAdvance
    }

    const statementsRows = useMemo(() => {
        const rows = [];
        billingInfo.Transactions.forEach((transaction) => {
            transaction.Statements.forEach((statement) => {
                rows.push({
                    ID: statement.ID,
                    TransactionID: transaction.ID,
                    HasCommission: transaction.Statements.length > 1 && isMainFee(statement),
                    ModelID: transaction.ModelID,
                    EventID: transaction.EventID,
                    FollowUpID: transaction.FollowUpID,
                    AccommodationID: transaction.AccommodationID,
                    ExternalJobID: transaction.ExternalJobID,
                    UsageID: transaction.UsageID,
                    Currency: transaction.Currency,
                    Quantity: transaction.Quantity,
                    TypeQuantity: transaction.TypeQuantity,
                    SingleFee: transaction.SingleFee,
                    TotalFee: transaction.TotalFee,
                    Amount: statement.Amount,
                    TaxedAmount: statement.TaxedAmount,
                    AmountDef: statement.AmountDef,
                    TaxedAmountDef: statement.TaxedAmountDef,
                    isCommission: statement.isCommission,
                    isClientAdvance: statement.isClientAdvance,
                    isInvoiced: statement.InvoiceID ? true : false,
                    InvoiceID: statement.InvoiceID,
                    InvoiceNumber: statement.InvoiceNumber,
                    InvoiceDate: statement.InvoiceDate,
                    Status: statement.Status,
                    PaidStatus: statement.PaidStatus,
                    RefDate: transaction.RefDate,
                    WorkDate: transaction.WorkDate,
                    WorkDateCancelled: transaction.WorkDateCancelled,
                    TransactionDescription: transaction.Description,
                    StatementDescription: statement.Description,
                    Family: transaction.Family,
                    TransactionLabelID: transaction.TransactionLabelID,
                    Title: transaction.Title,
                    originalTransaction: transaction,
                })
            })
        })
        return rows;
    }, [billingInfo])

    const someSelected = () => {
        return billingInfo.Transactions.some((tr) => tr.Statements.some((st) => selectedStatementIDs.includes(st.ID)))
    }

    const checkDifferentCurrencies = () => {
        if (selectedStatementIDs.length > 0) {
            let allValuesAreConverted = true;
            let someValuesAreConverted = false;
            for (const tr of billingInfo.Transactions) {
                const selectedStatements = tr.Statements.filter((st) => selectedStatementIDs.includes(st.ID))
                for (const st of selectedStatements) {
                    if (st.ExchangeRate === 0) {
                        allValuesAreConverted = false;
                    } else {
                        someValuesAreConverted = true;
                    }
                }
            }
            if (allValuesAreConverted) {
                return false
            }
            let currencySet = new Set()
            for (const tr of billingInfo.Transactions.filter(tr => tr.Statements.some(st => selectedStatementIDs.includes(st.ID)))) {
                currencySet.add(tr.Currency)
            }
            if (currencySet.size > 1) {
                return true
            } else {
                if ((currencySet[0] !== configurations.defaultCurrency) && someValuesAreConverted && !allValuesAreConverted) {
                    return true
                }
            }
        }
        return false
    }

    const prepareInvoiceData = () => { //type
        setShowCreateInvoiceDrawer(false)
        let invoiceStatementIDs = []
        let selectedCurrency = null;
        let agFeeCount = 0;
        let currencySet = new Set()
        let allValuesAreConverted = true;
        for (const tr of billingInfo.Transactions) {
            const selectedStatements = tr.Statements.filter((st) => selectedStatementIDs.includes(st.ID))
            for (const st of selectedStatements) {
                currencySet.add(tr.Currency)
                invoiceStatementIDs.push(st.ID)
                if (tr.Family === "AgFee" || (tr.Family === "JobFee" && st.isCommission) || (tr.Family === "JobFee" && st.isClientAdvance)) {
                    agFeeCount++;
                }
                if (st.ExchangeRate === 0) {
                    allValuesAreConverted = false;
                }
            }
        }
        if (allValuesAreConverted) {
            selectedCurrency = configurations.defaultCurrency
        } else {
            selectedCurrency = [...currencySet][0]
        }
        let ret = {
            Type: "Client",
            ContactID: contactID,
            ContactName: getContactName(contactID),
            ProjectName: project.Name,
            BillingInfoID: billingInfo.ID,
            BillingInfoDefaultPaymentTermCode: billingInfo.PaymentTermCode,
            SelectedStatementIDs: invoiceStatementIDs,
            Currency: selectedCurrency,
            AgencyFeeCount: agFeeCount
        }
        setCurrentInvoiceData(ret)
        setShowCreateInvoiceDrawer(true)
    }

    const isStatementBillable = (statement) => {
        return !statement.isInvoiced
    }

    const statementIDsArray = statementsRows.filter(isStatementBillable).map(s => s.ID)
    const statementsNumber = statementIDsArray.length;

    const TransactionDate = ({ transaction }) => {
        if (transaction.WorkDate) {
            if (getMoment(transaction.WorkDate).year() > 1900) {
                return (<VerticalDate size="small" date={transaction.WorkDate} verticalPadding='0' cancelled={transaction.WorkDateCancelled} />)
            }
        }
        if (transaction.RefDate && !transaction.WorkDate) {
            if (getMoment(transaction.RefDate).year() > 1900) {
                return (<VerticalDate size="small" date={transaction.RefDate} verticalPadding='0' cancelled={transaction.WorkDateCancelled} />)
            }
        }
        return (<small className='upper super-muted'>no date</small>)
    }

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedStatementIDs(selectedRowKeys);
        setIndeterminate((selectedRowKeys.length > 0) && (selectedRowKeys.length < statementsNumber));
        setCheckAll(selectedRowKeys.length === statementsNumber);
    }

    const onCheckAllChange = (e) => {
        const selectedKeys = e.target.checked ? statementIDsArray : []
        setSelectedStatementIDs([...selectedKeys]);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const rowSelection = {
        selectedRowKeys: selectedStatementIDs,
        renderCell: function (checked, record, index, originNode) {
            return (
                <div className="selection-wrapper">
                    <div><StatementStatusIcon statement={record} /></div>
                    <div>{originNode}</div>
                </div>
            )
        },
        onChange: onSelectChange,
        getCheckboxProps: (record) => {
            return ({ disabled: record.isInvoiced })
        }
    }

    const isItemExpanded = (itemID) => {
        return false
    }

    const getCommissionName = () => {
        switch (statementType) {
            case "Agency":
                return "Mother Agency Commission";
            case "Client":
                return "Agency Fee";
            case "Model":
                return "Agency Commission";
            default:
                return "Commission";
        }
    }

    const transactionColumns = [
        {
            title: 'Date',
            key: 'Date',
            dataIndex: 'Date',
            className: 'cell-border-left cell-border-right',
            //rowSpan: record.hasCommission ? 2 : 1,
            render: (text, record) => {
                if (isMainFee(record)) {
                    return (
                        <div className="transaction-date">
                            <TransactionDate transaction={record} />
                        </div>
                    )
                } else {
                    return <div className="transaction-date commission-row">&nbsp;</div>;
                }
            }
        },
        {
            title: 'Title',
            key: 'Title',
            dataIndex: 'Title',
            width: '60%',
            render: (text, record) => {
                const usage = project.Usages.find(usage => usage.ID === record.UsageID);
                if (record.isCommission) {
                    return (<div className="transaction-left"><small>{getCommissionName()}</small></div>)
                }
                if (record.isClientAdvance) {
                    return (<div className="transaction-left"><small>Client Advance / Payroll</small></div>)
                }
                return (
                    <div className="transaction-left">
                        {statementType !== "Model" && record.ModelID &&
                            <small className="transaction-model">{getContactName(record.ModelID)}</small>
                        }
                        <TransactionTitle transaction={record} />
                        {isItemExpanded(record.ID) &&
                            <>
                                <div>{record.Description || <em className="super-muted">no description</em>}</div>
                                <TransactionEvent transaction={record} project={project} />
                                {usage &&
                                    <div>{`${usage.Areas[0]} - ${usage.Duration}`.toLocaleLowerCase()}</div>
                                }
                            </>
                        }
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'Amount',
            width: '40%',
            align: 'right',
            render: (text, record) => {
                return (
                    <div className={`transaction-right${record.isCommission ? " commission-row" : ""}${record.isClientAdvance ? " advance-row" : ""}`}>
                        <div className='transaction-fee'>
                            {(isItemExpanded(record.ID) && (record.Quantity !== 1 || record.TypeQuantity !== 'Units')) &&
                                <>
                                    <Money amount={record.SingleFee} currency={record.Currency} />&nbsp;{`x ${record.Quantity} ${record.TypeQuantity}`}
                                </>
                            }
                            <div className="total-fee">
                                <Money highlightCurrency redIfNegative amount={record.Amount} currency={record.Currency} />
                                {(record.Currency !== configurations.defaultCurrency) && (record.AmountDef > 0) &&
                                    <>
                                        <br />
                                        <Money redIfNegative amount={record.AmountDef} currency={configurations.defaultCurrency} />
                                    </>
                                }
                            </div>
                        </div>
                        {!readOnly &&
                            ((record.isCommission || record.isClientAdvance)
                                ?
                                <div className="edit-button">&nbsp;</div>
                                :
                                <div className="edit-button">
                                    {!readOnly &&
                                        <NwEditButton iconOnly ghost onClick={(e) => {
                                            e.stopPropagation()
                                            onEditTransaction(record.originalTransaction)
                                        }} />
                                    }
                                </div>
                            )
                        }
                    </div>
                )
            }
        },
    ];

    const handleInvoiceCreated = (newInvoiceID) => {
        setShowInvoiceDrawer(newInvoiceID)
        setShowCreateInvoiceDrawer(false)
    }

    const handleChangeSelectedTransactionContact = () => {
        const selectedTransactionsIDs = statementsRows.filter(st => selectedStatementIDs.includes(st.ID)).map(st => st.TransactionID)
        setTransactionsIDsForContactChange([...selectedTransactionsIDs])
        setShowChangeContactDrawer(true)
    }

    const handleChangeTransactionContact = () => {
        setTransactionsIDsForContactChange(statementsRows.map(sr => sr.TransactionID))
        setShowChangeContactDrawer(true)
    }

    const handleCloseTransactionContactDrawer = () => {
        setTransactionsIDsForContactChange([])
        setShowChangeContactDrawer(false)
    }

    const isStatementConfirmed = (statement) => {
        return statement.Status !== "Option"
    }

    const invoiceCreationAllowed = (accessAccounting && !readOnly && (statementType === "Client"))
    const areSomeTransactionsInvoiced = statementsRows.some(st => st.InvoiceID);

    return (
        <div className="billinginfo-line" key={`billinginfo-${billingInfo.ID}`}>
            <div className="billinginfo-header">
                {!readOnly && (statementType === "Client") &&
                    <div className='billinginfo-header-left'>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} />
                    </div>
                }
                {(isSingleBillingInfo && contactName)
                    ?
                    <>
                        <div className="billinginfo-header-middle">
                            <h2>{contactName}</h2>
                        </div>
                        <div className="billinginfo-header-middle">
                            <BillingInfo data={billingInfo} />
                        </div>
                    </>
                    :
                    <div className="billinginfo-header-middle">
                        <BillingInfo data={billingInfo} />
                    </div>
                }
                <div className="billinginfo-header-right">
                    {statementType !== "Agency" &&
                    <NwLink onClick={handleChangeTransactionContact} disabled={areSomeTransactionsInvoiced} icon={light("money-check-pen")}>
                        Change billing contact
                    </NwLink>
                    }
                </div>
            </div>
            <div className={`transactions-container transactions-container-statements${readOnly ? " read-only" : ""}`}>
                <Table
                    rowSelection={invoiceCreationAllowed ? rowSelection : null}
                    showHeader={false}
                    pagination={false}
                    columns={transactionColumns}
                    size='small'
                    dataSource={statementsRows}
                    rowKey='ID'
                    rowClassName={(record) => {
                        let cn = ''
                        if (record.WorkDateCancelled) {
                            cn += 'cancelled'
                        }
                        if (!isStatementConfirmed(record)) {
                            cn += ' not-confirmed'
                        }
                        return cn
                    }}
                />
            </div>
            <div className="actions-on-selected-lines">
                {selectedStatementIDs.length > 0 &&
                    <small className="selected-lines-counter">{selectedStatementIDs.length} selected items</small>
                }
                {invoiceCreationAllowed &&
                    (someSelected(billingInfo) &&
                        <>
                            {checkDifferentCurrencies()
                                ?
                                <Alert
                                    message="You can't create an invoice with different currencies"
                                    type="error"
                                    showIcon
                                    style={{ marginTop: ".5rem", marginBottom: ".5rem" }}
                                />
                                :
                                <NwButton
                                    primary
                                    size='small'
                                    label='create invoice'
                                    onClick={() => { prepareInvoiceData() }}
                                    disabled={checkDifferentCurrencies()}
                                />
                            }
                            {statementType !== "Agency" &&
                                <NwButton
                                    primary
                                    size='small'
                                    label='change billing contact'
                                    onClick={() => { handleChangeSelectedTransactionContact() }}
                                />
                            }
                        </>
                    )
                }
            </div>
            {showCreateInvoiceDrawer &&
                <CreateInvoiceDrawer
                    onClose={() => setShowCreateInvoiceDrawer()}
                    onInvoiceCreated={handleInvoiceCreated}
                    data={currentInvoiceData}
                />
            }
            {showInvoiceDrawer &&
                <InvoiceDrawer
                    invoiceId={showInvoiceDrawer}
                    onClose={() => setShowInvoiceDrawer()}
                />
            }
            {showChangeContactDrawer &&
                <NwDrawer
                    title={`Change Transaction ${statementType}`}
                    onClose={handleCloseTransactionContactDrawer}>
                    <TransactionContact
                        contactID={contactID}
                        contactType={statementType}
                        billingInfoID={billingInfo.ID}
                        transactionIDs={transactionsIDsForContactChange}
                        transactionFamily="jobfee"
                        onChangeTransactionContact={handleCloseTransactionContactDrawer}
                        projectID={project.ID}
                    />
                </NwDrawer>
            }
        </div>
    )
};

export default ProjectGroupedStatementsBillingInfo;