import React, { useContext, useEffect, useMemo, useState } from "react";
import { withRouter, useLocation } from 'react-router-dom';
import styled from "styled-components";
import { Layout } from "antd";
import _ from 'lodash';

import ModelsWall from "Components/ModelSelector/ModelsWall/ModelsWall";
import NavigationBack from 'Components/ModelSelector/NavigationBack/NavigationBack';
import ModelsFilter from 'Components/ModelSelector/ModelsFilter';
import useLocalStorageState from "Hooks/UseLocalStorageState";
import { getContactName, useModelsList, useModelsWallIds, usePlaylistsCount, modelFullName } from "Hooks/Contact/UseContactsList";
import { useStateValue } from "Contexts/StateProvider";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { UserContext } from 'Contexts/UserContext';
import FlexContainer from "Components/Gui/FlexContainer";
//import { getLastLogs } from 'Libs/NwLogs';
import { StyledLayout, HeaderPanel } from "Components/Gui/StyledComponent";
import { getModelInTown } from 'Components/ModelSelector/ModelsWall//ModelsWallUtils';
import { MODELS_WALL_QUICKFILTER_DEFAULT } from 'Configs/WALLS_CONFIG';
import { useDebounce } from 'react-use';
import Axios from "axios";
import { deaccentizeandlowerize } from "Libs/NwUtils";

const { Content, Sider } = Layout;

const SiderStyled = styled(Sider)`
    height: calc(100vh - 60px);
    background-color: #fff;
    z-index: 1030;
    box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
        0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
        0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
        0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
    position: fixed;
    padding: 1em;
    overflow: auto;
`;

const ModelsLayout = styled(Layout)`
    margin-left: ${props => props.$size === 'big' ? '360px' : (props.$size === 'small' ? '220px' : '300px')};
    margin-left: ${props => props.$type === 'compact' && 0};
`;

const ModelsWallWrapper = styled.div`
    position: relative;
`;

const ModelSelector = ({
    addModelProps,
    connections,
    currentEvent,
    currentPackage,
    defaultView,
    disabledmodels,
    displayConnections,
    filterOnly,
    fixedView,
    history,
    isDrawer,
    layouttype,
    leftMargin,
    multipleSelection,
    navigateUrl,
    navigationTitle,
    onSelectModel,
    packageGroup,
    projectId,
    scope,
    selectedModel,
    showModelDrawerOnClick,
    startOnProjectModels
}) => {

    const location = useLocation();

    //const breakpoints = useNwBreakPoints();
    const breakpoints = useNwBreakPoints();
    const isMobile = breakpoints === 1;
    
    const { data: fullList, isFetching: fetchingModels } = useModelsList()
    const { data: playlistsCount } = usePlaylistsCount()
    const { currentUser } = useContext(UserContext)
    const [{ modelFiltersContext, folderContext, areaContext }, dispatch] = useStateValue()
    const { activeSet } = modelFiltersContext[areaContext.area]

    const currentfilters_storage_name = `${areaContext.area}_modelwall_currentfilters`
    const [filters, setFilters] = useLocalStorageState(currentfilters_storage_name)

    const quickfilters_storage_name = `${areaContext.area}_modelwall_quickfilters_${scope}`
    const [quickFilter, setQuickFilter] = useLocalStorageState(quickfilters_storage_name, MODELS_WALL_QUICKFILTER_DEFAULT);

    const listtype_storage_name = `${areaContext.area}_modelwall_listtype_${scope}`
    let listtype_storage_value = useLocalStorageState(listtype_storage_name, '')
    if (listtype_storage_value === '') {
        if (activeSet === "v0") {
            listtype_storage_value = 'all-models'
        } else {
            listtype_storage_value = 'smart-lists'
        }
    }
    const [listType, setListType] = useLocalStorageState(listtype_storage_name, 'all-models')

    const [modelEventsInfo, setModelEventsInfo] = useState()
    const [listUpdated, setListUpdated] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [projectModels, setProjectModels] = useState([]);

    useEffect(() => {
        if (projectId && startOnProjectModels) {
            const getProjectModels = async () => {
                const prjmod = await Axios.get(`/projectmodels/getforproject/${projectId}`);
                const ModelIDs = prjmod.map(pm => pm.ModelID);
                if (ModelIDs.length > 0) {
                    setListType("project")
                    setProjectModels(ModelIDs);
                } else {
                    setListType("all-models")
                }
            }
            getProjectModels();
        }
    }, []) //projectId, startOnProjectModels, scope


    const [searchType, setSearchType] = useState(false);

    const { data: filteredIDs, isFetching: fetchingModelsWallIds } = useModelsWallIds(
        filters,
        true,
        currentUser.ActiveContext.activeDepartments
    )

    // const { data: modelsWithBooksIds, isLoading: fetchingModelsWithBooks, fetchStatus: fetchStatusModelsWithBooks } = useModelsWithBooksIds(currentPackage && !fetchingModels);

    const [editFolder, setEditFolder] = useState(false);
    const [modelsList, setModelsList] = useState([]);
    const [modelsListNotFeaturesFiltered, setModelsListNotFeaturesFiltered] = useState([]);

    const [showOnlyModelsWithBooks, setShowOnlyModelsWithBooks] = useState(currentPackage ? true : false);

    const [debouncedNameFilter, setDebouncedNameFilter] = useState(quickFilter.nameFilter);

    useDebounce(() => {
        if (quickFilter.nameFilter !== debouncedNameFilter) {
            setDebouncedNameFilter(quickFilter.nameFilter)
        }
    }, 300, [quickFilter.nameFilter]);

    useEffect(() => {
        dispatch({
            type: "ModelFiltersSetAdvanced",
            area: areaContext.area,
            filters: []
        });
    }, []);

    useEffect(() => {
        if (location.state && location.state.contactId) {
            setQuickFilter({
                nameFilter: "",
                intown: "all",
                onlymine: false,
                tagFilter: [],
                quickFilters: {},
                sort: "Name",
                sortFolder: "Name",
                gender: "all",
                showQuickFilters: true
            })
        }
    }, [location])


    // useEffect(() => {
    //     if (filterType === "FOLDER") {
    //         setFiltersType("folders")
    //     }
    // }, [filterType]);

    const isLoadingModels = () => {
        if (fetchingModels || fetchingModelsWallIds) {
            return true
        }
        return false
    }

    useEffect(() => {
        if (!isLoadingModels()) {
            let areaFullList = fullList ? areaContext.area === 'scouting'
                ? fullList.filter(model => model.ST === 5)
                : fullList.filter(model => model.ST === 4) : [];
            let templist = []
            if (listType === "folders") {
                const folder = folderContext.openFolders.find(folder => folder.ID === activeSet);
                if (folder) {
                    templist = folder.items.map(itemId => {
                        const model = areaFullList.find(model => model.ID === itemId);
                        return model 
                        ? 
                            { ...model, STORAGE: false } 
                        : 
                            {
                                ID: itemId,
                                N: getContactName(itemId),
                                F: null,
                                STORAGE: true
                            }
                    });
                    templist = templist.filter(model => !model.STORAGE)
                }
            } else {
                if (listType === "project") {
                    templist = areaFullList.filter(model => projectModels.includes(model.ID))
                } else {
                    //if (activeSet === 'v0') {
                    if (listType === "all-models") {
                        const activeDepartments = currentUser.ActiveContext.activeDepartments
                        templist = areaFullList.filter(model => model.DEPS.some(departmentId => activeDepartments.includes(departmentId)))
                    }
                    if (listType === "smart-lists") {
                        if (!filteredIDs) {
                            templist = areaFullList
                        } else {
                            if (filteredIDs.length) {
                                templist = areaFullList.filter(item => filteredIDs.includes(item.ID))
                            }
                            else {
                                templist = [];
                            }
                        }
                    }
                }
            }
            setListUpdated(false)

            //if it's "find similar models", remove the current model from the list
            if (location.state && location.state.contactId) {
                templist = templist.filter((el) => el.ID !== location.state.contactId)
            }

            // quick filter
            if (listType === "all-models" && !searchType) { // quick search
                const { intown, onlymine, tagFilter, gender } = quickFilter;
                templist = templist.filter(item => {
                    let result = true;
                    if (debouncedNameFilter) { //nameFilter
                        const normalized_debouncedNameFilter = deaccentizeandlowerize(debouncedNameFilter)
                        const normalized_name = deaccentizeandlowerize(item.N)
                        const normalized_surname = deaccentizeandlowerize(item.S)
                        result = (normalized_name.startsWith(normalized_debouncedNameFilter)) || (normalized_surname.startsWith(normalized_debouncedNameFilter)) || ((normalized_name + ' ' + normalized_surname).startsWith(normalized_debouncedNameFilter))
                        //result = (item.N.toUpperCase().startsWith(debouncedNameFilter.toUpperCase())) || (item.S && item.S.toUpperCase().startsWith(debouncedNameFilter.toUpperCase())) || ((item.N.toUpperCase() + ' ' + item.S.toUpperCase()).startsWith(debouncedNameFilter.toUpperCase()))
                    }
                    if (tagFilter && tagFilter.length) {
                        tagFilter.map(tag => result = result && item.T.map(tag => tag.Name).includes(tag))
                    }
                    return result;
                })

                switch (intown) {
                    case "in":
                        templist = templist.filter(model => getModelInTown(model.ITS));
                        break;
                    case "out":
                        templist = templist.filter(model => !getModelInTown(model.ITS));
                        break;
                    default:
                        break;
                }

                console.log("gender", gender);
                    
                switch (gender) {
                    case "all":
                        break;
                    default:
                        templist = templist.filter(model => model.G.toLowerCase() === gender.toLowerCase());
                        break;
                }
                // case "Female":
                //     templist = templist.filter(model => Number(model.G) === 'Female');
                //     break;
                // case "male":
                //     templist = templist.filter(model => Number(model.G) === 'Male');
                //     break;
                // case "non-binary":
                //     templist = templist.filter(model => Number(model.G) === 'NonBinary');
                //     break;
                // default:
                //     break;
                

                if (onlymine) {
                    templist = templist.filter(model => {
                        if (model.U.find(user => user.UserID === currentUser.UserID)) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                }
            }

            //if it's package, remove models without books (if the option is selected)
            if (currentPackage && showOnlyModelsWithBooks) {
                if (playlistsCount && playlistsCount.length) {
                    const modelsWithBooksIDs = playlistsCount.map(pc => pc.ID)
                    templist = templist.filter(model => modelsWithBooksIDs.includes(model.ID))
                } else {
                    templist = []
                }
            }

            setModelsListNotFeaturesFiltered([...templist])
            if (listType === "all-models" && !searchType) {
                // quick filters on features active
                const { showQuickFilters, quickFilters } = quickFilter;
                if (showQuickFilters) {
                    templist = templist.filter(model => model.ST).filter(model => {
                        const modelFeature = model.F ? JSON.parse(model.F) : []
                        const features = [];
                        if (modelFeature) {
                            for (const feature of modelFeature) {
                                if (feature.Features.length) {
                                    for (const subFeature of feature.Features) {
                                        features.push(subFeature);
                                    }
                                }
                            }
                        }
                        let filtered = true;
                        for (const filterKey of Object.keys(quickFilters)) {
                            if (quickFilters[filterKey]) {
                                const feature = features.find(feature => feature.FeatureID === filterKey);
                                if (feature) {
                                    if (feature.TypeName === 'Choice' && !quickFilters[filterKey].find(value => value === feature.Value)) {
                                        filtered = false;
                                        break;
                                    }
                                    if (feature.TypeName === 'Conversion') {
                                        const featureValue = parseInt(feature.Value);
                                        if (quickFilters[filterKey][0] > featureValue || quickFilters[filterKey][1] < featureValue) {
                                            filtered = false;
                                            break;
                                        }
                                    }
                                    if (feature.TypeName === 'Numeric') {
                                        const featureValue = parseFloat(feature.Value);
                                        if (quickFilters[filterKey][0] > featureValue || quickFilters[filterKey][1] < featureValue) {
                                            filtered = false;
                                            break;
                                        }
                                    }
                                    if (feature.TypeName === 'Rating') {
                                        const modelRating = parseInt(feature.Value);
                                        if (modelRating < quickFilters[filterKey]) {
                                            filtered = false;
                                        }
                                    }
                                } else {
                                    filtered = false;
                                    break;
                                }
                            }
                        }
                        return filtered;
                    })
                }
            }

            //sorting models
            if (isMobile) {
                templist = _.sortBy(templist, [function (o) { return modelFullName(o).trim().toLowerCase() }])
            } else {
                if (listType === 'folders') {
                    if (!editFolder && quickFilter.sortFolder === "Name") {
                        templist = _.sortBy(templist, [function (o) { return modelFullName(o).trim().toLowerCase() }])
                    }
                } else {
                    switch (quickFilter.sort) {
                        case "Name":
                            templist = _.sortBy(templist, [function (o) { return modelFullName(o).trim().toLowerCase() }])
                            break
                        case "MotherAgency":
                            templist = _.sortBy(templist, [function (o) { return o.MAN ? o.MAN.trim().toLowerCase() : 'zzz' }])
                            break
                        case 'ID':
                            templist = _.sortBy(templist, [function (o) { return o.ID }])
                            break
                        default:
                            templist = _.sortBy(templist, [function (o) { return modelFullName(o).trim().toLowerCase() }])
                    }
                }
            }
            setModelsList([...templist])
        }
    }, [listType, fullList, filteredIDs, activeSet, fetchingModels, fetchingModelsWallIds, listUpdated, quickFilter, debouncedNameFilter, searchType, folderContext, playlistsCount, showOnlyModelsWithBooks, projectModels])


    useEffect(() => {
        if (currentPackage) {
            if (modelsList && modelsList.length > 0) {
                if (playlistsCount && playlistsCount.length > 0) {
                    for (const pc of playlistsCount) {
                        const model = modelsList.find(model => model.ID === pc.ID)
                        if (model) {
                            model.FP = pc.FP
                            model.PL = pc.PL
                        }
                    }
                }
            }
        }
    }, [modelsList, playlistsCount])

    const handleUpdateModelEvents = modelEventsData => {
        setModelEventsInfo(modelEventsData);
    };

    let tags = useMemo(() => {
        let tags = [];
        modelsList.map(model => {
            if (model.T) {
                model.T.map(tag => {
                    if (!tags.includes(tag.Name)) {
                        tags.push(tag.Name)
                    }
                    return null;
                })
            }
            return null;
        });

        tags.sort();
        return tags;
    }, [modelsList]);

    const renderModelsWall = () => {
        return (
            <ModelsWallWrapper>
                <ModelsWall
                    addModelProps={addModelProps}
                    connections={connections}
                    currentEvent={currentEvent}
                    currentPackage={currentPackage}
                    defaultView={defaultView}
                    disabledmodels={disabledmodels}
                    disableSelectModels={filterOnly}
                    displayConnections={displayConnections}
                    editFolder={editFolder}
                    filtersType={listType}
                    fixedView={fixedView}
                    inSideBar={false}
                    isDrawer={isDrawer}
                    isLoading={isLoading || fetchingModelsWallIds}
                    leftMargin={leftMargin}
                    list={modelsList}
                    listNotFeaturesFiltered={modelsListNotFeaturesFiltered}
                    listUpdated={listUpdated}
                    modelEventsInfo={modelEventsInfo}
                    multipleSelection={multipleSelection}
                    onChangeShowOnlyModelsWithBooks={currentPackage ? setShowOnlyModelsWithBooks : null}
                    onEditFolder={setEditFolder}
                    onSelectModel={onSelectModel}
                    onSetQuickFilter={setQuickFilter}
                    onUpdateModelEvents={handleUpdateModelEvents}
                    packageGroup={packageGroup}
                    quickFilter={quickFilter}
                    scope={scope}
                    searchType={searchType}
                    selectedModel={selectedModel}
                    showModelDrawerOnClick={showModelDrawerOnClick}
                    showOnlyModelsWithBooks={showOnlyModelsWithBooks}
                    tags={tags}
                />
            </ModelsWallWrapper>
        )
    };

    const renderFilterSelect = (inSideBar) => {
        return (
            <ModelsFilter
                filters={filters}
                filtersType={listType}
                inSideBar={inSideBar}
                isDrawer={isDrawer}
                layouttype={breakpoints < 2 ? 'fullpage-compact' : layouttype}
                leftMargin={leftMargin}
                navigateUrl={navigateUrl}
                onEditFolder={setEditFolder}
                onListUpdated={setListUpdated}
                onSetFilters={setFilters}
                onSetLoading={setIsLoading}
                onSetQuickFilter={setQuickFilter}
                onSetSearchType={setSearchType}
                projectId={projectId}
                projectModels={projectModels}
                quickFilter={quickFilter}
                searchType={searchType}
                setFiltersType={setListType}
                startOnProjectModels={startOnProjectModels && projectModels.length > 0}
                tags={tags}
            />
        )
    };

    const getSiderWidth = () => {
        if (startOnProjectModels) {
            return 360;
        }
        if (breakpoints < 3) {
            return 200;
        }
        return 300;
    }

    const getModelsLayoutSize = () => {
        if (startOnProjectModels) {
            return 'big';
        }
        if (breakpoints < 3) {
            return 'small';
        }
        return 'medium';
    }

    switch (layouttype) {
        case "fullpage":
            return (
                <>
                    <NavigationBack navigationTitle={navigationTitle} navigationUrl={navigateUrl} />
                    <StyledLayout $navigation={navigateUrl}>
                        {(history.location.pathname !== "/manage-folders" && breakpoints > 2) 
                        ?
                            <SiderStyled width={getSiderWidth()}>
                                {renderFilterSelect(false)}
                            </SiderStyled> 
                        : 
                            null
                        }
                        <ModelsLayout $size={getModelsLayoutSize()} $type={breakpoints < 3 && 'compact'}>
                            <Content>
                                {history.location.pathname !== "/manage-folders" && breakpoints < 3 &&
                                    <HeaderPanel>
                                        <FlexContainer fullWidth justifyContent='space-between' alignItems='flex-start'>
                                            <FlexContainer style={{ position: "relative" }} fullWidth>
                                                {renderFilterSelect(false)}
                                            </FlexContainer>
                                        </FlexContainer>
                                    </HeaderPanel>
                                }
                                {renderModelsWall()}
                            </Content>
                        </ModelsLayout>
                    </StyledLayout>
                </>
            );
        case 'fullpage-compact':
            return (
                <>
                    <NavigationBack navigationTitle={navigationTitle} navigationUrl={navigateUrl} />
                    <StyledLayout $navigation={navigateUrl}>
                        <ModelsLayout $type='compact'>
                            <Content>
                                {(history.location.pathname !== "/manage-folders") &&
                                    <HeaderPanel>
                                        <FlexContainer fullWidth justifyContent='space-between' alignItems='flex-start'>
                                            <FlexContainer style={{ position: "relative" }}>
                                                {renderFilterSelect(false)}
                                            </FlexContainer>
                                        </FlexContainer>
                                    </HeaderPanel>
                                }
                                {renderModelsWall()}
                            </Content>
                        </ModelsLayout>
                    </StyledLayout>
                </>
            );
        default:
            return (
                <React.Fragment>
                    {renderFilterSelect(true)}
                    <ModelsWall
                        addModelProps={addModelProps}
                        connections={connections}
                        currentEvent={currentEvent}
                        currentPackage={currentPackage}
                        defaultView={defaultView}
                        disabledmodels={disabledmodels}
                        disableSelectModels={true}
                        displayConnections={displayConnections}
                        editFolder={editFolder}
                        filtersType={listType}
                        fixedView={fixedView}
                        inSideBar={true}
                        isDrawer={isDrawer}
                        isLoading={isLoading || fetchingModelsWallIds}
                        leftMargin={leftMargin}
                        list={modelsList}
                        listNotFeaturesFiltered={modelsListNotFeaturesFiltered}
                        listUpdated={listUpdated}
                        modelEventsInfo={modelEventsInfo}
                        multipleSelection={multipleSelection}
                        onChangeShowOnlyModelsWithBooks={setShowOnlyModelsWithBooks}
                        onEditFolder={setEditFolder}
                        onSelectModel={onSelectModel}
                        onSetQuickFilter={setQuickFilter}
                        onUpdateModelEvents={handleUpdateModelEvents}
                        packageGroup={packageGroup}
                        quickFilter={quickFilter}
                        scope={scope}
                        searchType={searchType}
                        selectedModel={selectedModel}
                        showOnlyModelsWithBooks={showOnlyModelsWithBooks}
                        tags={tags}
                    />
                </React.Fragment>
            );
    }
};

export default withRouter(ModelSelector);
