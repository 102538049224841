import React, { useMemo } from 'react';
import { Table } from 'antd';

import { GetTimestampFromDate, isSameDate, showDateNoTime } from 'Libs/NwMoment';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import Money from 'Components/Gui/Money';
import { NwEditButton } from 'Components/Gui/NwButton';
import { getMoment } from "Libs/NwMoment";
import TransactionEvent from 'Components/Transactions/TransactionEvent';
import TransactionTitle from 'Components/Transactions/TransactionTitle';
import VerticalDate from 'Components/Dates/VerticalDate';
import StatementStatusIcon from 'Components/Transactions/StatementStatusIcon';
import { uniq } from 'lodash';
import NwIcon from 'Components/Gui/NwIcon';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';



const ProjectGroupedTransactionsListItem = ({
    eventDrawer,
    expandedItems,
    isExpanded,
    model,
    onEditTransaction,
    onItemExpansion,
    project,
    readOnly,
}) => {

    const renderTransactionAgFee = record => {
        const agfeeStatement = record.ClientStatements.find(statement => statement.isCommission);
        return agfeeStatement ? `${agfeeStatement.TransactionPerc}% ag. fee` : ''
    };

    const renderTransactionAdvanceClient = record => {
        const clientAdvanceStatement = record.ClientStatements.find(statement => statement.isClientAdvance);
        return clientAdvanceStatement ? `${clientAdvanceStatement.Amount} advance` : ''
    };

    const renderTransactionCommission = record => {
        const commissionStatement = record.ModelStatements.find(statement => statement.isCommission);
        return commissionStatement ? `${commissionStatement.TransactionPerc}% comm. on model` : ''
    }

    const TransactionRefDate = ({ transaction }) => {
        if (transaction.RefDate && !transaction.WorkDate) {
            if (getMoment(transaction.RefDate).year() > 1900) {
                return (<p className='upper'>{showDateNoTime(transaction.RefDate)}</p>)
            }
        }
        return null
    }

    const isItemExpanded = (itemID) => {
        return isExpanded || expandedItems.includes(itemID);
    }

    const toggleItemExpansion = (itemID) => {
        if (!isExpanded) {
            if (!expandedItems.includes(itemID)) {
                onItemExpansion([...expandedItems, itemID]);
            } else {
                onItemExpansion(expandedItems.filter(id => id !== itemID));
            }
        }
    }

    const statementStatusAdditionalText = (statement) => {
        if (statement.isCommission) {
            return 'Agency Fee'
        }
        if (statement.isClientAdvance) {
            return 'Advance'
        }
        return 'Fee'
    }

    const MultipleStatementStatus = ({ statements }) => (
        <div className="transaction-status">
            {statements.map(st => (
                <StatementStatusIcon additionalText={statementStatusAdditionalText(st)} statement={st} />
            ))}
        </div>
    )

    const SingleStatementStatus = ({ statement }) => (
        <div className="transaction-status">
            <StatementStatusIcon statement={statement} />
        </div>
    )

    const sameInvoiceNumber = (statements) => {
        return statements.every(statement => statement.InvoiceNumber === statements[0].InvoiceNumber)
    }

    const transactionColumns = [
        {
            title: 'Status',
            key: 'Status',
            width: '10%',
            align: 'center',
            render: record => {
                if (record.ClientStatements.length > 0) {
                    if (record.ClientStatements.length > 1) {
                        if (record.ClientStatements[0].Status === record.ClientStatements[1].Status) {
                            if (record.ClientStatements[0].Status === "Invoiced") {
                                if (sameInvoiceNumber(record.ClientStatements)) {
                                    return (<SingleStatementStatus statement={record.ClientStatements[0]} />)
                                } else {
                                    return (<MultipleStatementStatus statements={record.ClientStatements} />)
                                }
                            } else {
                                return (<SingleStatementStatus statement={record.ClientStatements[0]} />)
                            }
                        } else {
                            return (<MultipleStatementStatus statements={record.ClientStatements} />)
                        }
                    } else {
                        return (<SingleStatementStatus statement={record.ClientStatements[0]} />)
                    }
                } else {
                    return (<div></div>)
                }
            }
        },
        {
            title: 'Title',
            key: 'Title',
            width: '40%',
            render: record => {
                return (
                    <div className="transaction-left">
                        <TransactionTitle transaction={record} />
                        {isItemExpanded(record.ID) &&
                            <small>{record.Description || <em className="super-muted">no description</em>}</small>
                        }
                        {record.ClientBillingInfo &&
                            <div>{record.ClientBillingInfo.CompanyName.toUpperCase()}</div>
                        }
                    </div>
                )
            }
        },
        {
            title: 'Event',
            key: 'Event',
            width: '35%',
            render: record => {
                const usage = project.Usages.find(usage => usage.ID === record.UsageID);
                return (
                    <div className="transaction-middle">
                        <TransactionEvent transaction={record} project={project} />
                        <TransactionRefDate transaction={record} />
                        {isItemExpanded(record.ID) && usage &&
                            <div>{`${usage.Areas[0]} - ${usage.Duration}`.toLocaleLowerCase()}</div>
                        }
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            key: 'Amount',
            width: '25%',
            align: 'right',
            render: record => {
                return (
                    <div className="transaction-right">
                        <div className="transaction-fee">
                            {(isItemExpanded(record.ID) && (record.Quantity !== 1 || record.TypeQuantity !== 'Units')) &&
                                <><Money amount={record.SingleFee} currency={record.Currency} />&nbsp;{`x ${record.Quantity} ${record.TypeQuantity}`}</>
                            }
                            <div className='total-fee'><Money highlightCurrency amount={record.TotalFee} currency={record.Currency} /></div>
                            {(isItemExpanded(record.ID) && record.Family === 'JobFee') &&
                                <>
                                    <small>{renderTransactionAgFee(record)}<br />{renderTransactionCommission(record)}</small>
                                    <small>{renderTransactionAdvanceClient(record)}</small>
                                </>
                            }
                        </div>
                        <div className="edit-button">
                            {!readOnly &&
                                <NwEditButton iconOnly ghost onClick={(e) => {
                                    e.stopPropagation()
                                    onEditTransaction(record)
                                }} />
                            }
                        </div>
                    </div>
                )
            }
        },
    ];

    const transactionColumnsEventDrawer = [
        {
            title: 'Title',
            key: 'Title',
            render: record => {
                return (
                    <div className="transaction-left">
                        <TransactionTitle transaction={record} />
                        {isItemExpanded(record.ID) &&
                            <small>{record.Description || <em className="super-muted">no description</em>}</small>
                        }
                        {record.ClientBillingInfo &&
                            <div>{record.ClientBillingInfo.CompanyName.toUpperCase()}</div>
                        }
                        <TransactionEvent transaction={record} />
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            key: 'Amount',
            align: 'right',
            render: record => {
                return (
                    <div className="transaction-right">
                        <div className='transaction-fee'>
                            {(isItemExpanded(record.ID) && (record.Quantity !== 1 || record.TypeQuantity !== 'Units')) &&
                                <><Money amount={record.SingleFee} currency={record.Currency} />&nbsp;{`x ${record.Quantity} ${record.TypeQuantity}`}</>
                            }
                            <div className='total-fee'><Money amount={record.TotalFee} currency={record.Currency} /></div>
                            {(isItemExpanded(record.ID) && record.Family === 'JobFee') &&
                                <small>{renderTransactionAgFee(record)}<br />{renderTransactionCommission(record)}</small>
                            }
                        </div>
                        <div>
                            {!readOnly &&
                                <NwEditButton ghost iconOnly onClick={(e) => {
                                    e.stopPropagation()
                                    onEditTransaction(record)
                                }} />
                            }
                        </div>
                    </div>
                )
            }
        },
    ];

    const isDateCancelled = (date) => {
        return date.Transactions.every(transaction => transaction.WorkDateCancelled)
    }

    const isTransactionConfirmed = (transaction) => {
        if (transaction.ClientStatements.length > 0) {
            return transaction.ClientStatements[0].Status !== "Option"
        } else {
            return true
        }
    }

    const confirmedDatesWithoutTransactions = useMemo(() => {
        const edm = project.Events.flatMap(event => event.EventDates.flatMap(date => date.EventDatesModels))
        const confirmed_edm = edm.filter(edm => edm.OptionNumber === 10 && !edm.Cancel && edm.ModelID === model.ModelID)
        const confirmed_dates = uniq(confirmed_edm.map(edm => edm.FromDate)).sort()
        const confirmed_dates_without_transactions = confirmed_dates.filter(date => !model.Dates.some(d => isSameDate(d.Date, date)))  
        return confirmed_dates_without_transactions
    }, [model, project])

    return (
        <div className="subject-container">
            {model.ModelID
                ?
                <h2>{getContactName(model.ModelID)}</h2>
                :
                <h2>General</h2>
            }
            {model.Dates.map((date) => {
                return (<div className="date-container" key={`model-${model.ModelID ? model.ModelID : '0'}-${date.Date ? GetTimestampFromDate(date.Date) : '0'}`}>
                    <VerticalDate size="small" date={date.Date} cancelled={isDateCancelled(date)} />
                    <div className="transactions-container">
                        <Table
                            showHeader={false}
                            pagination={false}
                            columns={eventDrawer ? transactionColumnsEventDrawer : transactionColumns}
                            size='small'
                            rowClassName={(record) => {
                                let cn = ''
                                if (record.WorkDateCancelled) {
                                    cn += 'cancelled'
                                }
                                if (!isTransactionConfirmed(record)) {
                                    cn += ' not-confirmed'
                                }
                                return cn
                            }}
                            dataSource={date.Transactions.map(transaction => ({ ...transaction, key: transaction.ID, WorkDate: date.Date, ModelID: model.ModelID }))}
                            onRow={(record) => {
                                return {
                                    onClick: () => { toggleItemExpansion(record.ID) }
                                };
                            }}
                        />
                    </div>
                </div>
                )
            })}
            {confirmedDatesWithoutTransactions.length > 0 &&
                <div className="date-container">
                    <div className="dates-without-transactions">
                        <NwIcon icon={solid("triangle-exclamation")} style={{ marginRight: '1rem', verticalAlign: "middle" }} size="2x" danger />
                        <p>confirmed dates without transactions:</p>
                        <div>
                            {confirmedDatesWithoutTransactions.map(d => (
                                <span key={showDateNoTime(d)} className="date-without-transactions">{showDateNoTime(d)}</span>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default ProjectGroupedTransactionsListItem;