import React from 'react';
import { Field } from "formik";
import { getMoment } from "Libs/NwMoment";

import { NWTextAreaCharCounter, NWInput, NWSelect, NWCheckbox, NWDatePicker } from 'Components/Gui/NWForm/NwFormItems';

const getCustomFieldInitialValue = (typeName, value) => {
    switch(typeName) {
        case 'MultipleChoice':
            return value ? value.split(',') : [];
        case 'Boolean':
            return value ? value : false;
        case 'Numeric':
            return value ? value : 0;
        case 'Date':
            return value ? getMoment(value) : ''
        default: 
            return value ? value : '';
    };
};

const validateNumber = value => {
    let error;
    if (value.length > 0 && !/^[-+]?\d+$/.test(value)) {
        error = 'It should be integer';
    }
    return error;
};

const getCustomField = (values, customField) => {
    switch(customField.TypeName) {
        case 'String':
            return <Field
                component={NWInput}
                label={customField.Name.replace(/_/g, ' ')}
                name={customField.Name}
                type="text"
                value={values[customField.Name]}
            />
        case 'MultipleLineString':
            return <Field
                component={NWTextAreaCharCounter}
                label={customField.Name.replace(/_/g, ' ')}
                name={customField.Name}
                type="text"
                value={values[customField.Name]}
                maxLength={2000}
                autoSize={{ minRows: 5, maxRows: 10 }}
            />
        case 'Choice':
            return <Field
                component={NWSelect}
                label={customField.Name.replace(/_/g, ' ')}
                name={customField.Name}
                value={values[customField.Name]}
                options={customField.Options.map(option => {
                    return { key: option, label: option, value: option };
                })}
            />  
        case 'MultipleChoice':
            return <Field
                component={NWSelect}
                label={customField.Name.replace(/_/g, ' ')}
                name={customField.Name}
                value={values[customField.Name]}
                mode="tags"
                options={customField.Options.map(option => {
                    return { key: option, label: option, value: option };
                })}
            />  
        case 'Boolean':                
            return <Field
                component={NWCheckbox}
                text={customField.Name.replace(/_/g, ' ')}
                value={values[customField.Name]}
                name={customField.Name}
            />
        case 'Numeric':
            return <Field
                component={NWInput}
                label={customField.Name.replace(/_/g, ' ')}
                name={customField.Name}
                validate={validateNumber}
                type="text"
                value={values[customField.Name]}
            />
        case 'Date':
            return <Field
                component={NWDatePicker}
                value={values[customField.Name]}
                label={customField.Name.replace(/_/g, ' ')}
                name={customField.Name}
            />
        default:
            return <div />
    }
};

const getEmbeddedCustomField = (values, customField) => {
    switch(customField.TypeName) {
        case 'String':
            return <Field
                component={NWInput}
                label={customField.Name.replace(/_/g, ' ')}
                name={`CustomFields.${customField.Name}`}
                type="text"
                value={values.CustomFields[customField.Name]}
            />
        case 'MultipleLineString':
            return <Field
                component={NWTextAreaCharCounter}
                label={customField.Name.replace(/_/g, ' ')}
                name={`CustomFields.${customField.Name}`}
                type="text"
                value={values.CustomFields[customField.Name]}
                maxLength={2000}
                autoSize={{ minRows: 5, maxRows: 10 }}
            />
        case 'Choice':
            return <Field
                component={NWSelect}
                label={customField.Name.replace(/_/g, ' ')}
                name={`CustomFields.${customField.Name}`}
                value={values.CustomFields[customField.Name]}
                options={customField.Options.map(option => {
                    return { key: option, label: option, value: option };
                })}
            />  
        case 'MultipleChoice':
            return <Field
                component={NWSelect}
                label={customField.Name.replace(/_/g, ' ')}
                name={`CustomFields.${customField.Name}`}
                value={values.CustomFields[customField.Name]}
                mode="tags"
                options={customField.Options.map(option => {
                    return { key: option, label: option, value: option };
                })}
            />  
        case 'Boolean':                
            return <Field
                component={NWCheckbox}
                text={customField.Name.replace(/_/g, ' ')}
                value={values.CustomFields[customField.Name]}
                name={`CustomFields.${customField.Name}`}
            />
        case 'Numeric':
            return <Field
                component={NWInput}
                label={customField.Name.replace(/_/g, ' ')}
                name={`CustomFields.${customField.Name}`}
                validate={validateNumber}
                type="text"
                value={values.CustomFields[customField.Name]}
            />
        case 'Date':
            return <Field
                component={NWDatePicker}
                value={values.CustomFields[customField.Name]}
                label={customField.Name.replace(/_/g, ' ')}
                name={`CustomFields.${customField.Name}`}
                format='ddd DD MMM YY'
            />
        default:
            return <div />
    }
  };

export {
    validateNumber,
    getCustomFieldInitialValue,
    getCustomField,
    getEmbeddedCustomField
}