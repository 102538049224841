import React from "react";
import { Formik, Field } from "formik";
import { getMoment } from "Libs/NwMoment";

import { NwButton } from "Components/Gui/NwButton";
import {
  NWDatePicker,
  NWInput,
  NWTextAreaCharCounter,
  NWContactSelector,
  NWVisibilitySelector
} from "Components/Gui/NWForm/NwFormItems";
import { NwForm } from 'Components/Gui/NWForm/NwFormWrapper';
import { validateVisibility } from "Libs/NwUtils";
import { useStateValue } from 'Contexts/StateProvider';
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';
import Axios from "axios";
import { forceReloadContactDocuments, onContactMediaUpdate } from "Hooks/Contact/UseContactMedia";
import { datePickerBeforeOnChange } from "Libs/NwMoment";

const MultiMediaInfoForm = ({ mediaList, contactID, onTouchForm }) => {
    useFormFieldFocus('Title')
    const [{areaContext}] = useStateValue()
    const initialValues = {
        Title: '',
        ShootingDate: null,
        Location: '',
        Notes: '',
        Author: '',
        PhotographerID: null,
        Visibility: {
            isGlobal: true,
            Areas: []
        }
    }
    
    const handleSaveMediaDetail = async (values, setSubmitting) => {
        setSubmitting(true)
        const updatedMediaInfo = {
            ...values,
            VisibilityAreas: values.Visibility.Areas,
            DocumentsToUpdate: mediaList.map(media => media.ID)
        }
        try {
            await Axios.put(`/documents/updatemetadata`, updatedMediaInfo)
            onContactMediaUpdate(contactID)
            onTouchForm(false)
            setSubmitting(false)
        } catch (error) {
            console.log("errore", error)
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                handleSaveMediaDetail(values, setSubmitting)
            }}
            render={({
                isSubmitting,
                values,
                handleSubmit
            }) => (
                <NwForm 
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit} 
                    layout="vertical">
                    <Field
                        component={NWInput}
                        label="Title"
                        name="Title"
                        type="text"
                        value={values.Title}
                        id='Title'
                    />
                    <Field
                        component={NWInput}
                        label="Location"
                        name="Location"
                        type="text"
                        value={values.Location}
                    />
                    <Field
                        component={NWInput}
                        label="Author"
                        name="Author"
                        type="text"
                        value={values.Author}
                    />
                    <Field
                        component={NWDatePicker}
                        label="Shooting date"
                        name="ShootingDate"
                        format="MMM DD, YYYY"
                        getCalendarContainer={triggerNode => triggerNode.parentNode}
                        style={{ width: "100%" }}
                        value={values.ShootingDate ? getMoment(values.ShootingDate) : null}
                        manipulateValue={datePickerBeforeOnChange}
                    />
                    <Field
                        required
                        component={NWContactSelector}
                        label="Photographer"
                        name="PhotographerID"
                        activeTypes={["srv", 'cli']}
                        // activableTypes={['cli', 'srv']}
                        value={values.PhotographerID}
                    />
                    <Field
                        component={NWTextAreaCharCounter}
                        label="Notes"
                        name="Notes"
                        value={values.Notes}
                        maxLength={2000}
                        type="text"
                        autoSize={{ minRows: 2, maxRows: 5 }}
                    />         
                    <Field
                        component={NWVisibilitySelector}
                        label="Visibility"
                        inlineLabel
                        name="Visibility"
                        value={values.Visibility}
                        validate={validateVisibility(values.Visibility, areaContext.area)}
                    /> 
                    <br />
                    {/* <Button disabled={isSubmitting} onClick={() => onCancel(null)}>
                        Cancel
                    </Button> */}
                    &nbsp;
                    <NwButton
                        htmlType="submit"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        label="Save"
                        loadingLabel="Updating..."
                    />
                </NwForm>
            )}
        />
    );
};

export default MultiMediaInfoForm;
