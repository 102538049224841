import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { NwEditButton } from "Components/Gui/NwButton";
import { NwCard } from 'Components/Gui/NwCard/NwCard';
import { ListContext } from 'Contexts/ListContext';
import { mergeCustomFields, parseCustomFieldValue } from 'Libs/NwUtils';
import CustomPropertiesForm from 'Components/ContactDetails/CommonComponents/CustomProperties/CustomPropertiesForm';
import NwDrawer from 'Components/Gui/NwDrawer';

const DetailItemContainer = styled.div`
    display: flex;
    padding: 4px 0;
`;

const DetailItemLabel = styled.div`
    min-width: 120px;
    white-space: nowrap;
    margin-right: 8px;
    font-size: 0.9rem;
    text-transform: uppercase;
`;

const DetailItemValue = styled.div`
    font-size: 0.9rem;
    font-weight: bold;
`;

const Wrapper = styled.div`
    height: ${(props) => props.$fullHeight ? "100%" : "auto"};
    .ant-list-item {
        align-items: flex-start;
    }
    .ant-card-body-content {
        padding: 0 !important;
    }
    .ant-card {
        height: ${(props) => props.$fullHeight ? "100%" : "auto"};
    }
    .content {
        padding: 1rem 2rem;
    }
`;

const ContactDetailsCustomProperties = ({ currentContact, contactType, fullHeight, isInCardTab, editMode, afterEditingProperties }) => {
    const [isEditInfo, setIsEditInfo] = useState(editMode);
    const { cachedList } = useContext(ListContext);
    const [touchForm, setTouchForm] = useState(false);
    const customFields = cachedList.customFields.items[contactType]
    const mergedCustomFields = mergeCustomFields(customFields.filter(field => !field.Disabled), currentContact.CustomFields, true)

    const handleEditInfo = () => {
        setIsEditInfo(true);
    };

    const handleCloseEditInfo = () => {
        afterEditingProperties();
        setIsEditInfo(false);
    };

    
    useEffect(() => {
        setIsEditInfo(editMode)
    }, [editMode]);

    const CardContent = () => (
        <div className='content'>
            {mergedCustomFields.map((field, index) => (
                <DetailItemContainer key={`custom-field-${field.Name}`}>
                    <DetailItemLabel>{field.Name.replace(/_/g, ' ')}</DetailItemLabel>
                    <DetailItemValue>{parseCustomFieldValue(field)}</DetailItemValue>
                </DetailItemContainer>
            ))}
        </div>
    )

    return (
        <Wrapper $fullHeight={fullHeight}>
            {isInCardTab
                ?
                <CardContent />
                :
                <NwCard
                    title="Additional Info"
                    padding={'no-padding'}
                    extra={
                        <NwEditButton ghost onClick={handleEditInfo} />
                    }
                >
                    <CardContent />
                </NwCard>
            }
            {isEditInfo &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title='Edit Properties'
                    onClose={handleCloseEditInfo}>
                    <CustomPropertiesForm 
                        onTouchForm={setTouchForm}
                        touchForm={touchForm}
                        contactID={currentContact.ID} 
                        customFields={customFields}
                        type={1}
                        onCancel={handleCloseEditInfo} />
                </NwDrawer>
            }
        </Wrapper>
    );
};

export default ContactDetailsCustomProperties;