import React, { useContext, useState } from "react";
// import { useHistory } from 'react-router-dom';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { getMoment } from "Libs/NwMoment";

//import useLocalStorageState from 'Hooks/UseLocalStorageState';
import NwDrawer from 'Components/Gui/NwDrawer';
import {
    NWInput,
    NWProjectSelector
} from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { UserContext } from 'Contexts/UserContext';
import { getNow, showDate } from 'Libs/NwMoment';
import { useAddPackage, useAddPlaylistToPackage, forceReloadFullSearchPackages } from 'Hooks/Packages/UsePackages';

const AddPackageDrawer = ({ packageName, bookId, projectId, onAddedPackage, onClose }) => {
    const { currentUser } = useContext(UserContext);
    const { mutateAsync: onAddPackage } = useAddPackage();
    const { mutateAsync: onAddPlaylistToPackage } = useAddPlaylistToPackage();
    // const history = useHistory();
    const [touchForm, setTouchForm] = useState(false);
    //const [filters] = useLocalStorageState(`packagewallfilter_currentfilters`);

    const initialValues = {
        Title: packageName || `${currentUser.Name} ${currentUser.Surname} ${showDate(getNow())}`,
        ProjectID: projectId ? projectId : ''
    };

    const savePackage = async (values, setSubmitting) => {
        const data = {
            name: values.Title,
            projectID: values.ProjectID
        }
        
        try {
            const pack = await onAddPackage({ data });
            setTouchForm(false);

            if (bookId) {
                const data = {
                    packageID: pack.ID,
                    playlistID: bookId
                }
                await onAddPlaylistToPackage({ 
                    data,
                    isActivePackage: true
                });
            }
            //forceReloadFullSearchPackages();

            // history.push(`/package/${pack.ID}`);
            if (onAddedPackage) {
                onAddedPackage(pack.ID);
            }
            setSubmitting(false);
            onClose();
        } catch (error) {
            console.log('ant : Add New Package Error => ', error);
            setSubmitting(false);
        }
    };

    return (
        <NwDrawer
            title='New Package'
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Content is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    savePackage(values, setSubmitting);
                }}>
                {({ values, handleSubmit, isSubmitting }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Field
                            component={NWInput}
                            label="Package Title"
                            name="Title"
                            type="text"
                            value={values.Title} />
                        {!projectId && 
                            <Field
                                component={NWProjectSelector}
                                name="ProjectID"
                                value={values.ProjectID} />
                        }
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        setTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    label="Create"
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </NwForm>
                )}
            </Formik>
        </NwDrawer>
    );
}


export default AddPackageDrawer;