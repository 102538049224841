import React from 'react';
import styled from 'styled-components';

import emptystatesdrawings from '_img/drawings/index';
import { Empty } from 'antd';

// confident
// martini
// blushing
// shootingstar
// desertisland
// lighthouse
// paperplanet
// weightlifter

const EmptyStateDiv = styled.div`
    margin-top: 2em;
    text-align: center;
    display: ${props => props.$disable && 'none'};

    img {
        max-height: 80px;
        max-width: 80px;
    }
    p {
        font-size: 1.1em;
        margin-top: 1em;
        font-style: italic;
    }
`

const EmptyState = ({disable, drawing, message, extra, ...rest}) => {
    if (drawing) {
        return (
            <EmptyStateDiv className="nw-empty-state" disable={disable} {...rest}>
                <img src={emptystatesdrawings[drawing]} alt={message} />
                <p>{message}</p>
                <div style={{paddingTop:"1em"}}>{extra}</div>
            </EmptyStateDiv>
        )
    } else {
        return (<EmptyStateDiv className="nw-empty-state" $disable={disable} {...rest}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={message || false} /><div style={{paddingTop:"1em"}}>{extra}</div></EmptyStateDiv>)
    } 
    
}

export default EmptyState