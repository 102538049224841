import React, { useContext, useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { ListContext } from "Contexts/ListContext";
import NwDrawer from "Components/Gui/NwDrawer";
import { NWInput, NWPaymentTermSelector, NWBankAccountsSelector, NWSwitch } from 'Components/Gui/NWForm/NwFormItems';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { useAddNewInvoice } from "Hooks/Invoices/UseInvoices";
import StyleVariables from 'Components/Gui/StyleVariables.js';
import { Alert } from "antd";

const InvoiceContent = styled.div`
    .contact-name {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 40px;
    }
    .bold-text {
        font-weight: bold;
    }
    .statements-content {
        margin-bottom: 40px;
        .contact-row {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            .select-all {
                color: ${StyleVariables.PrimaryColor};
                margin-left: 10px;
                cursor: pointer;
                user-select: none;
            }
        }
        .tx-details {
            font-size: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .ant-checkbox-wrapper {
                font-size: 12px;
            }
        }
    }
`
const CreateInvoiceDrawer = ({ onClose, data, showselection, onReload, onInvoiceCreated }) => {
    const { cachedList } = useContext(ListContext);
    const [touchForm, setTouchForm] = useState(false);
    const { mutateAsync: onAddNewInvoice } = useAddNewInvoice();
    const [errorCreating, setErrorCreating] = useState(null);

    let clientHasDefaultPaymentTerm = false;

    if (!cachedList || (!cachedList.paymentTerms || !cachedList.paymentTerms.items || cachedList.paymentTerms.items.length === 0) || (!cachedList.bankAccounts || !cachedList.bankAccounts.items || cachedList.bankAccounts.items.length === 0 )) {
        return (
            <h1>Before creating your first invoice, please make sure that in the "settings" area you have configured at least a payment term and a bank account</h1>
        )
    }

    let initialPaymentTerm = cachedList.paymentTerms.items.find(el => el.IsDefault);
    let initialBankAccount = cachedList.bankAccounts.items.find(el => el.IsDefault);
    //if no default payment term or bank account, select the first one
    if (!initialPaymentTerm) {
        //TODO: check if the billing info has a default payment term
        initialPaymentTerm = cachedList.paymentTerms.items[0];
    }
    if (!initialBankAccount) {
        initialBankAccount = cachedList.bankAccounts.items[0];
    }
    //if no default payment term or bank account return error message
    if (!initialPaymentTerm || !initialBankAccount) {
        return (
            <h1>Before creating your first invoice, please make sure that in the "settings" area you have configured at least a payment term and a bank account</h1>
        )  
    }

    if (data.BillingInfoDefaultPaymentTermCode) {
        initialPaymentTerm = cachedList.paymentTerms.items.find(el => el.Code === data.BillingInfoDefaultPaymentTermCode);
        clientHasDefaultPaymentTerm = true;
    }
    
    const initialValues = {
        InvoiceName: data.ProjectName ? `Invoice for ${data.ProjectName}` : 'New Invoice',
        PaymentTermCode: initialPaymentTerm.Code,
        BankAccountID: initialBankAccount.ID,
        //GroupCommissions: getAgFeeCount() > 1 ? true: false,
        GroupCommissions: (data.AgencyFeeCount && data.AgencyFeeCount) > 1 ? true : false
    }

    // const currencyValidation = () => {
    //     const statements = showselection?statementData.filter(el=>el.Checked === true):data.statements;
    //     if (!statements || !statements.length) return true;
    //     if (showselection) {
    //         const currency = statements[0].transaction.Currency;
    //         for (let i = 1; i < statements.length; i ++) {
    //             if (currency !== statements[i].transaction.Currency) return true;
    //         }
    //         return false;
    //     }
    
    //     const currency = statements[0].Currency;
    //     for (let i = 1; i < statements.length; i ++) {
    //         if (currency !== statements[i].Currency) return true;
    //     }
    //     return false;
    // }

    const saveInvoice = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            const invoiceId = await onAddNewInvoice({
                data: {
                    ContactID: data.ContactID,
                    BillingInfoID: data.BillingInfoID,
                    Name: values.InvoiceName,
                    PaymentTermCode: values.PaymentTermCode,
                    BankAccountID: values.BankAccountID,
                    Type: data.Type,
                    //Currency: showselection?statementData.filter(el=>el.Checked === true)[0].transaction.Currency:data.statements[0].Currency,
                    Currency: data.Currency,
                    //StatementRowIDs: showselection?statementData.filter(el=>el.Checked === true).map(el => el.ID):data.statements.map(el => el.ID),
                    StatementRowIDs: data.SelectedStatementIDs,
                    GroupCommissions: values.GroupCommissions,
                }
            });
            onInvoiceCreated(invoiceId);
            if (onReload) {
                onReload();
            }
            setSubmitting(false);
        } catch (e) {
            setErrorCreating(`Error creating invoice: ${e.message}`)
            setSubmitting(false);
        }
    }

    // const onCheckChange = (index) => {
    //     let _statementData = [...statementData];
    //     _statementData[index].Checked = !_statementData[index].Checked;
    //     setStatementData(_statementData);
    // }

    // const onSelectAll = () => {
    //     let _statementData = [...statementData];
    //     _statementData.map(el => el.Checked = true);
    //     setStatementData(_statementData);
    // }

    // const renderSelection = () => {
    //     if (!showselection) return (null);
    //     return (
    //         <div className="statements-content">
    //             <div className="contact-row">
    //                 <div className="bold-text">
    //                     {`${data.contactName}  / ${data.BillingInfof.CompanyName}`}
    //                 </div>
    //                 <div
    //                     className="select-all"
    //                     onClick={() => onSelectAll()}
    //                 >
    //                     select all lines
    //                 </div>
    //             </div>
    //             {statementData.map((statement, index) => {
    //                 return (
    //                     <div key={index} className="tx-details">
    //                         <Checkbox
    //                             checked={statement.Checked}
    //                             onChange={() => onCheckChange(index)}
    //                         >
    //                             {statement.Family.toUpperCase()} - {statement.Description}
    //                         </Checkbox>
    //                         <div>{Dinero({amount: Math.round(Math.abs(parseFloat(statement.Amount) * 100)), currency: statement.transaction.Currency}).toFormat(statementFormat)}</div>
    //                     </div>
    //                 )
    //             })}
    //         </div>
    //     )
    // }

    const writeTerm = (term) => {
        let stringTerms = term.Code;
        if (term.Name !== term.Code) {
            stringTerms = `${stringTerms} - ${term.Name}`;
        }
        return stringTerms;
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title="Create Invoice"
        >
            <InvoiceContent>
                <h3>Invoice Currency: {data.Currency}</h3>
                <p>Client default payment terms: {clientHasDefaultPaymentTerm ? writeTerm(initialPaymentTerm) : 'n.d.' }</p>
                <br/>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                        saveInvoice(values, setSubmitting);
                    }}
                    validationSchema={Yup.object().shape({
                        InvoiceName: Yup.string().required('Title is required'),
                    })}
                >
                {({ isSubmitting, values, handleSubmit, }) => (
                        <NwForm 
                            values={values}
                            onTouchForm={setTouchForm}
                            onFinish={handleSubmit} 
                        >
                            <Field
                                component={NWPaymentTermSelector}
                                label="Payment Terms"
                                name="PaymentTermCode"
                                value={values.PaymentTermCode}
                            />
                            <Field
                                component={NWBankAccountsSelector}
                                label="Bank Account"
                                name="BankAccountID"
                                value={values.BankAccountID}
                            />
                            <Field
                                component={NWInput}
                                label="Invoice Description"
                                name="InvoiceName"
                                maxLength={50}
                                type="text"
                                value={values.InvoiceName}
                            />
                            {(data.AgencyFeeCount && data.AgencyFeeCount > 1) 
                            ?
                            <Field
                                component={NWSwitch}
                                label=""
                                name="GroupCommissions"
                                text='group agency fees in a single line'
                                value={values.GroupCommissions}    
                            />
                            : null}
                            {
                                errorCreating &&
                                <Alert style={{ margin: '1rem 0' }} message={errorCreating} type="error" />
                            }
                            <NwFormButtonsBar
                                marginTop="4rem"
                                left={
                                    <NwCancelButton
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            setTouchForm(false);
                                            onClose();
                                        }}
                                    />
                                }
                                right={
                                    <NwSaveButton
                                        htmlType="submit"
                                        loading={isSubmitting}
                                        //disabled={currencyValidation()}
                                    />
                                }
                            />
                        </NwForm>
                    )}
                </Formik>
            </InvoiceContent>
        </NwDrawer>
    );
}

export default CreateInvoiceDrawer;