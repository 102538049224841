import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';

import { Editor } from '@tinymce/tinymce-react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwButton } from 'Components/Gui/NwButton';
import { Loading } from 'Components/Gui/Loading';
import SendDrawer from 'Components/SendDrawer/SendDrawer';
import DocumentLayout from 'Components/_LayoutComponents/Layout/DocumentLayout';
import { UserContext } from "Contexts/UserContext";
import NwDrawer from 'Components/Gui/NwDrawer';
import { useRef } from 'react';

const Wrapper = styled.div`
    .actions-on-documents {
        margin: 1rem auto;
        padding: 0 1rem;
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        
        .document-buttons {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
    
    .editor-wrapper {
        margin: 1rem auto;
        width: 90%;
        //max-width: 800px;
        //height: calc( 100vh - 90px);
        //box-shadow: 0 1px 6px rgba(57,73,76,.35);
        //background: white;
        
        @media only screen and (max-width: 992px) {
            margin-left: 15%;
            margin-right: 15%;
        }
        @media only screen and (max-width: 768px) {
            margin-left: 10%;
            margin-right: 10%;
        }
        @media only screen and (max-width: 600px) {
            margin-left: 0;
            margin-right: 0;
        }

        /* .tox-edit-area {
            padding: 2rem;

            iframe {
                width: calc(100 - 4rem);
            }
        } */
    }
`;

const DocVersionDrawer = ({ docVersion }) => {
    const [contentData, setContentData] = useState();
    const [header, setHeader] = useState();
    const [footer, setFooter] = useState();
    const [head, setHead] = useState();
    const [styleDefinitions, setStyleDefinitions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [isEmailDrawer, setEmailDrawer] = useState(false);
    
    const document = {...docVersion};
    
    const getContents = () => {
        const domDoc = new DOMParser().parseFromString(docVersion.Html, "text/html")
        const stylematches = [...domDoc.querySelectorAll('style')].map(style => style.textContent)
        const headmatch = domDoc.querySelector('head')
        const bodymatch = domDoc.querySelector('#body')
        const headermatch = domDoc.querySelector('header')
        const footermatch = domDoc.querySelector('footer')
        
        setHead(headmatch.outerHTML);
        if (headermatch) {
            setHeader(headermatch.outerHTML);
        }
        if (footermatch) {
            setFooter(footermatch.outerHTML);
        }
        if (bodymatch) {
            setContentData(bodymatch.innerHTML);
        }
        const stylestring  = stylematches.join('\n') //.filter(sm => !sm.indexOf("@import") >= 0).join('\n')
        setStyleDefinitions(stylestring);
    }

    useEffect(() => {
        if (docVersion) {
            getContents(docVersion)
        }
    }, [docVersion]);

    const handleSaveDocument = async () => {
        if (editorRef.current) {
            const content = editorRef.current.getContent();
            let savedDocument = "<!DOCTYPE html><html lang=\"en\">" + head
            savedDocument += "<body class=\"pdf\">" + header + '<section id="body">' + content + '</section>' + footer + "</body>"
            savedDocument += "</html>";
            setLoading(true);
            const saveDocumentData = {
                ID: document.ID,
                Html: savedDocument
            };
            try {
                await Axios.put('/docVersions/UpdateHtml', saveDocumentData);
                setLoading(false);
            } catch (error) {
                console.log('ant : Save document error => ', error);
                setLoading(false);
            }
        }
    };

    const handlePrintDocument = async () => {
        if (editorRef.current) {
            await handleSaveDocument()
            setPdfLoading(true);
            try {
                const url = await Axios.get(`/docVersions/pdf/${document.DocGuid}`);
                //const documentUrl = new URL(url, Constants.tenanturl);
                const documentUrl = new URL(url);
                window.open(documentUrl.href, '_blank');
                setPdfLoading(false);
            } catch (error) {
                console.log('ant : Load pdf error => ', error);
                setPdfLoading(false);
            }
        }
    };

    const handleOpenEmailsDrawer = () => {
        setEmailDrawer(true);
    };

    const handleCloseEmailsDrawer = () => {
        setEmailDrawer(false);
    };

    const handleSendDocument = async (subject, message, recipients) => {
        console.log("recipients",recipients)
        const recipientsToSend = recipients.map(email => {
            if (email.ContactID) {
                return {
                    ContactID: email.ContactID,
                    ConnectionID: email.ConnectionID,
                    ContactDetails: [email.Email]
                }
            } else {
                return {
                    ContactDetails: [email.Email]
                }
            }
        });
        const data = [
            {
                Recipients: recipientsToSend,
                DocVersionGUID: document.DocGuid,
                SendMethod: "SendGrid",
                Subject: subject,
                AsAttachment: true,
                Message: message
            }
        ];
        try {
            await Axios.post('/docVersions/sendmessage', data);
            //setTouchForm(false);
            //setSubmitting(false);
        } catch (error) {
            console.log('ant : Send document to emails error => ', error);
            //setSubmitting(false);
        }
    }

    const editorRef = useRef(null);
    
    return contentData ? (
            <Wrapper>
                <div className="actions-on-documents">
                    <div>&nbsp;</div> 
                    <div className="document-buttons">
                        <NwButton label='Send' icon={light('paper-plane')} primary onClick={handleOpenEmailsDrawer} loading={loading} />
                        &nbsp;&nbsp;
                        <NwButton label='Generate PDF' primary icon={light('file-pdf')} onClick={handlePrintDocument} loading={pdfLoading} />
                    </div>
                </div>
                <div className="editor-wrapper">
                    <Editor
                        apiKey='twj4dzqrnrkzil9tsdzupufw4jtt5t34ujzta4wvwsh4tacc'
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={contentData}
                        plugins='table lists link charmap'
                        init={{
                            height: 'calc(100vh - 140px)',
                            menubar: 'edit insert tools table tc help',
                            toolbar: 'undo redo | bold italic underline strikethrough | fontsize blocks | alignleft aligncenter alignright alignjustify |  table | outdent indent | numlist bullist checklist | forecolor backcolor | pagebreak | charmap emoticons | link',
                            plugins: ['lists','link','charmap','table'],
                            content_style: styleDefinitions
                        }}
                    />
                </div>
                {isEmailDrawer && document &&
                    <NwDrawer padding="0px" title='Send Document' onClose={handleCloseEmailsDrawer}>
                        <SendDrawer
                            searchBox
                            suggestions={[]} //aggiungere e-mail cliente progetto/evento
                            existingEmails={[]}
                            recipientsBox
                            textsBox
                            hasSubject
                            hasMessage
                            subjectDefault="document"
                            sendBox
                            onSend={handleSendDocument}
                            afterSend={handleCloseEmailsDrawer}
                            onCancel={handleCloseEmailsDrawer}
                        />
                    </NwDrawer>
                }
            </Wrapper>
    ) : 
    <DocumentLayout>
        <Loading loadingType="box" />
    </DocumentLayout>;
};

export default withRouter(DocVersionDrawer);