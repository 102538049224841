import React from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";

import {
    NWInput,
    NWPrivacyLevelSelector,
} from 'Components/Gui/NWForm/NwFormItems';
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton } from "Components/Gui/NwButton";
import { useAddPlaylist, useEditPlaylist, useEditPlaylistDocuments } from "Hooks/Contact/UseContactPlaylists";
import useFormFieldFocus from 'Hooks/UseFormFieldFocus';

const AddBookForm = ({
    afterCreate,
    contactID,
    isAdd,
    mediaList,
    onClose,
    onSelectBook,
    onTouchForm,
    selectedBook,
}) => {
    useFormFieldFocus('Title');

    const { mutateAsync: addPlaylist } = useAddPlaylist();
    const { mutateAsync: editPlaylist } = useEditPlaylist();
    const { mutateAsync: editPlaylistDocuments } = useEditPlaylistDocuments();

    let initialValues = {
        Title: "",
        PrivacyLevel: 'Public'
    };

    const handleSaveBook = async (values, setSubmitting) => {
        setSubmitting(true);
        let DocumentPlaylists = [];

        if (isAdd) {
            const selectedList = mediaList.filter(media => media.isSelected);
            DocumentPlaylists = selectedList.map((media, index) => {
                return {
                    DocumentID: media.ID,
                    Note: media.Notes,
                    DocumentOrder: index
                };
            });
        }
        const newBook = {
            Name: values.Title,
            ContactID: contactID,
            PrivacyLevel: values.PrivacyLevel,
            Type: 'book',
            DocumentPlaylists: DocumentPlaylists
        };

        if (!selectedBook) {
            try {
                let response = await addPlaylist({ playlist: newBook, contactID: contactID });
                if (isAdd) {
                    const selectedList = mediaList.filter(media => media.isSelected);
                    let DocumentPlaylists = selectedList.map((media, index) => {
                        return {
                            PlaylistID: response.ID,
                            DocumentID: media.ID,
                            Note: media.Notes,
                            DocumentOrder: index
                        };
                    });
                    let updatedData = {
                        ID: response.ID,
                        DocumentPlaylists: DocumentPlaylists
                    }

                    await editPlaylistDocuments({ playlist: updatedData, contactID: contactID });
                    response = {
                        ...response,
                        DocumentPlaylists: selectedList.map(media => ({
                            ...media,
                            DocumentPlaylistNotes: media.Notes
                        }))
                    };
                }

                onTouchForm(false)
                onClose()
                afterCreate()
                onSelectBook(response)
            } catch (error) {
                console.log('ant : Create new book error => ', error);
                setSubmitting(false)
            }
        } else {
            const book = {
                ID: selectedBook.ID,
                Name: values.Title,
                ContactID: contactID,
                PrivacyLevel: values.PrivacyLevel,
                Type: 'book',
            };

            try {
                const response = await editPlaylist({ playlist: book, contactID: contactID });
                onTouchForm(false)
                onClose()
                onSelectBook(response)
            } catch (error) {
                console.log('ant : Edit new book error => ', error);
                setSubmitting(false);
            }
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
                Title: Yup.string().required("Content is required")
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                handleSaveBook(values, setSubmitting);
            }}
            render={({
                status,
                isSubmitting,
                values,
                handleSubmit
            }) => (
                <NwForm
                    values={values}
                    onTouchForm={onTouchForm}
                    onFinish={handleSubmit}
                    layout="vertical">
                    <Field
                        component={NWInput}
                        label="Title"
                        name="Title"
                        type="text"
                        value={values.Title}
                        id='Title'
                    />
                    <Field
                        component={NWPrivacyLevelSelector}
                        label="Privacy Level"
                        name="PrivacyLevel"
                        value={values.PrivacyLevel}
                    />
                    <br /><br />
                    <div className="form-group">
                        <NwFormButtonsBar
                            left={
                                <NwCancelButton
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        onTouchForm(false);
                                        onClose();
                                    }}
                                />
                            }
                            right={
                                <NwSaveButton
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                />
                            }
                        />
                    </div>
                    {status && (
                        <div className={"alert alert-danger"}>
                            <React.Fragment>{status.msg}</React.Fragment>
                        </div>
                    )}
                </NwForm>
            )}
        />
    )
};

export default AddBookForm;