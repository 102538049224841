import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import ContactDetailsHeaderAnagraphical from "Components/ContactDetails/ContactDetailsHeader/ContactDetailsHeaderAnagraphical";
import ModelDetailsHeaderLegal from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderLegal";
import ModelDetailsHeaderAgencies from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderAgencies";
import { Loading } from "Components/Gui/Loading";
import NwIcon from "Components/Gui/NwIcon";
import ContactUsers from "Components/ContactUsers/ContactUsers";
import ModelInTown from "Components/ModelInTown/ModelInTown";
import FlexContainer from "Components/Gui/FlexContainer";
import ContactAlert from 'Components/ContactAlert/ContactAlert';
import ModelDetailsCover from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsCover';
import usePlaceholderImageFixed from "Hooks/UsePlaceholderImageFixed";
import { useContact } from "Hooks/Contact/UseContact";
import ModelDetailsHeaderFeatures from 'Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsHeader/ModelDetailsHeaderFeatures'
import ContactSocials from 'Components/Social/ContactSocials';
import ContactHeaderBillingInfos from "Components/ContactDetails/ContactDetailsHeader/ContactHeaderBillingInfos";
import ContactDetailsBankAccounts from "Components/ContactDetails/ContactDetailsHeader/ContactDetailsBankAccounts";

const Wrapper = styled.div`
    margin-top: 1rem;

    .model-cover {
        position: relative;
        width: 8rem;
        height: 8rem;
        margin: 0.5rem 0 0 0.5rem;
    }
    .model-name {
        font-size: 1.6rem;
        margin-bottom: 4px;
    }

    .header {
        font-size: .85rem;
        margin-top: 0.5rem;
        margin-left: 16px;

        .header-body-boxes {
            p {
                margin-bottom: 0;
            }
        }

        .back-models {
            font-size: .8rem;
            cursor: pointer;
        }
    }

    .model-details {
        margin-top: 16px;
        padding: 16px;
        .detail-icon {
            margin-right: 16px;
            font-size: 1.3rem;
            font-weight: bold;
            width: 20px;
        }
    }
`;

const ModelDetailsMobileHeader = ({
    selectedModel,
    isDrawer
}) => {
    const { data: currentContact } = useContact(selectedModel);
    const [IsLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const [isModelAgencies, setModelAgencies] = useState(false);

    const handleBackToModels = () => {
        history.push('/models');
    }

    useEffect(() => {
        if (currentContact) {
            setIsLoading(false);
        }
    }, [currentContact]);

    const [urlbg, isPlaceholder] = usePlaceholderImageFixed(
        currentContact.NetworkAgencies[0].CoverGuid,
        currentContact.SubType
    );

    const handleOpenModelAgencies = () => {
        setModelAgencies(true);
    };

    const handleCloseModelAgencies = () => {
        setModelAgencies(false);
    };

    return currentContact ? (
        IsLoading ? (
            <Loading />
        ) : (
            <Wrapper>
                <FlexContainer alignItems='flex-start'>
                    <div className='model-cover'>
                        <ContactAlert size='small' contactId={currentContact.ID}>
                            <ModelDetailsCover collapseView urlBg={urlbg} isPlaceholder={isPlaceholder} currentContact={currentContact} />
                        </ContactAlert>
                    </div>
                    <div className='header'>
                        {!isDrawer &&
                            <>
                                <FlexContainer className='back-models' alignItems='center' onClick={handleBackToModels}>
                                    <NwIcon icon={light('arrow-left')}></NwIcon>&nbsp;back to models list
                                </FlexContainer>
                                <div className='model-name'>{`${currentContact.Name} ${currentContact.Surname}`}</div>
                            </>
                        }
                        <ModelDetailsHeaderAgencies
                            model={currentContact}
                            onOpenModelAgencies={handleOpenModelAgencies}
                        />
                        <ModelInTown
                            modelID={currentContact.ID}
                            modelInTownSchedule={
                                currentContact.InTownSchedule
                            }
                        />
                        <ContactUsers
                            users={currentContact.Users}
                            contactID={currentContact.ID}
                        />
                    </div>
                </FlexContainer>
                <div className='model-details'>
                    <FlexContainer mg='0 0 8px'>
                        <NwIcon className='detail-icon' icon={solid('id-card')} />
                        <ContactDetailsHeaderAnagraphical
                            contact={currentContact}
                        />
                    </FlexContainer>
                    <FlexContainer mg='0 0 8px'>
                        <NwIcon className='detail-icon' icon={solid('passport')} />
                        <ModelDetailsHeaderLegal currentContact={currentContact} />
                    </FlexContainer>
                    <FlexContainer mg='0 0 8px'>
                        <NwIcon className='detail-icon' icon={solid('file-invoice-dollar')} />
                        <ContactHeaderBillingInfos type="model" currentContact={currentContact} />
                    </FlexContainer>
                    <FlexContainer mg='0 0 8px'>
                        <NwIcon className='detail-icon' icon={solid('piggy-bank')} />
                        <ContactDetailsBankAccounts contactId={currentContact.ID} />
                    </FlexContainer>

                    <FlexContainer mg='0 0 8px'>
                        {currentContact.DefaultConnection && currentContact.DefaultConnection.Phone &&
                            <>
                                <NwIcon className='detail-icon' icon={solid('phone')} />
                                <div>
                                    {currentContact.DefaultConnection.Phone}
                                </div>
                            </>
                        }
                    </FlexContainer>
                    <FlexContainer mg='0 0 8px'>
                        <NwIcon className='detail-icon' icon={solid('mobile-alt')} />
                        <div>
                            {(currentContact.DefaultConnection && currentContact.DefaultConnection.Mobile) ? currentContact.DefaultConnection.Mobile : 'no mobile'}
                        </div>
                    </FlexContainer>
                    <FlexContainer mg='0 0 8px'>
                        <NwIcon className='detail-icon' icon={solid('envelope')} />
                        <div>
                            {(currentContact.DefaultConnection && currentContact.DefaultConnection.Email) ? currentContact.DefaultConnection.Email : 'no email'}
                        </div>
                    </FlexContainer>
                    <FlexContainer mg='0 0 8px' alignItems='flex-start'>
                        <NwIcon className='detail-icon' icon={solid('weight')} />
                        <ModelDetailsHeaderFeatures contact={currentContact} />
                    </FlexContainer>
                    <FlexContainer mg='32px 0 0'>
                        <div className='detail-icon'>#</div>
                        {currentContact.Socials.length > 0 ?
                            <ContactSocials currentContact={currentContact} /> : 'no socials'
                        }
                    </FlexContainer>
                </div>
                {/* {isModelAgencies && 
                    <NwDrawer
                        title='Model Agencies'
                        onClose={handleCloseModelAgencies}>
                        <ModelAgenciesCollapseCard
                            drawerMode
                            contactID={currentContact.ID}/>
                    </NwDrawer>
                } */}
            </Wrapper>
        )
    ) : (
        <Loading />
    );
};

export default withRouter(ModelDetailsMobileHeader);
