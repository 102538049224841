import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

import { Loading } from 'Components/Gui/Loading';
import ModelMediaPanel from 'Components/ModelMedia/ModelMediaPanel/ModelMediaPanel';
import { onContactMediaUpdate, useContactPicturesAndVideos } from "Hooks/Contact/UseContactMedia";
import useDataPlaceholder from "Hooks/UseDataPlaceholder";
import { useContactPlaylists } from 'Hooks/Contact/UseContactPlaylists';
import { usePackagePlaylist } from "Hooks/Packages/UsePackagePlaylist";

const { Content } = Layout;

const LayoutContainer = styled.div`
    background: ${props => props.loading && 'black'};
`;

const MainLayout = styled(Layout)`
    opacity: ${props => props.$loading ? .4 : 1 };
`;

const StyledContent = styled(Content)`
    padding: 1rem 1.5rem 2rem .5rem;
`;


const LoadingContainer = styled.div`
    position: absolute;
    position: absolute;
    right: 0;
    left: 0;
    top: 40%;
    z-index: 2000;
`;


const ModelMedia = ({ selectedModel, packageBook, bookToEdit, onCloseBookEditor, onBookTouched, isDrawer }) => {
    const replacePlaceHolder = useDataPlaceholder();
    const { data: documents } = useContactPicturesAndVideos(selectedModel);
    const [showMedia, setShowMedia] = useState({
        isShowPictures: true,
        isShowVideos: true
    });
    const [tags, setTags] = useState([]);
    const [uploader, setUploader] = useState();
    const [uploadedSince, setUploadedSince] = useState();
    const [uploadedUntil, setUploadedUntil] = useState();
    const [loading, setLoading] = useState(false);
    const { data: contactPlaylists } = useContactPlaylists(!packageBook?selectedModel:null);
    const { data: packagePlaylist } = usePackagePlaylist(packageBook?packageBook.ID:null)
    const [playlists, setPlaylists] = useState();
    const [selectedPackageBook, setSelectedPackageBook] = useState();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    useEffect(() => {
        if (contactPlaylists) setPlaylists(contactPlaylists); 
    }, [contactPlaylists]);

    useEffect(() => {
        if (packagePlaylist)
            setSelectedPackageBook({
                ...packagePlaylist,
                PackageID: packageBook.PackageID
            });
    }, [packagePlaylist]);

    const handleUpdateShowMedia = updatedShowMedia => {
        setShowMedia(updatedShowMedia);
    };

    const handleUpdateTags = updatedTags => {
        setTags(updatedTags);
    };

    const handleChangeUploader = value => {
        setUploader(value);
    };

    const handleUploadedSinceChange = value => {
        setUploadedSince(value);
    };

    const handleUploadedUntilChange = value => {
        setUploadedUntil(value);
    };

    const handleClearFilters = () => {
        setShowMedia({
            isShowPictures: true,
            isShowVideos: true
        });
        setTags([]);
        setUploader();
        setUploadedSince();
        setUploadedUntil();
    };

    const displayedDocuments = useMemo(() => {
        let documentList = [];
        if (documents && documents.length) {
            documentList = documents.filter(document => {
                if ((document.Type === 'Photo' && showMedia.isShowPictures) || (document.Type === 'Video' && showMedia.isShowVideos)) {
                    return true;
                };
                return false;
            });
    
            if (uploader) {
                documentList = documentList.filter(document => document.UserCreated === uploader);
            }
    
            documentList = documentList.filter(document => {
                for (const tag of tags) {
                    if (!document.Tags.find(item => item.ID === tag)) {
                        return false;
                    }
                }
                return true;
            });
    
            const uploadedSinceDate = replacePlaceHolder(uploadedSince);
            const uploadedUntilDate = replacePlaceHolder(uploadedUntil);
            documentList = documentList.filter(document => {
                if (uploadedSince) {
                    if (new Date(uploadedSinceDate) > new Date(document.DateCreated))
                        return false;
                }
                if (uploadedUntil) {
                    if (new Date(uploadedUntilDate) < new Date(document.DateCreated))
                        return false;
                }
                return true;
            }) 
        }
        return documentList;
    }, [uploader, 
        uploadedSince, 
        uploadedUntil, 
        showMedia, 
        documents, 
        replacePlaceHolder, 
        tags]);

    return documents ? (
            <>
                {loading &&
                    <LoadingContainer>
                        <Loading />
                    </LoadingContainer>
                }
                <LayoutContainer loading={loading ? 1 : 0}>
                    <MainLayout $loading={loading ? 1 : 0}>
                        <StyledContent>
                            <ModelMediaPanel
                                isDrawer={isDrawer}
                                displayedDocuments={displayedDocuments} 
                                contactID={selectedModel} 
                                contactPlaylists={playlists}
                                onMediaUpdate={onContactMediaUpdate}
                                onSetLoading={setLoading}
                                onClearFilters={handleClearFilters}
                                showMedia={showMedia}
                                onUpdateShowMedia={handleUpdateShowMedia}
                                tags={tags} 
                                onUpdateTags={handleUpdateTags}
                                uploader={uploader} 
                                onChangeUploader={handleChangeUploader}
                                uploadedSince={uploadedSince} 
                                onChangeUploadedSince={handleUploadedSinceChange}
                                uploadedUntil={uploadedUntil} 
                                onChangeUploadedUntil={handleUploadedUntilChange}
                                packageBook={selectedPackageBook}
                                onCloseBookEditor={onCloseBookEditor}
                                bookToEdit={bookToEdit}
                                onBookTouched={onBookTouched}
                            />
                        </StyledContent>
                    </MainLayout>
                </LayoutContainer>
            </>
    ) : <Loading />;
};

export default ModelMedia;