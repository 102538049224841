import React, { useContext, useState } from "react";
import { withRouter } from "react-router"
import { Avatar, Tooltip } from 'antd';
import styled from 'styled-components';

import { UserContext } from "Contexts/UserContext";
import { ListContext } from 'Contexts/ListContext';
import UserIcon from "Components/UserIcon/UserIcon";
import { StyledMenu } from "Components/_LayoutComponents/Header/NwHeaderUI";
import FlexContainer from 'Components/Gui/FlexContainer';
import UserContextModal from 'Components/UserContextModal/UserContextModal';
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";
import NwIcon from "Components/Gui/NwIcon";
import { HeaderContext } from "./NwHeader";

const Container = styled(FlexContainer)`
    .ant-avatar {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .ant-menu-submenu-title {
        padding: 0 4px !important;
    }

    ul {
        min-width: 48px;

        .ant-menu-overflow-item-rest {
            display: none;
        }
    }
`;

const MobileContainer = styled(FlexContainer)`
    .ant-menu-horizontal {
        border-bottom: none;
        line-height: 56px;

        .ant-menu-submenu {
            bottom: 0;
            border-bottom: none;
            padding: 0 0 0 10px !important;
            line-height: 56px !important;

            .ant-menu-submenu-title svg {
                margin-bottom: -2px;
            }
        }
        .ant-menu-submenu:hover {
            border-bottom: none;
        }
    }

    ul {
        min-width: 0;
        line-height: 56px;

        .ant-menu-overflow-item-rest {
            display: none;
        }
    }
`;

const NetworkAgencyContainer = styled(FlexContainer)`
    .title {
        line-height: 1;
        margin-bottom: 2px;
    }

    .ant-tag {
        margin-right: 0;
    }
`;

const StyledAvatarNwAg = styled(Avatar)`
    background-color: ${props => props.theme.PrimaryDark};
    font-size: 10px;
    border-radius: 25%;
    .ant-avatar-string {
        text-transform: uppercase;
    }
`;

const StyledAvatarDept = styled(Avatar)`
    background-color: ${props => props.theme.PrimaryColorHalf};
    font-size: 10px;
    .ant-avatar-string {
        text-transform: uppercase;
    }
`;

const StyledAvatarDefaultDept = styled(Avatar)`
    background-color: ${props => props.theme.PrimaryColor};
    font-size: 10px;
    .ant-avatar-string {
        text-transform: uppercase;
    }
`;

const AgencyMenuItem = styled.div`
    height: 24px !important;
    margin: 0;
    display: flex;
    align-items: center;

    .ant-avatar-group .ant-avatar {
        line-height: 24px;
        width: 24px;
        height: 24px;
        font-size: 1rem;
    }
`;

const UserDropDown = ({ history }) => {
    const { currentUser, Logout } = useContext(UserContext);
    const { cachedList } = useContext(ListContext);
    const [isShowContextModal, setIsShowContextModal] = useState(false);
    const { activeDepartments, accGroupId, nwAgId, depId } = currentUser.ActiveContext;
    const currentAccountingGroup = cachedList.accountingGroups.items.find(group => group.ID === accGroupId);
    const networkAgency = currentAccountingGroup && currentAccountingGroup.NetworkAgencies.find(agency => agency.ID === nwAgId);
    
    const headerContextValues = useContext(HeaderContext);
    const { area, device } = headerContextValues;


    const onClick = ({ key }) => {
        switch (key) {
            case "email-report":
                history.push(`/user-mail-report`)
                break;
            case "profile":
                history.push(`/user/settings`)
                break;
            case "logout":
                Logout()
                break;
            default:
                break;
        }
    };

    const handleOpenUserContextModal = () => {
        setIsShowContextModal(true);
    };

    const handleCloseContestModal = () => {
        setIsShowContextModal(false);
    };

    const renderNetworkAgencies = () => {
        return (
            <NetworkAgencyContainer alignItems='center' onClick={handleOpenUserContextModal}>
                {(device < 5) && <>Working on &nbsp;</>}
                <Avatar.Group>
                    <Tooltip title={networkAgency.Name}>
                        <StyledAvatarNwAg>{networkAgency.Name.slice(0, 4)}</StyledAvatarNwAg>
                    </Tooltip>
                    {activeDepartments.length > 4 ?
                        <Tooltip title={`${activeDepartments.length} active depts`}>
                            <StyledAvatarDept>
                                DEPTS
                            </StyledAvatarDept>
                        </Tooltip>
                        : activeDepartments.map(departmentId => {
                            const department = networkAgency.Departments.find(item => item.ID === departmentId);
                            if (departmentId === depId) {
                                return (
                                    <Tooltip title={`${department.Name} - default`} key={`department-${department.ID}`}>
                                        <StyledAvatarDefaultDept>
                                            {department.Name.slice(0, 4)}
                                        </StyledAvatarDefaultDept>
                                    </Tooltip>
                                )
                            } else {
                                return (
                                    <Tooltip title={department.Name} key={`department-${department.ID}`}>
                                        <StyledAvatarDept>
                                            {department.Name.slice(0, 4)}
                                        </StyledAvatarDept>
                                    </Tooltip>
                                )
                            }
                        })}
                </Avatar.Group>
            </NetworkAgencyContainer>
        )
    }

    const renderUserDropdown = () => {
        const items = [
            {
                key: "email-report",
                label: (
                    <FlexContainer alignItems='center'>
                        <NwIcon icon={light("paper-plane")} />E-mail Report
                    </FlexContainer>
                )
            },
            {
                key: "profile",
                label: (
                    <FlexContainer alignItems='center'>
                        <NwIcon icon={light("user-gear")} />Profile Page
                    </FlexContainer>
                )
            },
            {
                key: "logout",
                label: (
                    <FlexContainer alignItems='center'>
                        <NwIcon icon={light("arrow-right-from-bracket")} />Log out
                    </FlexContainer>
                )
            }
        ];
        if ((device < 5) && networkAgency)
            items.push({
                key: "agency",
                label: (
                    <AgencyMenuItem>
                        {renderNetworkAgencies()}
                    </AgencyMenuItem>
                )
            });
        return (
            <>
                <StyledMenu
                    onClick={onClick}
                    mode="horizontal"
                    selectable={false}
                    className={`menu-${area}`}
                    triggerSubMenuAction="click"
                    items={[{
                        key: "single-folder",
                        label: currentUser ? <UserIcon shape="square" userObject={currentUser} /> : "",
                        style: { lineHeight: "60px" },
                        popupClassName: "main-nav-submenu",
                        children: items
                    }]}
                >
                </StyledMenu>
                {networkAgency && (device === 5) && renderNetworkAgencies()}
                {isShowContextModal &&
                    <UserContextModal onCancel={handleCloseContestModal} />
                }
            </>
        )
    }

    return (
        <>
            {(device < 5) ?
                <MobileContainer>
                    {renderUserDropdown()}
                </MobileContainer>
                : <Container>
                    {renderUserDropdown()}
                </Container>
            }
        </>
    );
}
export default withRouter(UserDropDown);
