import React, { useState, useContext } from 'react';
import { Formik, Field } from "formik";
import { getMoment } from "Libs/NwMoment";
import * as Yup from "yup";
import { Row, Col, notification } from 'antd';
import Axios from 'axios';
import { NwForm } from 'Components/Gui/NWForm/NwFormWrapper';
import FlexContainer from 'Components/Gui/FlexContainer';
import {
    NWInput,
    NWSelect,
    NWTextAreaCharCounter,
    NWContactWithBillingInfo,
    NWAmountInput,
    NWSwitch
} from "Components/Gui/NWForm/NwFormItems";
import { forceReloadProject } from 'Hooks/Project/UseProject';
import { QUANTITIES } from 'Components/Gui/NWForm/QuantitySelector';
import Switch from 'Components/Gui/CustomSwitch';
import AddEditUsageDrawer from 'Components/Project/ProjectTransactions/Usages/AddEditUsageDrawer';
import { isPayrollBetaActive, validateAmount } from 'Libs/NwUtils';
import useConfigurations from 'Hooks/UseConfigurations';
import { AddNewLink, ProjectTransactionFormContainer, UsageContainer } from 'Components/Project/ProjectTransactions/Transactions/ProjectTransactionsUI';
import { Loading } from 'Components/Gui/Loading';
import ModelSelectorWorkDates from './Common/ModelSelectorWorkDates';
import { forceReloadProjectAccountingDetails } from 'Hooks/Project/UseProjectAccountingDetails';
import { forceReloadProjectTransactions } from 'Hooks/Project/UseProjectTransactions';
import { forceReloadProjectStatements } from 'Hooks/Project/UseProjectStatements';
import TransactionLabelsSelector from './Common/TransactionLabelsSelector';
import useTransactionLabels from 'Hooks/UseTransactionLabels';
import { ListContext } from 'Contexts/ListContext';
import FixedSaveAndCancelBar from './Common/FixedSaveAndCancelBar';
import { getContact } from 'Hooks/Contact/UseContactsList';
import CustomSwitch from 'Components/Gui/CustomSwitch';

const JobFeeForm = ({ currentProject, onCloseDrawer, clientBillingInfos, contacts }) => {  //onChangeForm
    const { cachedList } = useContext(ListContext);

    const payrollActive = isPayrollBetaActive()

    const transactionProperties = useTransactionLabels("JobFee");
    const getTransactionLabel = transactionLabelID => transactionProperties.labels.find(label => label.ID === transactionLabelID);
    const defaultTypeQuantity = transactionProperties.startLabel.TypeQuantityName ? transactionProperties.startLabel.TypeQuantityName : "Units"

    const [showUsage, setShowUsage] = useState(false);
    const [usePayroll, setUsePayroll] = useState(false);
    const [showNewUsage, setShowNewUsage] = useState(false);
    const configurations = useConfigurations();

    const [agencyFeeToApply, setAgencyFeeToApply] = useState(configurations.defaultAgencyFeePerc);


    const getGroupedModelDateList = () => {
        const tempGroupedModelDates = [];
        for (const event of currentProject.Events) {
            for (const eventDate of event.EventDates) {
                for (const eventDateModel of eventDate.EventDatesModels) {
                    const modelIndex = tempGroupedModelDates.findIndex(modelDate => modelDate.ModelID === eventDateModel.ModelID);
                    const eventDateDetails = {
                        EventID: event.ID,
                        EventDateID: eventDate.ID,
                        EventType: event.TypeName,
                        SubTypeCode: event.SubTypeCode,
                        //Confirmed: eventDate.Confirmed,
                        OptionNumber: eventDateModel.OptionNumber,
                        Cancel: eventDateModel.Cancel,
                        //WorkDate: eventDateModel.FromDate,
                        //RefDate: eventDateModel.FromDate,
                        EventName: event.Name,
                        Active: false,
                        Quantity: 1,
                        FromDate: eventDateModel.FromDate,
                        ToDate: eventDateModel.ToDate,
                        StartBreak: eventDateModel.StartBreak,
                        EndBreak: eventDateModel.EndBreak,
                    }

                    if (modelIndex > -1) {
                        tempGroupedModelDates[modelIndex].EventDates.push({
                            ...eventDateDetails
                        })
                    } else {
                        tempGroupedModelDates.push({
                            ModelID: eventDateModel.ModelID,
                            ActiveJobEvents: false,
                            ActiveJobEventsQuantity: 0,
                            EventDates: [{
                                ...eventDateDetails
                            }]
                        })
                    }
                }
            }
        }
        for (const model of tempGroupedModelDates) {
            const activeJobEvents = model.EventDates.filter(eventDate => eventDate.EventType === 'Job' && eventDate.OptionNumber === 10 && !eventDate.Cancel);
            model.ActiveJobEventsQuantity = activeJobEvents.length;
            model.AlwaysCancelled = model.EventDates.every(eventDate => eventDate.Cancel);
            model.HasConfirmation = model.EventDates.some(eventDate => eventDate.OptionNumber === 10 && !eventDate.Cancel);
        }

        return tempGroupedModelDates
    }
    const [groupedModelDates, setGroupedModelDates] = useState(getGroupedModelDateList());

    const handleFormatGroupedModelDates = () => {
        let newGroupedModelDates = JSON.parse(JSON.stringify(groupedModelDates));
        for (let modelIndex = 0; modelIndex < newGroupedModelDates.length; modelIndex++) {
            newGroupedModelDates[modelIndex].ActiveJobEvents = false;
            const confirmedJobs = newGroupedModelDates[modelIndex].EventDates.filter(eventDate => eventDate.EventType === 'Job' && eventDate.OptionNumber === 10 && !eventDate.Cancel);
            newGroupedModelDates[modelIndex].ActiveJobEventsQuantity = confirmedJobs.length;
            for (let i = 0; i < newGroupedModelDates[modelIndex].EventDates.length; i++) {
                const { ToDate, FromDate, StartBreak, EndBreak } = newGroupedModelDates[modelIndex].EventDates[i];
                let momentdiff = 8
                if (StartBreak && EndBreak) {
                    momentdiff = getMoment(ToDate).startOf("minute").diff(getMoment(EndBreak).startOf("minute"), 'hours', true);
                    momentdiff += getMoment(StartBreak).startOf("minute").diff(getMoment(FromDate).startOf("minute"), 'hours', true);
                } else {
                    momentdiff = getMoment(ToDate).startOf("minute").diff(getMoment(FromDate).startOf("minute"), 'hours', true);
                }
                //const qty = (momentdiff > 8) ? 8 : momentdiff;
                const qty = momentdiff;
                newGroupedModelDates[modelIndex].EventDates[i].Active = false;
                newGroupedModelDates[modelIndex].EventDates[i].Quantity = qty;
            }
        }
        setGroupedModelDates(newGroupedModelDates);
    };

    const saveTransaction = async (values, setSubmitting) => {
        
        let apiData = {
            ProjectID: currentProject.ID,
            ClientID: values.ContactToBill.ContactID,
            ClientBillingInfoID: values.ContactToBill.ContactBillingInfoID,
            Family: 'JobFee',
            TransactionLabelID: values.TransactionLabelID,
            Title: values.Title,
            Description: values.Description,
            TypeQuantity: values.TypeQuantity === 'Flat' ? 'Units' : values.TypeQuantity,
            Currency: values.Amount[0],
            SingleFee: values.Amount[1],
            UsageID: values.UsageID,
            IncludingAgencyFee: ['Flat', 'Days'].includes(values.TypeQuantity) ? values.IncludingAgencyFee : false,
            ClientAdvance: usePayroll ? values.ClientAdvance[1] : 0
        }
        let models = [];
        for (const groupedModel of groupedModelDates) {
            if (groupedModel.ActiveJobEvents) {
                //TODO: rivedere la prima data confermata, deve valere anche se non ci sono date confermate
                const confirmedJobs = groupedModel.EventDates.filter(eventDate => eventDate.EventType === 'Job' && eventDate.OptionNumber === 10 && !eventDate.Cancel);
                const allJobs = groupedModel.EventDates.filter(eventDate => eventDate.EventType === 'Job');
                const refAndWorkDate = (confirmedJobs && confirmedJobs.length > 0) ? confirmedJobs[0].FromDate : allJobs[0].FromDate

                if (values.TypeQuantity === 'Days') {
                    if (groupedModel.ActiveJobEventsQuantity > 0) {
                        models.push({
                            ModelID: groupedModel.ModelID,
                            ModelBillingInfoID: null,
                            EventID: null,
                            WorkDate: refAndWorkDate,
                            RefDate: refAndWorkDate,
                            Quantity: groupedModel.ActiveJobEventsQuantity
                        })
                    }
                } else if (values.TypeQuantity === 'Flat') {
                    models.push({
                        ModelID: groupedModel.ModelID,
                        ModelBillingInfoID: null,
                        EventID: null,
                        WorkDate: refAndWorkDate,
                        RefDate: refAndWorkDate,
                        Quantity: 1
                    })
                } else if (values.TypeQuantity === 'Units') {
                    models.push({
                        ModelID: groupedModel.ModelID,
                        ModelBillingInfoID: null,
                        EventID: null,
                        WorkDate: refAndWorkDate,
                        RefDate: refAndWorkDate,
                        Quantity: groupedModel.Quantity
                    })
                }
            } else {
                const activeEventDates = groupedModel.EventDates.filter(eventDate => eventDate.Active);
                if (values.TypeQuantity === 'Hours') {
                    for (const eventDate of activeEventDates) {
                        models.push({
                            ModelID: groupedModel.ModelID,
                            ModelBillingInfoID: null,
                            EventID: eventDate.EventID,
                            WorkDate: eventDate.FromDate,
                            RefDate: eventDate.FromDate,
                            Quantity: eventDate.Quantity
                        })
                    }
                } else if (values.TypeQuantity === 'Days') {
                    for (const eventDate of activeEventDates) {
                        models.push({
                            ModelID: groupedModel.ModelID,
                            ModelBillingInfoID: null,
                            EventID: eventDate.EventID,
                            WorkDate: eventDate.FromDate,
                            RefDate: eventDate.FromDate,
                            Quantity: 1
                        })
                    }
                }
            }
        }
        if (models.length === 0) {
            setSubmitting(false);
            notification.error({
                message: 'Error',
                description: 'Please select at least one model',
                placement: 'bottomRight',
                bottom: 100,
                duration: 0
            });
        } else {
            apiData = {
                ...apiData,
                Models: [...models]
            }
            try {
                await Axios.post('/transactions/JobFee', apiData);
                forceReloadProject(currentProject.ID);
                forceReloadProjectAccountingDetails(currentProject.ID);
                forceReloadProjectTransactions(currentProject.ID);
                forceReloadProjectStatements(currentProject.ID);
                onCloseDrawer();
                setSubmitting(false);
            } catch (error) {
                console.log('ant : Create Project Rate Error => ', error);
                setSubmitting(false);
            }
        }
    };

    const clientBillingInfoDefault = clientBillingInfos.find(item => item.isDefault)
    let clientBillingInfoDefaultID
    if (clientBillingInfoDefault) {
        clientBillingInfoDefaultID = clientBillingInfoDefault.ID
    } else {
        if (clientBillingInfoDefault && clientBillingInfoDefault.length > 0) {
            clientBillingInfoDefaultID = clientBillingInfoDefault[0].ID
        }
    }

    const initialValues = {
        ContactToBill: { ContactID: currentProject.CustomerID, ContactBillingInfoID: clientBillingInfoDefaultID },
        TransactionLabelID: transactionProperties.startLabel.ID,
        Title: transactionProperties.startLabel.Label,
        Description: '',
        Amount: [configurations.defaultCurrency, null],
        TypeQuantity: defaultTypeQuantity,
        UsageID: null,
        IncludingAgencyFee: false,
        ClientAdvance: [configurations.defaultCurrency, null],
    }

    const typeQuantities = [
        { key: "H", label: 'Hours', value: 'Hours' },
        { key: "D", label: 'Days', value: 'Days' },
        { key: "F", label: 'Flat Rate', value: 'Flat' },
        { key: "U", label: 'Units', value: 'Units' },
    ]

    const updateAgencyFeeToApply = (selectedClient) => {
        if (selectedClient && selectedClient.DC && selectedClient.DC.length) {
            if (selectedClient.DC[0].AF) {
                setAgencyFeeToApply(selectedClient.DC[0].AF)
                return true
            }
        }
        setAgencyFeeToApply(configurations.defaultAgencyFeePerc)
    }

    return (
        currentProject
            ?
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Title is required"),
                    TransactionLabelID: Yup.string().required("Label is required"),
                    ContactToBill: Yup.string().required("Client is required")
                })}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true)
                    saveTransaction(values, setSubmitting);
                }}>
                {({ isSubmitting, values, setFieldValue, handleSubmit, touched, isValid, validateForm }) => {
                    const currentTransactionLabel = transactionProperties.labels.find(label => label.ID === values.TransactionLabelID);
                    return (
                        <NwForm
                            values={values}
                            //onTouchForm={onChangeForm}
                            onFinish={handleSubmit}
                            layout="vertical">
                            <ProjectTransactionFormContainer>
                                <Row gutter={32}>
                                    <Col span={13} className='rate-form'>
                                        {currentProject.PrevisionalTransactions.length > 0 &&
                                            <div className='previsional'>
                                                <div className='label'>Estimated Fees</div>
                                                {currentProject.PrevisionalTransactions.map(transaction => {
                                                    const transactionLabel = getTransactionLabel(transaction.TransactionLabelID)
                                                    return (
                                                        <div className='transaction-item' key={transaction.ID}>
                                                            <div
                                                                className='amount'
                                                                onClick={() => {
                                                                    setFieldValue('TransactionLabelID', transactionLabel.ID)
                                                                    setFieldValue('Description', transaction.Description)
                                                                    setFieldValue('Amount', [transaction.Currency, transaction.Amount])
                                                                    setFieldValue('TypeQuantity', transactionLabel.TypeQuantityName)
                                                                    validateForm()
                                                                }}>
                                                                {`${transactionLabel.Label} - ${transaction.Amount} ${transaction.Currency}`}
                                                            </div>
                                                            <div className='description'>{transaction.Description}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        }
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Field
                                                    disabled={currentTransactionLabel.FixedTarif}
                                                    component={NWAmountInput}
                                                    value={values.Amount}
                                                    label="Amount"
                                                    name="Amount"
                                                    validate={validateAmount}
                                                    onAfterChange={(value) => {
                                                        console.log('value', value[0])
                                                        console.log('configurations.defaultCurrency', configurations.defaultCurrency)
                                                        if (value[0] !== configurations.defaultCurrency) {
                                                            setUsePayroll(false)
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <Field
                                                    component={NWSelect}
                                                    label="Per"
                                                    name="TypeQuantity"
                                                    disabled={currentTransactionLabel.FixedTarif}
                                                    value={values.TypeQuantity}
                                                    options={typeQuantities}
                                                    onAfterChange={(value) => {
                                                        handleFormatGroupedModelDates()
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        {['Flat', 'Days'].includes(values.TypeQuantity) &&
                                            // <Row gutter={16}>
                                            //     <Col span={20}>
                                            <div className="inclusive-agency-fee">
                                                <Field
                                                    component={NWSwitch}
                                                    text={`Activate If the Amount Includes Agency Fee (${agencyFeeToApply}%)`}
                                                    checked={values.IncludingAgencyFee}
                                                    name="IncludingAgencyFee"
                                                />
                                            </div>
                                            //     </Col>
                                            // </Row>
                                        }
                                        {payrollActive &&
                                        <div className="payroll">
                                            <CustomSwitch
                                                text={`Payroll / Client Advance`}
                                                checked={usePayroll && !currentTransactionLabel.FixedTarif && configurations.defaultCurrency === values.Amount[0]}
                                                disabled={currentTransactionLabel.FixedTarif || configurations.defaultCurrency !== values.Amount[0]}
                                                onChange={value => setUsePayroll(value)}
                                            />
                                            {(currentTransactionLabel.FixedTarif || configurations.defaultCurrency !== values.Amount[0]) &&
                                                <div className="payroll-not-allowed">
                                                    not allowed if the fee is not in the default currency or if the fee is fixed
                                                </div>
                                            }
                                            {usePayroll &&

                                                <div className="payroll-amount">
                                                    <Field
                                                        disabled={currentTransactionLabel.FixedTarif}
                                                        component={NWAmountInput}
                                                        value={values.ClientAdvance}
                                                        label=""
                                                        name="ClientAdvance"
                                                        fixedCurrency={configurations.defaultCurrency}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        }
                                        <TransactionLabelsSelector
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            transactionProperties={transactionProperties}
                                            onChange={handleFormatGroupedModelDates}
                                        />
                                        <Field
                                            component={NWContactWithBillingInfo}
                                            fullSelector
                                            value={values.ContactToBill}
                                            label="Client"
                                            name="ContactToBill"
                                            contacts={contacts}
                                            billingInfos={clientBillingInfos}
                                            disabled={contacts.length === 1}
                                            required
                                            onAfterChange={() => {
                                                const selectedClient = getContact(values.ContactToBill.ContactID);
                                                updateAgencyFeeToApply(selectedClient);
                                            }}
                                        />
                                        <Field
                                            component={NWInput}
                                            label="Title"
                                            name="Title"
                                            type="text"
                                            value={values.Title}
                                        />
                                        <Field
                                            component={NWTextAreaCharCounter}
                                            label="Description"
                                            name="Description"
                                            type="text"
                                            value={values.Description}
                                            maxLength={2000}
                                            autoSize={{ minRows: 4, maxRows: 10 }}
                                        />
                                        <UsageContainer>
                                            <FlexContainer mg='0 0 8px'>
                                                <Switch
                                                    text='Usage'
                                                    checked={showUsage}
                                                    onChange={value => setShowUsage(value)} />
                                            </FlexContainer>
                                            {showUsage && currentProject.Usages.length > 0 &&
                                                <Field
                                                    component={NWSelect}
                                                    value={values.UsageID}
                                                    // label="Usage"
                                                    placeholder='Select an existing usage'
                                                    name="UsageID"
                                                    options={currentProject.Usages.map(el => {
                                                        const usageTypeNames = el.UsageTypes.join(', ');
                                                        return { key: el.ID, value: el.ID, label: `${usageTypeNames} - ${el.Duration}` };
                                                    })}
                                                />
                                            }
                                            {showUsage &&
                                                <AddNewLink onClick={() => setShowNewUsage(true)}>add new usage info</AddNewLink>
                                            }
                                        </UsageContainer>
                                    </Col>
                                    <Col span={11}>
                                        <ModelSelectorWorkDates
                                            typeQuantity={values.TypeQuantity}
                                            models={groupedModelDates}
                                            updateModels={setGroupedModelDates}
                                        />
                                    </Col>
                                </Row>
                            </ProjectTransactionFormContainer>
                            <FixedSaveAndCancelBar
                                isSubmitting={isSubmitting}
                                isValid={isValid}
                                onCancel={() => {
                                    onCloseDrawer();
                                }}
                            />
                            {showNewUsage &&
                                // @ts-ignore
                                <AddEditUsageDrawer
                                    projectId={currentProject.ID}
                                    usageTypes={cachedList.usageTypes.items}
                                    onUpdate={async newUsage => {
                                        await forceReloadProject(currentProject.ID);
                                        setFieldValue('UsageID', newUsage.ID);
                                    }}
                                    onClose={() => setShowNewUsage(false)} />
                            }
                        </NwForm>
                    )
                }}
            </Formik>
            :
            <Loading />
    )
};

export default JobFeeForm;