import React, { useContext } from "react";
import styled from 'styled-components';

import UserIcon from "Components/UserIcon/UserIcon";
import { ListContext } from "Contexts/ListContext";
import { showLog, showLogNoTime } from "Libs/NwMoment";

const CreatedByText = styled.div`
  white-space: ${props => props.noWrap && 'nowrap'};
`;

const CreatedBy = ({ object, author, date, extra = "created", hideAvatar = true, breaklines, textonly, short, hideTime, noWrap, hideAuthor, emptyText = "", noDiv }) => {

    const { cachedList, cachedListLoaded } = useContext(ListContext)

    let userID = null
    let userName = ""
    let userCoverGuid = ""
    let letters = ""
    let datevalue = null

    if (author || date) {
        userID = author
        datevalue = date
    } else {
        if (object && (object.DateCreated || object.UserCreated)) {
            const { DateCreated, UserCreated } = object
            datevalue = DateCreated
            userID = UserCreated
        }
    }

    if (userID) {
        if (cachedListLoaded) {
            const user = cachedList.users.items.find(usr => usr.ID === userID)
            if (user) {
                userName = `${short ? "" : " by "}${user.Name}`
                if (user.Surname) {
                    userName += ` ${user.Surname[0]}`
                }
                userCoverGuid = user.CoverGuid
                letters = user.Name.toUpperCase()[0]
                if (user.Surname) {
                    letters += user.Surname.toUpperCase()[0]
                }
            } else {
                userName = "Unknown"
                letters = "??"
            }
        }
    }

    const getDateFormat = dv => (
        hideTime ? showLogNoTime(dv) : showLog(dv)
    )

    const getText = () => {
        if (short) {
            return (
                <>
                {!hideAuthor && `${userName}, `}{datevalue ? getDateFormat(datevalue) : ""}
                </>
            )
        } else {
            if (textonly) {
                return (<>{extra}{!hideAuthor && `${userName}`}{breaklines && <br />}{datevalue ? `${extra ? ' on ' : ' • '}${getDateFormat(datevalue)}`: ""}</>)
            }
            return (<>{extra} {!hideAuthor && `${userName}`}{breaklines && <br />}{datevalue ? `${extra ? ' on ' : ' • '}${getDateFormat(datevalue)}`: ""}</>)
        }
    }

    if (userName || datevalue) {
        if (textonly) {
            if (noDiv) {
                return <>{getText()}</>
            } else {
                return <CreatedByText noWrap={noWrap}>{getText()}</CreatedByText>
            }
        } else {
            return (
                <CreatedByText className="created-by" noWrap={noWrap}>
                    {(hideAvatar || hideAuthor)
                        ?
                        getText()
                        : (
                            <>
                                <UserIcon userId={userID} coverGuid={userCoverGuid} letters={letters} size="medium" />
                                {" "}
                                {getText()}
                            </>
                        )}
                </CreatedByText>
            );
        }
    } else {
        if (textonly) {
            return (
                <>{emptyText}</>
            )
        } else {
            return (
                <div className="created-by">{emptyText}</div>
            )
        }
    }
};

export default CreatedBy;
