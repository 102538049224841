export const isLocked = (transaction) => {
    const feedback = {locked: false, message: "", modelLocked: false, agencyLocked: false}
    if (transaction.ModelStatements && transaction.ModelStatements.some(statement => statement.Nw3Locked)) {
        feedback.locked = true
        feedback.message = "This transaction is locked - as it has been imported from Netwalk 3 and included in a payment - and cannot be edited. You can only change texts and descriptions"
    }
    if (transaction.ClientStatements && transaction.ClientStatements.some(statement => statement.InvoiceNumber)) {
        feedback.locked = true
        feedback.message = "This transaction is locked - as it has been included in an invoice - and cannot be edited. You can only change texts and descriptions"
    }
    if (transaction.ModelStatements && transaction.ModelStatements.some(statement => statement.PaidStatusName === "Paid" || statement.PaidStatusName === "Partially Paid")) {
        feedback.locked = true
        feedback.message = "This transaction is locked - as it has been paid to a model - and cannot be edited. You can only change texts and descriptions"
        feedback.modelLocked = true
    }
    if (transaction.AgencyStatements && transaction.AgencyStatements.some(statement => statement.PaidStatusName === "Paid" || statement.PaidStatusName === "Partially Paid")) {
        feedback.locked = true
        feedback.message = "This transaction is locked - as it has been paid to an agency - and cannot be edited. You can only change texts and descriptions"
        feedback.agencyLocked = true
    }
    return feedback
}