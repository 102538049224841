import React, { useContext, useMemo } from 'react'
import { duotone, light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ListContext } from 'Contexts/ListContext';
import useEventStyle from 'Hooks/UseEventStyle';
import NwIcon from 'Components/Gui/NwIcon';
import styled from 'styled-components';
import { Menu } from 'antd';
import { useUserPermission } from 'Contexts/UserContext';

const Wrapper = styled.div`
    padding-bottom: 1rem;
    min-width: 20rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .left-menu {
        flex: 1 0 0;
        border-right: ${props => props.theme.DefaultBorder};
        padding-right: 1rem;
        margin-right: 1rem;
    }

    .right-menu {
        flex: 1 1 0;
    }


    .ant-menu-vertical {
        border-right: none;
    }

    .ant-menu {
        border-radius: 0 0 6px 6px;
    }
    .ant-menu-item {
        margin: 0 !important;
        border-bottom: ${props => props.theme.DefaultBorder};
        height: 2rem;
        line-height: 2rem;

        &:last-child {
            border-bottom: none;
        }

        text-transform: uppercase;
        font-size: .8rem;
        vertical-align: middle;

        svg  {
            margin: 0 6px;
        }

        .active {
            font-weight: bold;

            svg {
                color: ${props => props.theme.DangerColor};
            }
        }

        .badge {
            color: #fff;
            background-color: ${props => props.theme.DangerColor};
            display: inline-block;
            padding: .25em .4em;
            font-size: .75rem;
            font-weight: 700;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            margin-top: .2em;
            vertical-align: baseline;
            border-radius: .25rem;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
    }
`

const AddProjectEventDropdownContent = ({
    onCreateEvent,
    onCreatePackage
}) => {
    const { cachedList } = useContext(ListContext);
    const todoStyle = useEventStyle({}, "ToDo");
    const meetingStyle = useEventStyle({}, "Meeting");
    const travelStyle = useEventStyle({}, "Travel");
    const followupStyle = useEventStyle({}, "FollowUp");
    const accommodationStyle = useEventStyle({}, "Accommodation");
    const { eventSubtypes, followUpLabels } = cachedList;


    const canCreateEvent = useUserPermission('CreateBookingEvent');
    const canCreateTravel = useUserPermission('CreateTravels');
    const canCreateFollowUp = useUserPermission('CreateActivities');
    const canCreateMeeting = useUserPermission('CreateMeetings');
    //TODO: implement policy
    const canCreateAccommodation = true

    console.log("canCreateEvent", canCreateEvent)

    const renderEventSubTypeIcon = type => {
        return (
            <NwIcon
                icon={duotone('plus-circle')}
                style={{ '--fa-primary-color': `#${type.ForeColor}`, '--fa-secondary-color': `#${type.BackColor}`, '--fa-primary-opacity': '1', '--fa-secondary-opacity': '1', marginRight: '4px' }}
            />
        )
    }

    const renderEventTypeIcon = type => {
        return (
            <NwIcon icon={type.icon} />
        )
    }

    const renderPackageIcon = () => {
        return (
            <NwIcon icon={light("book-arrow-right")} />
        )
    }

    const renderAction = (action, index) => {
        if (action.group) {
            return ({
                type: "group",
                key: ` group-${index}`,
                label: action.name,
                disabled: action.disabled,
                children: action.subactions.map(renderAction)
            });
        }
        return ({
            key: action.key,
            label: (
                <span>
                    {action.icon}
                    {action.name}
                </span>
            ),
            disabled: action.disabled
        });
    };

    const onClick = (e) => {
        const action = e.key.split("-")[0]
        const eventtype = e.key.split("-")[1]
        const label = e.key.split("-")[2]
        switch (action) {
            case "createevent":
                onCreateEvent({
                    type: eventtype,
                    eventInfo: {
                        followUpLabelCode: label
                    }
                });
                break;
            case "createbookingevent":
                onCreateEvent({
                    type: 'Event',
                    eventInfo: {
                        type: eventtype,
                        subTypeCode: label
                    }
                });
                break;
            case "createpackage":
                onCreatePackage();
                break;
            default:
                break;
        }
    }

    const { castingTypes, jobTypes, defaultCastingType, defaultJobType } = useMemo(() => {
        const castingTypes = eventSubtypes.items.filter(item => item.Type === 'Casting' && !item.Disabled);
        const jobTypes = eventSubtypes.items.filter(item => item.Type === 'Job' && !item.Disabled);
        const defaultCastingType = castingTypes.find(item => item.Default);
        const defaultJobType = jobTypes.find(item => item.Default);
        return {
            castingTypes,
            jobTypes,
            defaultCastingType: defaultCastingType ? defaultCastingType : castingTypes[0],
            defaultJobType: defaultJobType ? defaultJobType : jobTypes[0]
        };
    }, [eventSubtypes]);
    let defaultFollowUp = followUpLabels.items.find(followUpLabel => followUpLabel.Default);
    defaultFollowUp = defaultFollowUp ? defaultFollowUp : followUpLabels[0];
    defaultFollowUp = {
        type: 'FollowUp',
        name: defaultFollowUp ? defaultFollowUp.Label : '',
        icon: followupStyle && followupStyle.icon,
        code: defaultFollowUp ? defaultFollowUp.Code : ''
    }
    const followUpTypes = followUpLabels.items.filter(item => !item.Default && !item.Disabled).map(followUpLabel => {
        return {
            type: 'FollowUp',
            name: followUpLabel.Label,
            icon: followupStyle && followupStyle.icon,
            code: followUpLabel.Code
        }
    });

    const leftMenuActions = []
    const othereventtypes = {
        group: true,
        name: 'ACTIVITES / FOLLOW-UP',
        subactions: [
            {
                name: 'Task',
                key: 'createevent-ToDo-task',
                icon: renderEventTypeIcon(todoStyle),
            },
            {
                name: 'Meeting',
                key: 'createevent-Meeting-meeting',
                icon: renderEventTypeIcon(meetingStyle),
                disabled: !canCreateMeeting
            },
            {
                name: 'Travel',
                key: 'createevent-Travel-travel',
                icon: renderEventTypeIcon(travelStyle),
                disabled: !canCreateTravel
            },
            {
                name: 'Accommodation',
                key: 'createevent-Accommodation-accommodation',
                icon: renderEventTypeIcon(accommodationStyle),
                disabled: !canCreateAccommodation
            },
            {
                name: defaultFollowUp.name,
                key: `createevent-FollowUp-${defaultFollowUp.code}`,
                icon: renderEventTypeIcon(defaultFollowUp),
                disabled: !canCreateFollowUp
            }
        ]
    }
    for (const et of followUpTypes) {
        othereventtypes.subactions.push({
            name: et.name,
            key: `createevent-${et.type}-${et.code}`,
            icon: renderEventTypeIcon(et),
            disabled: !canCreateFollowUp
        })
    }
    leftMenuActions.push(othereventtypes)
    const packagesactions = {
        group: true,
        name: 'PACKAGES',
        subactions: [
            {
                name: "NEW PACKAGE",
                key: `createpackage-package-package`,
                icon: renderPackageIcon()
            }
        ]
    }
    leftMenuActions.push(packagesactions)

    const rightMenuActions = []
    const castingeventtypes = {
        group: true,
        name: 'CASTING',
        subactions: [{
            name: defaultCastingType.Name,
            key: `createbookingevent-Casting-${defaultCastingType.Code}`,
            icon: renderEventSubTypeIcon(defaultCastingType),
            disabled: !canCreateEvent
        }]
    }
    for (const ct of castingTypes.filter(type => type.Code !== defaultCastingType.Code)) {
        castingeventtypes.subactions.push({
            name: ct.Name,
            key: `createbookingevent-Casting-${ct.Code}`,
            icon: renderEventSubTypeIcon(ct),
            disabled: !canCreateEvent
        })
    }
    rightMenuActions.push(castingeventtypes)
    const jobeventtypes = {
        group: true,
        name: 'JOBS AND OPTIONS',
        subactions: [{
            name: defaultJobType.Name,
            key: `createbookingevent-Job-${defaultJobType.Code}`,
            icon: renderEventSubTypeIcon(defaultJobType),
            disabled: !canCreateEvent
        }]
    }
    for (const ct of jobTypes.filter(type => type.Code !== defaultJobType.Code)) {
        jobeventtypes.subactions.push({
            name: ct.Name,
            key: `createbookingevent-Job-${ct.Code}`,
            icon: renderEventSubTypeIcon(ct),
            disabled: !canCreateEvent
        })
    }
    rightMenuActions.push(jobeventtypes)

    return (
        <Wrapper>
            <div className="left-menu">
                <Menu
                    onClick={onClick}
                    className="nw-small-context-menu"
                    items={leftMenuActions.map(renderAction)}
                />
            </div>
            <div className="right-menu">
                <Menu
                    onClick={onClick}
                    className="nw-small-context-menu"
                    items={rightMenuActions.map(renderAction)}
                />
            </div>
        </Wrapper>
    );
}

export default AddProjectEventDropdownContent;
