import React, { useState } from 'react';
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

import NwDrawer from 'Components/Gui/NwDrawer';
import { NWInput } from "Components/Gui/NWForm/NwFormItems";
import { NwForm, NwFormButtonsBar } from 'Components/Gui/NWForm/NwFormWrapper';
import { NwCancelButton, NwSaveButton, NwButton } from "Components/Gui/NwButton";
import FlexContainer from 'Components/Gui/FlexContainer';
import { forceReloadContact } from 'Hooks/Contact/UseContact';

const PlayerWrapper = styled.div`
    border-radius: 4px;
    margin-top: 16px;
    margin-bottom: 16px;
`;

const AddWebVideoDrawer = ({ contactID, onClose }) => {
    const [touchForm, setTouchForm] = useState(false);

    const initialValues = {
        VideoLink: '',
        VideoUrl: '',
        Title: ''
    };

    const saveWebVideo = async (values, setSubmitting) => {
        setSubmitting(true);
        try {
            const videoData = {
                link: values.VideoUrl,
                title: values.Title,
                contactID: contactID
            };

            await Axios.post('/documents/LinkVideo', videoData);
            forceReloadContact(contactID);
            setSubmitting(false);
            onClose();
        } catch (error) {
            setSubmitting(false);
            console.log('ant : error =>', error);
        }
    };

    const handleValidate = (videoLink, setFieldValue) => {
        if (!validateLink(videoLink)) {
            setFieldValue('VideoUrl', videoLink);
        }
    };

    const validateLink = value => {
        if (/(https?:\/\/)www.(youtube.com\/watch[?]v=([a-zA-Z0-9_-]{11}))|https?:\/\/(www.)?vimeo.com\/([0-9]{8,10})/g.test(value)) {
            return false;
        }
        return 'Please input valid url';
    }

    return (
        <NwDrawer
            touchForm={touchForm}
            onTouchForm={setTouchForm}
            onClose={onClose}
            title='Link a Web Video'>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    Title: Yup.string().required("Title is required"),
                    VideoUrl: Yup.string().required("VideoUrl is required"),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    saveWebVideo(values, setSubmitting);
                }}
                render={({
                    isSubmitting,
                    values,
                    setFieldValue,
                    handleSubmit
                }) => (
                    <NwForm 
                        values={values}
                        onTouchForm={setTouchForm}
                        onFinish={handleSubmit} 
                        layout="vertical">
                        <Field
                            component={NWInput}
                            label="Paste link here(Vimeo or YouTube only)"
                            name="VideoLink"
                            type="text"
                            value={values.VideoLink}
                            validate={validateLink}
                            onAfterChange={() => {
                                setFieldValue('VideoUrl', '');
                            }}
                        />
                        <FlexContainer justifyContent='space-between' mg='0 0 16px'>
                            <NwButton primary label='validate' onClick={() => handleValidate(values.VideoLink, setFieldValue)} />
                        </FlexContainer>
                        {values.VideoUrl && 
                            <>
                                <PlayerWrapper>
                                    <ReactPlayer
                                        // onReady={() => setIsLoaded(true)}
                                        url={values.VideoUrl}    
                                        width='100%'
                                        height='400px'              
                                        controls
                                    />
                                </PlayerWrapper>
                                <Field
                                    component={NWInput}
                                    label="Title"
                                    name="Title"
                                    type="text"
                                    value={values.Title}
                                />
                                <NwFormButtonsBar
                                    left={
                                        <NwCancelButton
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setTouchForm(false);
                                                onClose();
                                            }}
                                        />
                                    }
                                    right={
                                        <NwSaveButton
                                            htmlType="submit"
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                        />
                                    }
                                />
                            </>
                        }
                    </NwForm>
                )}
            />
        </NwDrawer>
    )
};

export default AddWebVideoDrawer;