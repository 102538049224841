import React from "react";
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import QuickFilters from "Components/Filters/FilterForm/ModelFilterForm/QuickFilters";
import { QuickFilterContainer, QuickFilterSpacer } from "Components/Filters/FilterForm/FilterFormUI";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { is } from "../../../../../../../Library/Caches/typescript/5.5/node_modules/@babel/types/lib/index";


const ModelsWallMiniHeader = ({
    quickFilter,
    onSetQuickFilter,
    onAfterChangeFilters,
    filtersType,
    tags
}) => {

    const breakpoints = useNwBreakPoints()
    const isMobile = breakpoints === 1

    let sortOptionsFolder = [
        { label: 'Name', value: 'Name' },
        { label: 'Manual', value: 'Manual' }
    ];

    const sortOptions = [
        { label: 'Name', value: 'Name' },
        { label: 'ID', value: 'ID' },
        { label: 'Agency', value: 'MotherAgency' },
    ];

    if (filtersType === 'all-models') {
        return (
            <QuickFilters
                quickFilter={quickFilter}
                onSetQuickFilter={onSetQuickFilter}
                tags={tags}
                onAfterChangeFilters={onAfterChangeFilters}
                isMini
                isMobile={isMobile}
            />
        )
    }
    if (isMobile) {
        return (<></>)
    } else {
        if (filtersType === 'folders') {
            return (
                <QuickFilterContainer $isMini $topPadded>
                    <div className="quick-filter-item">
                        <span className="before">sort by</span>
                        <RadioButtonGroup
                            value={quickFilter.sortFolder}
                            onChange={(value) => {
                                onSetQuickFilter({
                                    ...quickFilter,
                                    sortFolder: value
                                })
                                if (onAfterChangeFilters) {
                                    onAfterChangeFilters()
                                }
                            }}
                            size='small'
                            options={sortOptionsFolder} />
                    </div>
                </QuickFilterContainer>
            )
        }
        return (
            <QuickFilterContainer $isMini $topPadded>
                <div className="quick-filter-item">
                    <span className="before">sort by</span>
                    <RadioButtonGroup
                        value={quickFilter.sort}
                        onChange={(value) => {
                            onSetQuickFilter({
                                ...quickFilter,
                                sort: value
                            })
                            if (onAfterChangeFilters) {
                                onAfterChangeFilters()
                            }
                        }}
                        size='small'
                        options={sortOptions} 
                    />
                </div>
            </QuickFilterContainer>
        )
    }
};

export default ModelsWallMiniHeader;