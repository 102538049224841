import styled from 'styled-components';
import { Layout, Menu, Select } from 'antd';

const { Header, Sider } = Layout;

export const StyledHeader = styled(Header)`
    position: fixed;
    z-index: 1035; 
    width: 100%;
    max-width: 100vw;
    padding: 0;
    box-shadow: 0px 2px 22px rgb(0 0 0 / 30%);    
    border-bottom: 1px solid ${props => props.theme.PrimaryColor};
    background: white;
    height: 60px;

    a {
        color: ${props => props.theme.TextColor};
    }

    @media (max-width: 720px) {
        height: 104px;
        //override on ant-layout-header
        line-height: 48px;
    }



    @media (min-width: 1280px) {
        border-bottom: 3px solid ${props => props.theme.PrimaryColor};
    }

    .nw-header {
        width: 100%;
        height: 60px;
        display: flex;
        padding: 0 2rem;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        background: white;
        border-bottom: 3px solid ${props => props.theme.PrimaryColor};

        @media (max-width: 720px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 104px;
            gap: 0;
            padding: 0;
        }

        .nw-header-sections-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            width: calc(100vw - 1rem);
            
            .nw-header-section {
                &.nw-header-main-section {
                    flex-grow: 0;
                    justify-content: flex-end;
                }
            }
        }
        
        .nw-header-section {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: auto;
            gap: 1rem;
            min-height: 60px;

            @media (max-width: 720px) {
                min-height: 48px;
            }

            &.nw-header-logo-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                @media (max-width: 1400px) {
                    gap: 0;
                }

                .nw-header-logo-holder {
                    img {
                        width: 120px;
                        height: auto;
                    }

                    @media (max-width: 1400px) {
                        padding: 0 10px;

                        img {
                            width: 90px;
                        }
                    }

                    @media (max-width: 720px) {
                        img {
                            width: 110px;
                        }
                    }
                }

                .nw-global-search-icon-holder {
                    font-size: 24px;

                    @media (max-width: 1400px) {
                        font-size: 18px;
                    }
                }
            }

            &.nw-header-main-section {
                flex-grow: 1;
                justify-content: center;
                padding-left: 2rem;

                @media (max-width: 1400px) {
                    padding-left: 1rem;
                }
                @media (max-width: 720px) {
                    padding-right: .5rem;
                }
                
                .nw-main-nav-wrapper {
                    width: 100%;
                }
                .nw-main-nav-icon-wrapper {
                    font-size: 1.5rem;

                    @media (max-width: 720px) {
                        font-size: 1.75rem;
                    }
                }
            }

            &.nw-right-buttons {
                border-left: ${props => props.theme.DefaultBorder};
                border-right: ${props => props.theme.DefaultBorder};
                padding: 0 .75rem;
                gap: .25rem;
                align-items: flex-start;

                @media (max-width: 720px) {
                    border-left: none;
                    border-right: none;
                }

                .ant-menu-horizontal {
                    height: 54px;
                    padding-top: 8px;
                    width: 48px;
                    text-align: center;

                    .ant-menu-item, .ant-menu-submenu {
                        padding: 0 !important;
                        width: 100%;

                        .ant-menu-submenu-title {
                            font-size: .95rem;

                            @media (max-width: 1400px) {
                                font-size: .85rem;
                            }
                        
                            .packages-icon-holder {
                                height: 46px;
                                width: 48px;
                                position: relative;
                                display: block;

                                .packages-badge {
                                    position: absolute;
                                    top: 2px;
                                    right: 6px;
                                    background: ${props => props.theme.PrimaryColor};
                                    color: white;
                                    font-size: .6rem;
                                    width: 14px;
                                    height: 14px;
                                    font-weight: bold;
                                    vertical-align: middle;
                                    border-radius: 50%;
                                    line-height: 1.5em;
                                    font-family: 'Chivo Mono', monospace;
                                }

                                /* @media (max-width: 1400px) {
                                    height: 40px;
                                    width: 40px;
                                } */
                            }
                        }
                    }
                }

                @media (max-width: 1400px) {
                    padding: 0 .5rem;
                }

                .nw-global-search-icon-holder {
                    font-size: 25px;
                    padding-top: 6px;
                    margin-left: 8px;
                }

                .select-wrapper {
                    min-width: 12rem;
                }
            }
        }
    }
    
    

    

    .nw-header-right-buttons-holder {
        //text-transform: uppercase;
        padding: 0 1rem;
        border-left: ${props => props.theme.DefaultBorder};
        border-right: ${props => props.theme.DefaultBorder};
        margin-right: 12px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        line-height: 40px;
        min-width: 6rem;

        .ant-menu-submenu {
            .ant-menu-submenu-title {
                .ant-menu-title-content {
                    
                }
            }
        }

        @media (max-width: 1400px) {
            
        }

        /* .ant-menu-submenu-title {
            svg {
                margin-bottom: -6px;
            }
        } */

        /* ul {
            min-width: 48px;
            
            .ant-menu-overflow-item-rest {
                display: none;
            }
        } */
    }

    
`

export const StyledMobileHeader = styled.div`
    
    .mobile-header-first-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        background: white;
        border-bottom: 3px solid ${props => props.theme.PrimaryColor};
        @media (min-width: 992px) {
            display: none;
        }
    }
    
    .mobile-header-second-line {
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        align-items: center;
        line-height: 56px;
    }

    .nw-header-section {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        /* @media (min-width: 1025px) {
            justify-content: flex-end;
        } */
    }

    .nw-header-logo-holder {
        padding: 0 20px;
    }
`

export const SiderStyled = styled(Sider)`
    min-height: 100%;
    max-width: 100vw !important;
    background-color: #fff;
    margin-top: 1px;
    z-index: 1030;
    box-shadow: 0 0.125rem 9.375rem rgba(90, 97, 105, 0.1),
        0 0.25rem 0.5rem rgba(90, 97, 105, 0.12),
        0 0.9375rem 1.375rem rgba(90, 97, 105, 0.1),
        0 0.4375rem 2.1875rem rgba(165, 182, 201, 0.1);
    position: fixed;
    text-align: ${props => props.size === 'small' && 'center'};

    .ant-layout-sider-children {
        height: calc(100vh - 116px);
    }
`;

export const StyledAreaSelect = styled(Select)`
    margin-top: 2px;
    min-width: 11rem !important;
    font-size: .8rem !important;
    box-shadow: none !important;

    .ant-select-selection:focus, .ant-select-selection:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
        border-color: ${props => props.theme.BorderColor};
    }

    @media (max-width: 1400px) {
        font-size: .7rem !important;
        min-width: 9rem !important;
    }
    @media (max-width: 720px) {
        font-size: .8rem !important;
        min-width: 10rem !important;
    }
`;

// export const NavBarButtonsContainer = styled.div`
//     text-transform: uppercase;
//     padding: 0 1rem;
//     font-size: .8rem;
//     border-left: ${props => props.theme.DefaultBorder};
//     border-right: ${props => props.theme.DefaultBorder};
//     margin-right: 12px;
//     display: flex;
//     justify-content: space-between;
//     align-items: flex-end;
//     line-height: 40px;
//     min-width: 80px;

//     .ant-menu-submenu-title {
//         svg {
//             margin-bottom: -6px;
//         }
//     }

//     ul {
//         min-width: 48px;
        
//         .ant-menu-overflow-item-rest {
//             display: none;
//         }
//     }
// `

// export const NavBarButtonsMobileContainer = styled(FlexContainer)`
//     .ant-menu-horizontal {
//         border-bottom: none;
//         line-height: 56px;

//         .ant-menu-submenu {
//             bottom: 0;
//             border-bottom: none;
//             padding: 0 0 0 10px !important;

//             .ant-menu-submenu-title svg {
//                 margin-bottom: -4px;
//             }
//         }
//         .ant-menu-submenu:hover {
//             border-bottom: none;
//         }
//     }

//     ul {
//         min-width: 0;
//         line-height: 56px;

//         .ant-menu-overflow-item-rest {
//             display: none;
//         }
//     }
// `;

export const StyledMenu = styled(Menu)`
    background: transparent;
    &.ant-menu-horizontal {
        line-height: 57px;
        border-bottom: none;
        /* border-bottom: 3px solid ${props => props.theme.PrimaryColor}; */
        
        .ant-menu-item, .ant-menu-submenu {
            
            padding: 0 10px !important;
            margin: 0 !important;

            .ant-menu-submenu-title {
                padding: 0 !important;
                &:hover {
                    color: ${props => props.theme.PrimaryColor};
                }
            }

            &.ant-menu-item-selected, &.ant-menu-submenu-selected {
                background-color: ${props => props.theme.PrimaryColor};
                color: #fff;

                .ant-menu-submenu-title:hover {
                    color: #fff;
                }
            }
        }

        .ant-menu-item, .ant-menu-submenu {
            top: auto;
            
            &.small {
                font-size: .7em;
            }
        }
        
        .ant-menu-item, .ant-menu-submenu-title {
            padding: 0 15px;
            font-weight: 500;
            text-transform: uppercase;
            font-size: .85em;
        }

    }

    .ant-menu-vertical {
        min-height: 70vh;
    }
`

export const NavMenu = styled(StyledMenu)`
    &.ant-menu-horizontal {
        border-bottom: none;
        .ant-menu-item, .ant-menu-submenu {
            @media (max-width: 1400px) {
                padding: 0 5px !important;
                .ant-menu-title-content {
                    font-size: .7rem;

                    .nw-main-nav-subitems {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
        .ant-menu-submenu:not(.ant-menu-submenu-selected) {
            &:hover {
                background-color: ${props => props.theme.PrimaryColorLight};
            }
        }

        &.nw-main-nav-tablet {
            font-size: .9rem;
            line-height: 40px;
            justify-content: center;
            gap: .75rem;

            .ant-menu-item, .ant-menu-submenu {
                padding: 0 8px !important;
                margin: 0 !important;
                //width: auto;
                text-align: center;
                
                @media (max-width: 1400px) {
                    padding: 0 8px !important;
                    margin: 0 !important;
                    .ant-menu-title-content {
                        font-size: .9rem;
                    }
                }
            }

            &.nw-main-nav-smaller {
                font-size: .8rem;
                gap: .5rem;
                .ant-menu-item, .ant-menu-submenu {
                    width: auto;
                    padding: 0 6px !important;
                    @media (max-width: 1400px) {
                        padding: 0 6px !important;
                        .ant-menu-title-content {
                            font-size: .8rem;
                        }
                    }
                }
            }
        }
    }
    &.ant-menu-inline {
        &.nw-main-nav-mobile {
            padding-top: 4rem;
            >.ant-menu-submenu >.ant-menu-submenu-title {
                padding-right: 2rem;
                border-bottom: ${props => props.theme.DefaultBorder};
                .ant-menu-title-content {
                    text-transform: uppercase;
                    font-size: 1.1rem;
                }
            }
            >.ant-menu-item {
                border-bottom: ${props => props.theme.DefaultBorder};

                .ant-menu-title-content {
                    text-transform: uppercase;
                    font-size: 1.1rem;
                }
            }

            .ant-menu-submenu {
                &.ant-menu-submenu-open {
                    border-bottom: ${props => props.theme.DefaultBorder};
                    padding-bottom: .5rem;
                }
            }

            .ant-menu-item-group-title {
                padding: 2px 2rem 0 0;
                .submenu-title-wrapper {
                    display: flex;
                    align-items: center;
                    gap: .25rem;
                    justify-content: flex-end;
                }
            }

            .ant-menu-item {
                &:active {
                    background: #fff;
                }
                .ant-menu-submenu-title:active {
                    background: #fff;
                }
                
                .ant-menu-title-content {
                    .nw-main-nav-subitems {
                        display: flex;
                        align-items: center;
                        gap: .5rem;
                    }
                }
            }
        }
    }
`;


