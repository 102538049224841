import React from 'react';
import { isNumber } from 'lodash';
import moment from 'moment';
import ls from 'local-storage';
import MomentTimeZone from 'moment-timezone';

const formats = [
    { name: "standard", format: "MMM D YYYY", formatWeekDay: 'ddd', formatOnlyDay: 'D', formatOnlyMonth: "MMM", formatNoYear: "MMM D" },
    { name: "extended", format: "dddd, MMMM Do YYYY", formatNoYear: "dddd, MMMM Do", formatWeekDay: 'dddd', formatOnlyMonth: "MMMM" },
    { name: "extended-short", format: "ddd DD MMM YYYY", formatNoYear: "ddd DD MMM" },
    { name: "short", format: "YYYY-MM-DD", formatNoYear: "MM-DD" }
]

const getNow = (startOfDay) => {
    //removed .utc() because it has to work with local time
    if (startOfDay) {
        return moment().startOf('day')
    }
    return moment()
}

const getMoment = (date, format) => {
    //removed .utc() because it has to work with local time
    if (format) {
        return moment(date, format)
    }
    return moment(date)
}
const getMomentUtc = (date) => {
    //to be used only with server-generated dates
    return moment.utc(date)
}
const utcToLocal = (date) => {
    return getMomentUtc(date).local()
}

const getUserTimeFormat = () => {
    const st = ls.get("nw_config_userguiconfig");
    if (st) {
        if (st.calendar && st.calendar.timeFormat) {
            return Number(st.calendar.timeFormat)
        }
    }
    return 24
}

const timeFormat = () => {
    const userTimeFormat = getUserTimeFormat()
    return (userTimeFormat === 12 ? 'h:mm A' : 'HH:mm')
}

const getFormat = (name, omityear, time, formatName) => {
    const df = formats.find((f) => { return f.name === name })
    let format = omityear ? df.formatNoYear : df.format
    if (formatName) {
        format = df[formatName];
    }
    if (time) {
        format += " " + timeFormat()
    }
    return format
}

const getUserTimeZone = () => {
    return MomentTimeZone.tz.guess()
}

const parse = (momentdate, style, showyear) => {
    const checkShowYear = () => {
        if (typeof showyear === 'boolean') {
            return !showyear
        }
        return momentdate.year() === getNow().year()
    }
    const omityear = checkShowYear()

    switch (style) {
        case "log":
            return momentdate.format(getFormat("standard", omityear, true))
        case "notime":
            return momentdate.format(getFormat("standard", omityear, false))
        case "ext":
            return momentdate.format(getFormat("extended", omityear, true))
        case "ext-notime":
            return momentdate.format(getFormat("extended", omityear, false))
        case "ext-short":
            return momentdate.format(getFormat("extended-short", omityear, false))
        case "short":
            return momentdate.format(getFormat("short", omityear, false))
        case "weekDay":
            return momentdate.format(getFormat("standard", omityear, false, 'formatWeekDay'))
        case "onlyDay":
            return momentdate.format(getFormat("standard", omityear, false, 'formatOnlyDay'))
        case "onlyMonth":
            return momentdate.format(getFormat("standard", omityear, false, 'formatOnlyMonth'))
        case "weekDayExtended":
            return momentdate.format(getFormat("extended", omityear, false, 'formatWeekDay'))
        case "onlyMonthExtended":
            return momentdate.format(getFormat("extended", omityear, false, 'formatOnlyMonth'))
        default:
            return momentdate.format(getFormat("standard", omityear, true))
    }
}

const showLog = (date) => {
    return parse(utcToLocal(date), "log")
}

const showLogNoTime = (date) => {
    return parse(utcToLocal(date), "notime")
}

const showLogFromNow = (date) => {
    return utcToLocal(date).fromNow()
}

const showDate = (date, style) => {
    const m = getMoment(date) //moment.utc(date)
    if (m.isValid) {
        return parse(m, style || "")
    } else {
        return "n.d."
    }
}

const showDateNoTime = (date, showYear) => {
    const m = getMoment(date) //moment.utc(date)
    if (m.isValid()) {
        if (typeof showYear === 'boolean') {
            return parse(m, "notime", showYear)
        }
        return parse(m, "notime")
    } else {
        return "n.d."
    }
}

const showDateNoTimeExtended = (date) => {
    const m = getMoment(date) //moment.utc(date)
    if (m.isValid()) {
        return parse(m, "ext-notime")
    } else {
        return "n.d."
    }
}

const showShortDate = (date) => {
    const m = getMoment(date) //moment.utc(date)
    if (m.isValid()) {
        return parse(m, "short")
    } else {
        return "n.d."
    }
}

const showTimeFromArray = (timeArray) => {
    //convert array [h,m] to string in 12 or 24 format based on timeFormat()
    if (timeArray && timeArray.length > 1) {
        const h = timeArray[0]
        const m = timeArray[1]
        const time24 = `${PadDigits(h, 2)}:${PadDigits(m, 2)}`
        if (getUserTimeFormat() === 12) {
            return moment(time24, 'HH:mm').format('h:mm A')
        }
        return time24
    }
    return "n.d."
}



const showTime = (date) => {
    if (Array.isArray(date)) {
        return showTimeFromArray(date)
    }
    const m = getMoment(date) //moment.utc(date)
    if (m.isValid()) {
        return m.format(timeFormat())
    } else {
        return "n.d."
    }
}

const isFullDay = (start, end) => {
    return (start.hour() === 0 && start.minute() === 0 && end.hour() === 23 && end.minute() === 59)
}

const showPeriod = (startdate, enddate) => {
    const start = getMoment(startdate) //moment.utc(startdate)
    const end = getMoment(enddate) //moment.utc(enddate)
    if (start.isValid()) {
        if (end.isValid()) {
            //there's an end date/time
            if (start.isSame(end, 'day')) {
                //same day for start and end. It's a 1-day period
                if (start.isSame(end, 'minute')) {
                    //same time for start and end
                    return parse(start)
                }
                //different time
                if (isFullDay(start, end)) {
                    //from 00:00 to 23:59
                    return parse(start, "notime") + " - all day"
                }
                //standard 1 day period: 1 jan 2000 10:00 > 12:00
                return parse(start, "notime") + " - " + start.format(timeFormat()) + " > " + end.format(timeFormat())
            } else {
                if (isFullDay(start, end)) {
                    return parse(start, "notime") + " > " + parse(end, "notime")
                } else {
                    return parse(start) + " > " + parse(end)
                }
            }
        } else {
            //only start, show date and time
            return parse(start)
        }
    } else {
        return "n.d."
    }
}

//component
const ShowMultiTimePeriod = ({ event }) => {
    const start = getMoment(event.FromDate) //moment.utc(startdate)
    const end = getMoment(event.ToDate) //moment.utc(enddate)
    const startbreak = getMoment(event.StartBreak)
    const endbreak = getMoment(event.EndBreak)
    if (start.isValid()) {
        if (end.isValid()) {
            if (start.isSame(end, 'day')) {
                if (startbreak && endbreak) {
                    //there's a break
                    return (
                        <>
                            <span>{parse(start)} &gt; {parse(startbreak)}</span>
                            <span>{parse(endbreak)} &gt; {parse(end)}</span>
                        </>
                    )
                }
                //standard 1 day period: 1 jan 2000 10:00 > 12:00
                return (<span>{parse(start, "notime")} - {start.format(timeFormat())} &gt; {end.format(timeFormat())}</span>)
            }
        }
    }
    return (<span>n.d.</span>)
}

const showPeriodByDay = (firstDate, lastDate) => {
    if (!firstDate || !lastDate) {
        return "n.d.";
    }
    const first = getMoment(firstDate);
    const last = getMoment(lastDate);

    if (first.isSame(last, 'day')) {
        return `${first.format('D MMM, YYYY')}`;
    } else if (first.isSame(last, 'month')) {
        return `${first.format('D')} > ${last.format('D MMM, YYYY')}`;
    } else if (first.isSame(last, 'year')) {
        return `${first.format('D MMM')} > ${last.format('D MMM, YYYY')}`;
    } else {
        return `${first.format('D MMM, YYYY')} > ${last.format('D MMM, YYYY')}`;
    }
};

const toISODate = (m) => {
    return moment(m).format('YYYY-MM-DD')
}

const toISODateTime = (m) => {
    return moment(m).format('YYYY-MM-DDTHH:mm')
}

const getToday = (modifier) => {
    let ct = getNow();
    if (modifier && isNumber(modifier)) {
        if (modifier > 0) {
            ct = ct.add(modifier, "days");
        } else {
            ct = ct.subtract(Math.abs(modifier), "days");
        }
    }
    return ct.startOf('day');
}
const getTodayISO = (modifier) => {
    const tm = getToday(modifier);
    return toISODate(tm);
}

const getTodayEndOfTheDayISO = (modifier) => {
    const tm = getToday(modifier);
    return toISODate(tm.endOf('day'));
}

const getLastYear = () => getNow().subtract(1, "years").startOf('day');
const getNextYear = () => getNow().add(1, "years").startOf('day');

const getLastYearISO = () => {
    const tm = getLastYear();
    return toISODate(tm);
}

const getNextYearISO = () => {
    const tm = getNextYear();
    return toISODate(tm);
}

const getStartCurrentISO = (unit) => {
    const tm = getNow().startOf(unit);
    return toISODate(tm);
}

const getEndCurrentISO = (unit) => {
    const tm = getNow().endOf(unit).endOf('day');
    return toISODate(tm);
}

const getStartLastISO = (unit) => {
    const tm = getNow().subtract(1, unit).startOf(unit);
    return toISODate(tm);
}

const getEndLastISO = (unit) => {
    const tm = getNow().subtract(1, unit).endOf(unit).endOf('day');
    return toISODate(tm);
}

const GetTimestampNumeric = (seconds) => {
    //if not seconds (null or false) returns the timestamp in milliseconds
    const ts = seconds ? getNow().unix() : getNow().valueOf()
    return ts
}

const GetTimestamp = (seconds) => {
    //if not seconds (null or false) returns the timestamp in milliseconds (to string)
    const ts = seconds ? getNow().unix() : getNow().valueOf()
    return ts.toString()
}

const GetTimestampFromDate = (date) => {
    const ts = getMoment(date).unix()
    return ts.toString()
}

const isToday = (ldate) => getNow().isSame(ldate, "day")

const isBirthday = (bd, d) => {
    let dateToCompare = getNow()
    if (d) {
        dateToCompare = getMoment(d)
    }
    if (bd) {
        const bdmoment = getMoment(bd)
        if (bdmoment.isValid()) {
            if ((bdmoment.month() === dateToCompare.month()) && (bdmoment.date() === dateToCompare.date()))
            {
                return true
            }
        }
    }
    return false
}


const getAge = (ldate) => Math.floor(getNow().diff(moment(ldate), 'years'))

const isOlderThan = (ldate, months) => {
    const today = getNow()
    const last = moment(ldate)
    const diff = today.diff(last, 'months')
    return diff > months
}

const getTime = (date) => {
    if (date) {
        var momentdate = getMoment(date)
        if (momentdate.isValid()) {
            return ([momentdate.hour(), momentdate.minute()])
        }
    }
    return [0, 0]
}

const timeStringToArray = (v) => {
    if (Array.isArray(v) && v.length === 2) {
        return v
    }
    if (v && v.indexOf(':') > 0) {
        const h = Number(v.split(':')[0])
        const m = Number(v.split(':')[1])
        return [h, m]
    }
    return [0, 0]
};

const momentToTimeArray = (v) => {
    if (v) {
        return ([v.hour(), v.minute()])
    }
    return [0, 0]
}

const timeArrayToString = (v) => {
    if (v && v.length > 1) {
        const h = PadDigits(v[0], 2)
        const m = PadDigits(v[1], 2)
        return `${h}:${m}`
    }
    return "00:00"
};

const diffInTimeArrays = (start, end) => {
    const startHour = start[0]
    const endHour = end[0]
    const startMinute = start[1]
    const endMinute = end[1]
    const diff = endHour - startHour
    const minuteDiff = endMinute - startMinute
    return [diff, minuteDiff]
}

const convertToMinutes = (timeArray) => {
    return timeArray[0] * 60 + timeArray[1]
}

const isSameTime = (start, end) => {
    if (start && end && Array.isArray(start) && Array.isArray(end)) {
        return convertToMinutes(start) === convertToMinutes(end)
    }
    if (!start && !end) {
        return true
    }
    return false
}

const isSecondTimeLater = (start, end) => {
    return convertToMinutes(start) < convertToMinutes(end)
}

const isSecondTimeEqualOrLater = (start, end) => {
    console.log('isSecondTimeEqualOrLater', start, end)
    console.log('convertToMinutes(start)', convertToMinutes(start))
    console.log('convertToMinutes(end)', convertToMinutes(end))
    return convertToMinutes(start) <= convertToMinutes(end)
}

const manipulateTimeArray = (timeArray, quantity, position) => {
    const hours = timeArray[0];
    const minutes = timeArray[1];
    if (position === 0) {
        // Adding/subtracting hours
        let newHours = hours + quantity;
        if (newHours < 0) {
            newHours = 24 + newHours; // Adjust for negative hours
        }
        newHours = newHours % 24; // Adjust for hours over 24
        return [newHours, minutes];
    } else {
        // Adding/subtracting minutes
        let newMinutes = minutes + quantity;
        let newHours = hours;
        if (newMinutes < 0) {
            newMinutes = 60 + newMinutes; // Adjust for negative minutes
            newHours--; // Decrease the hour
        } else if (newMinutes > 59) {
            newMinutes = newMinutes - 60; // Adjust for minutes over 59
            newHours++; // Increase the hour
        }
        newHours = (newHours < 0) ? 24 + newHours : newHours % 24; // Adjust hours if necessary
        return [newHours, newMinutes];
    }
}

const PadDigits = (n, totalDigits) => {
    n = n.toString();
    var pd = '';
    if (totalDigits > n.length) {
        for (let i = 0; i < (totalDigits - n.length); i++) {
            pd += '0';
        }
    }
    return pd + n.toString();
}

const getExpirationInDays = date => {
    const current = getNow();
    const todoDate = getMoment(date);
    const difference = current.diff(todoDate, "days");
    if (difference === 0) {
        return 'today'
    } else {
        return `(${difference} day${difference === 1 ? '' : 's'} ago)`;
    }
};

const isAllDay = (sd, ed) => {
    //get value, check dates and return if it's all day
    if (getMoment(sd).hour() === 0 && getMoment(sd).minute() === 0) {
        if (ed && (getMoment(ed).hour() === 23 || getMoment(ed).minute() === 59)) {
            return true
        }
        if (ed && (getMoment(ed).hour() === 0 || getMoment(ed).minute() === 0)) {
            return true
        }
        return false
    }
    return false
}

const isArrayTimesAllDay = (sd, ed) => {
    if (sd[0] === 0 && sd[1] === 0) {
        if (ed && (ed[0] === 23 || ed[1] === 59)) {
            return true
        }
        return false
    }
    return false
}

const isSameDate = (date1, date2) => {
    if (getMoment(date1).year() === getMoment(date2).year()
        && getMoment(date1).month() === getMoment(date2).month()
        && getMoment(date1).date() === getMoment(date2).date()) {
        return true;
    }
    return false;
};

const compareAsISODate = (date1, date2) => {
    if (date1 && date2) {
        return toISODate(date1) === toISODate(date2)
    }
    return false
}

const getModelAge = bd => (bd ? `${getAge(bd)} yo` : "");

const parseDateTime = parse;

// const convertDateToUtc = date => {
//     return moment.utc(Date.UTC(moment(date).year(), moment(date).month(), moment(date).date(), moment(date).hour(), moment(date).minute(), 0))
// };

const datePickerToApi = (date) => {
    // if (date) {
    //     return (moment.utc({
    //         y: date.year(),
    //         M: date.month(), 
    //         d: date.date()
    //     }).format('YYYY-MM-DD'))
    // }
    // return null
    if (date) {
        return getMoment(date).format('YYYY-MM-DD')
    }
    return null
}

const datePickerBeforeOnChange = (date) => {
    return datePickerToApi(date)
    //return (date ? date.utc().format() : null)
}

const getDatesInRange = function (startDate, endDate) {
    var dates = [];
    var currDate = getMoment(startDate).startOf('day');
    var lastDate = getMoment(endDate).startOf('day');
    dates.push(currDate.clone());
    while(currDate.add(1, 'days').diff(lastDate) <= 0) {
        dates.push(currDate.clone()); //CHECK FOR .toDate()
    }
    return dates;
};

const moveDateToCurrentYear = (date) => {
    const currentYear = getNow().year();
    return getMoment(date).year(currentYear);
}


export {
    //convertDateToUtc,
    compareAsISODate,
    datePickerBeforeOnChange,
    datePickerToApi,
    diffInTimeArrays,
    getAge,
    getDatesInRange,
    getEndCurrentISO,
    getEndLastISO,
    getExpirationInDays,
    getLastYear,
    getLastYearISO,
    getModelAge,
    getMoment,
    getNextYear,
    getNextYearISO,
    getNow,
    getStartCurrentISO,
    getStartLastISO,
    getTime,
    GetTimestamp,
    GetTimestampFromDate,
    GetTimestampNumeric,
    getToday,
    getTodayEndOfTheDayISO,
    getTodayISO,
    getUserTimeZone,
    isAllDay,
    isArrayTimesAllDay,
    isBirthday,
    isFullDay,
    isOlderThan,
    isSameDate,
    isSameTime,
    isSecondTimeEqualOrLater,
    isSecondTimeLater,
    isToday,
    manipulateTimeArray,
    momentToTimeArray,
    moveDateToCurrentYear,
    parseDateTime,
    showDate,
    showDateNoTime,
    showDateNoTimeExtended,
    showLog,
    showLogFromNow,
    showLogNoTime,
    ShowMultiTimePeriod,
    showPeriod,
    showPeriodByDay,
    showShortDate,
    showTime,
    showTimeFromArray,
    timeArrayToString,
    timeStringToArray,
    toISODate,
    toISODateTime,
}