import React, { useContext, useState, useMemo, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Modal, Checkbox, Select } from 'antd';
import styled from 'styled-components';
import Axios from 'axios';
import ls from 'local-storage';
import { useQueryClient } from '@tanstack/react-query';

import { UserContext } from "Contexts/UserContext";
import { ListContext } from 'Contexts/ListContext';
import FlexContainer from 'Components/Gui/FlexContainer';
import { NwButton } from "Components/Gui/NwButton";
import RadioButtonGroup from "Components/Gui/NWForm/RadioButtonGroup";
import { deleteUserLogs } from 'Libs/NwLogs';
import { fetchModelsByArea, fetchCustomersByArea, prefetchContacts, forceReloadModelsWall } from 'Hooks/Contact/UseContactsList';
import { useStateValue } from "Contexts/StateProvider";
import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const { Option } = Select;

const ErrorMessage = styled.div`
    color: red;
`;

const getEnableFields = object => {
    let enableFields = [];
    Object.keys(object).map(key => {
        if(object[key]) {
            enableFields.push(parseInt(key));
        }
        return null;
    });    
    return enableFields;
};

const UserContextModal = ({ onCancel }) => {
    const { currentUser, UpdateCurrentUser } = useContext(UserContext);
    const { cachedList, LoadData } = useContext(ListContext);
    const [networkAgency, setNetworkAgency] = useState();
    const { NetworkAgencies, Departments } = currentUser;
    const [checkedDepartments, setCheckedDepartments] = useState({});
    const [defaultDepartmentId, setDefaultDepartmentId] = useState(undefined);
    const [isInvalidate, setIsInvalidate] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const history = useHistory();
    const queryClient = useQueryClient();
    const [, dispatch] = useStateValue();
    const breakpoints = useNwBreakPoints();
    
    const getNetworkAgencies = () => {
        let networkAgencies = [];
        for(const accountingGroup of cachedList.accountingGroups.items) {
            networkAgencies = [...networkAgencies, ...accountingGroup.NetworkAgencies];
        }
        return networkAgencies.filter(agency => {
            if (NetworkAgencies.find(agencyId => agencyId === agency.ID)) {
                return true;
            }
            return false;
        })
    }

    const availableNetworkAgencies = getNetworkAgencies();
    const availableDepartments = networkAgency ? networkAgency.Departments.filter(department => Departments.find(id => department.ID === id)) : [];

    useEffect(() => {
        const { nwAgId, activeDepartments, depId } = currentUser.ActiveContext;
        setNetworkAgency(availableNetworkAgencies.find(agency => agency.ID === nwAgId));
        let checkedDepartments = {};
        activeDepartments.map(departmentId => {
            checkedDepartments[departmentId] = true;
            return null;
        })
        setCheckedDepartments(checkedDepartments);
        setDefaultDepartmentId(depId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeDepartments = departmentId => event => {
        if (!event.target.checked && departmentId === defaultDepartmentId) {
            const availableDepartment = Object.keys(checkedDepartments).find(itemId => parseInt(itemId) !== departmentId && checkedDepartments[itemId]);
            setDefaultDepartmentId(availableDepartment ? parseInt(availableDepartment) : undefined);
        }

        if (event.target.checked && !defaultDepartmentId) {
            setDefaultDepartmentId(departmentId);
        }

        setCheckedDepartments({
            ...checkedDepartments,
            [departmentId]: event.target.checked
        });
    };

    const handleChangeDefaultDepartment = department => {
        setDefaultDepartmentId(department);
    };

    const isValid = useMemo(() => {
        const activeDepartments = getEnableFields(checkedDepartments);
        if (activeDepartments.length === 0 || !defaultDepartmentId) {
            return false;
        }
        return true;
    }, [defaultDepartmentId, checkedDepartments]);

    const handleSaveUserContext = async () => {
        const activeDepartments = getEnableFields(checkedDepartments);

        if (activeDepartments.length === 0 || !defaultDepartmentId) {
            setIsInvalidate(true);
        } else {
            const userContextData = {
                depId: defaultDepartmentId,
                nwAgId: networkAgency.ID,
                activeDepartments: activeDepartments
            };

            const accountingGroup = cachedList.accountingGroups.items.find(accGroup => accGroup.NetworkAgencies.find(agency => agency.ID === networkAgency.ID));

            try {
                setIsUpdating(true);
                await Axios.post('/userconfigurations/setActiveContext', userContextData);
                UpdateCurrentUser({
                    ...currentUser,
                    ActiveContext: {
                        ...userContextData,
                        accGroupId: accountingGroup.ID
                    }
                });
                // clear user logs, cahcedlist, query cache
                deleteUserLogs();
                Object.keys(cachedList).map(key => {
                    ls.remove(`nw_cachedlist_${key}`);
                    ls.remove(`nw_cachedlist_${key}_lastupdate`);
                    return null;
                })
                queryClient.clear();
                // reload cachedlist, prefetch contacts
                LoadData();
                prefetchContacts();
                await fetchModelsByArea('booking');
                await fetchCustomersByArea('booking');
                forceReloadModelsWall();
                dispatch({
                    type: 'updateArea',
                    area: 'booking'
                })
                history.push('/dashboard');
                setIsUpdating(false);
                onCancel();
            } catch (error) {
                console.log('ant : Set Active Context error => ', error);
            }
        }

    };

    return (
        <Modal
            width={560}
            footer={null}
            onCancel={onCancel}
            open
            zIndex={1040}
            title="Change Context">   
            {networkAgency && 
                <>
                    <RadioButtonGroup 
                        value={networkAgency.ID} 
                        onChange={networkAgencyId => {
                            setCheckedDepartments({});
                            setDefaultDepartmentId(undefined);
                            setIsInvalidate(false);
                            setNetworkAgency(availableNetworkAgencies.find(agency => agency.ID === networkAgencyId));
                        }} 
                        options={availableNetworkAgencies.map(agency => (
                            { label: agency.Name, value: agency.ID }
                        ))} />
                    <FlexContainer mg='16px 0 24px' column alignItems='flex-start'>
                        {isInvalidate && !isValid &&
                            <ErrorMessage>Please select active departments and a default department</ErrorMessage>
                        }
                        <FlexContainer>
                            <FlexContainer column mg={breakpoints > 3 ? '0 120px 0 0' : '0 80px 0 0'} alignItems='flex-start'>
                                {availableDepartments.filter(department => !department.Disabled).map(department => {
                                    return (
                                        <div key={`department-${department.ID}`}>
                                            <Checkbox 
                                                checked={checkedDepartments[department.ID]}
                                                onChange={handleChangeDepartments(department.ID)}>
                                                {department.Name}
                                            </Checkbox>
                                        </div>
                                    );
                                })}
                            </FlexContainer>
                            <div>
                                <div>Default department</div>
                                <Select
                                    autoComplete='off' 
                                    placeholder={'select department'}
                                    value={defaultDepartmentId} 
                                    style={{width: breakpoints > 3 ? 250 : '100%'}}
                                    getPopupContainer={triggerNode => triggerNode.parentNode}
                                    onChange={handleChangeDefaultDepartment}>
                                    {Object.keys(checkedDepartments).map(departmentId => {
                                        if (checkedDepartments[departmentId]) {
                                            const department = availableDepartments.find(department => department.ID === parseInt(departmentId));
                                            return <Option key={`default-department-${department.ID}`} value={department.ID}>{department.Name}</Option>
                                        }
                                        return (null)
                                    })}
                                </Select>
                            </div>
                        </FlexContainer>
                    </FlexContainer>
                    
                    <FlexContainer fullWidth justifyContent='space-between'>
                        <NwButton label='Cancel' disabled={isUpdating} onClick={onCancel} />
                        <NwButton primary label='Save' disabled={isUpdating} loading={isUpdating} onClick={handleSaveUserContext} />
                    </FlexContainer>
                </>
            }
        </Modal>
    );
};

export default UserContextModal;
