import React, { useState } from "react";
import styled from 'styled-components';
import { Badge } from "antd";
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

//import { useContact } from 'Hooks/Contact/UseContact';
import { useUserPermission } from 'Contexts/UserContext';
import LegalDrawer from "Components/Legal/LegalDrawer";
import NwIcon from "Components/Gui/NwIcon";
import PermissionBlock from 'Components/PermissionBlock';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";

const ModelLegalContainer = styled.p`
    text-transform: uppercase;
    margin-bottom: 0;
`

const ModelDetailsHeaderLegal = ({ currentContact, readOnly }) => {
    const [drawer, setDrawer] = useState(false);
    //const { data: currentContact, isFetching } = useContact(selectedModel);
    const viewModelLegals = useUserPermission('ViewModelLegals', currentContact && currentContact.Users.map(user => user.UserID));
    const breakpoints = useNwBreakPoints();

    const renderModelLegalContainer = () => {
        return currentContact.LegalsCount ?
            <ModelLegalContainer viewModelLegals={viewModelLegals} onClick={() => viewModelLegals && setDrawer(true)}>
                {breakpoints !== 1 &&
                    <>
                        {currentContact.ExpiringLegalsCount > 0 ? 
                            <Badge count={currentContact.LegalsCount} dot>
                                <NwIcon icon={light("passport")} />
                            </Badge>
                            : <NwIcon icon={light("passport")} />
                        }   
                        &nbsp;&nbsp;
                    </>
                }
                {currentContact.LegalsCount} {currentContact.LegalsCount > 1 ? 'documents' : 'document'}
            </ModelLegalContainer>
            :
            <ModelLegalContainer viewModelLegals={viewModelLegals} onClick={() => viewModelLegals && setDrawer(true)}>
                <NwIcon icon={light("passport")} danger />&nbsp;&nbsp;no documents
            </ModelLegalContainer>
    }

    return (
        <>
            {currentContact &&
                <div className="header-body-boxes header-body-boxes-hoverable">
                    <PermissionBlock
                        permission={viewModelLegals}
                        content={renderModelLegalContainer()} />
                </div>
            }
            {drawer && <LegalDrawer selectedContact={currentContact.ID} readOnly={readOnly} onClose={() => setDrawer(false)} />}
        </>
    )
};

export default ModelDetailsHeaderLegal;
