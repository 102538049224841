import React, { useState, useMemo, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from "react-router";
import { List, Card, notification, Modal } from 'antd';
import _ from "lodash";
import Axios from 'axios';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwAddButton, NwButton } from 'Components/Gui/NwButton';
import { NwCard, NwCardContent } from 'Components/Gui/NwCard/NwCard';
import { NwCardTabsContent } from 'Components/Gui/NwCard/NwCardTabs';
import { sliceData } from 'Libs/NwUtils';
import EmptyState from 'Components/Gui/EmptyState';
import FilterTagSelector from 'Components/FilterTagSelector/FilterTagSelector';
import { onUpdatePlaylists, useContactPlaylists } from "Hooks/Contact/UseContactPlaylists"
import ObjectLogHistories from 'Components/ObjectLogHistories/ObjectLogHistories';
import NwDrawer from 'Components/Gui/NwDrawer';
import FlexContainer from 'Components/Gui/FlexContainer';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import NwIcon from 'Components/Gui/NwIcon';
import { UserContext } from 'Contexts/UserContext';
import useNwBreakPoints from "Hooks/UseNwBreakPoints";
import { useWebsites } from "Hooks/Websites/UseWebsites";
import { Loading } from 'Components/Gui/Loading';
import ModelBookPublishDrawer from "Components/ContactDetails/ModelDetails/CommonComponents/BookPublishing/ModelBookPublishDrawer";
import PlayListInfoDrawer from 'Components/MediaDrawer/PlayListInfoDrawer';
import PlayListPreviewDrawer from 'Components/MediaDrawer/PlayListPreviewDrawer';
import ModelMedia from 'Components/ModelMedia/ModelMedia';
import BookItem from 'Components/ContactDetails/ModelDetails/CommonComponents/BookItem';
import { useStateValue } from 'Contexts/StateProvider';
import SelectPictureDrawer from 'Components/SelectPicture/SelectPictureDrawer';
import PlaylistCoverGenerator from 'Components/ImageCroppers/PlaylistCoverGenerator';
import { forceReloadContact } from 'Hooks/Contact/UseContact';
//TODO: new playlist editor
//import PlaylistEditor from 'Components/ModelMedia/PlaylistEditor/PlaylistEditor';


const { confirm } = Modal;

const GridCard = styled(Card.Grid)`
  float: left; //added because ant removed it to use flex- to update later
  width: 50%;
  height: 170px;
  background: white;
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
`;

const StyledList = styled(List)`
  padding: ${props => !props.$mobileView && '0.5rem 0.75rem'};
  .ant-list-item-meta {
    .ant-list-item-meta-content {
      .ant-list-item-meta-title {
        text-transform: uppercase;
        line-height: 18px;
        margin-bottom: 0;
      }
    }
  }
  .ant-list-item {
    .ant-list-item-meta-avatar {
      img {
        border: 2px solid transparent;
      }
    }
    &:hover {
      .ant-list-item-meta-avatar {
        img {
          /* border: 2px solid ${props => props.theme.PrimaryColor}; */
          transform: scale(1.2);
        }
      }
    }
  }
`;

const CardWrapper = styled.div`
    height: 100%;
    position: relative;
    .ant-card-body-content {
        padding: 0 !important;
        justify-content: ${props => !props.viewModelBooks && 'center !important'}
    }
    .ant-card-bordered {
        border: none !important;
    }
`;

const LockContainer = styled(FlexContainer)`
  min-height: 320px;
  padding: 16px 0;
  svg {
    font-size: ${props => props.$readOnly ? '80px' : '120px'};
  }
`;

const BookFilters = styled.div`
  border-bottom: ${props => props.theme.DefaultBorder};
  padding-bottom: 16px;
`;


const ModelDetailsBooks = ({
    addedPlaylists,
    contactID,
    drawerMode = false,
    history,
    isDrawer,
    isInCardTab,
    modelPlaylists,
    onAddBook,
    onAddNewBook,
    onRemoveBook,
    onSelectBook,
    packageEditView,
    readOnly,
    refreshing,
    spinning,
}) => {
    const itemsPerPage = 4
    const { data: fetchedPlaylists, isFetching: isFetchingPlaylists } = useContactPlaylists(contactID, modelPlaylists)
    const { data: websiteData } = useWebsites();
    const [activePage, setActivePage] = useState(1)
    const [bookPreview, setBookPreview] = useState(null);
    const [bookCover, setBookCover] = useState(null);
    const [pictureToCrop, setPictureToCrop] = useState(null);
    const [bookInfo, setBookInfo] = useState(null);
    const [isBookDrawer, setIsBookDrawer] = useState(false);
    const [isPublishDrawer, setIsPublishDrawer] = useState(false);
    const [dataforPublishDrawer, setDataforPublishDrawer] = useState(false);
    // const [selectedRating, setSelectedRating] = useState();
    const selectedRating = undefined;
    const [filterTags, setFilterTags] = useState([]);
    const [onlyMine, setOnlyMine] = useState(false);
    const { currentUser } = useContext(UserContext);
    const [playlists, setPlaylists] = useState(null);
    const [{ areaContext }] = useStateValue();
    const [editBookDrawer, setEditBookDrawer] = useState(null)

    const [isBookTouched, setIsBookTouched] = useState(false)


    //TODO: RIPRISTINARE CONTROLLO  SENZA CARICARE MODELLA
    //const viewModelBooks = useUserPermission('ViewModelBooks', currentContact && currentContact.Users.map(user => user.UserID));

    const viewModelBooks = true
    const breakpoints = useNwBreakPoints();

    const handlePageChange = (page) => {
        setActivePage(page)
    }

    const getModelFilteredPlaylists = (playlists) => {
        if (!selectedRating) {
            return playlists;
        } else {
            const filteredPlaylists = [];
            let ratingType = null,
                ratingValue = null;

            if (selectedRating) {
                const rating = selectedRating.split("-");
                if (_.isArray(rating) && rating.length === 2) {
                    ratingType = rating[0];
                    ratingValue = parseInt(rating[1]);
                }
            }
            playlists.forEach(playlist => {
                if (ratingType && ratingValue) {
                    playlist.Rating[ratingType] >= ratingValue && filteredPlaylists.push(playlist);
                }
            });
            return filteredPlaylists;
        }
    };

    useEffect(() => {
        if (modelPlaylists) {
            setPlaylists([...modelPlaylists])
        } else {
            if (!isFetchingPlaylists) {
                setPlaylists([...fetchedPlaylists])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingPlaylists, modelPlaylists])

    const { displayedBooks } = useMemo(() => {
        let filteredBooks = [];
        let displayedBooks = [];
        if (playlists) {
            filteredBooks = getModelFilteredPlaylists(playlists);
            displayedBooks = filteredBooks.filter(book => {
                let filtered = true;
                filterTags.map(filterTag => {
                    if (!book.PlaylistTags.find(btag => btag === filterTag)) {
                        filtered = false;
                    }
                    return null;
                });
                if (filtered) {
                    return true;
                }
                return false;
            });
            displayedBooks = displayedBooks.filter(book => (!onlyMine || book.UserCreated === currentUser.UserID));
            //if (drawerMode) {
            displayedBooks = _.orderBy(displayedBooks, ['FastPackage', 'IsPublished', 'ID'], ['desc', 'desc', 'desc'])
            //}
        }
        return { displayedBooks };
    }, [playlists, filterTags, selectedRating, onlyMine, currentUser]);

    const tags = useMemo(() => {
        let tags = [];
        for (const book of displayedBooks) {
            for (const tag of book.PlaylistTags) {
                if (!tags.find(item => item === tag)) {
                    tags.push(tag);
                }
            }
        }
        return tags;
    }, [displayedBooks]);

    const booksToRender = (drawerMode || packageEditView || breakpoints === 1) ? displayedBooks : sliceData(activePage, itemsPerPage, displayedBooks);

    const showBookPreview = (book) => {
        setBookPreview(book);
        setIsBookDrawer(true);
    }

    const showCoverSelector = (book) => {
        setBookCover(book)
    }

    const removeCover = async (book) => {
        await Axios.delete(`/playlists/removecover/${book.ID}/standard`)
        await forceReloadContact(contactID);
    }


    const showBookInfo = (book) => {
        setBookInfo(book);
        setIsBookDrawer(true);
    }

    const publishBook = (book) => {
        if (websiteData && websiteData.length > 0) {
            setDataforPublishDrawer(book)
            setIsPublishDrawer(true)
        } else {
            notification.warning({
                message: 'No Websites Configured',
                description: 'There are no websites configured in the management section. Sorry, this book can\'t be published',
                icon: <NwIcon icon={solid("triangle-exclamation")} />
            });
        }
    }

    const onEditBook = book => {
        if (onSelectBook) {
            onSelectBook(book);
        }
        else
            setEditBookDrawer(book.ID)
    }

    const onDeleteBook = async book => {
        try {
            await Axios.delete(`/playlists/${book.ID}`);
            onUpdatePlaylists(contactID);
        } catch (error) {
            console.log("error on deleting book", error);
        }
    }

    const onChangeFastPackageStatus = async (book, value) => {
        try {
            const data = { PlaylistID: book.ID, FastPackage: value }
            await Axios.post("/playlists/setFastPackage", data);
            onUpdatePlaylists(contactID);
        } catch (error) {
            console.log("error on set fast package on book", error);
        }
    }

    const handleSetTags = (name, tags) => {
        setFilterTags(tags);
    };

    const goToBooksPage = () => {
        if (drawerMode) {
            console.log("drawer mode")
            onAddNewBook()
        } else {
            console.log("else")
            history.push({
                pathname: `/models/${contactID}/media`,
                state: { bookId: 'new' }
            })
        }
    }

    const onCloseEditBookDrawer = () => {
        setEditBookDrawer();
    }

    const goToMediaPage = () => {
        if (areaContext.area === 'booking') {
            history.push(`/models/${contactID}/media`);
        } else {
            history.push(`/${areaContext.area}-models/${contactID}/media`);
        }
    }

    const renderFilters = () => {
        return (
            breakpoints === 1 ?
                <FlexContainer column fullWidth>
                    {!packageEditView && !readOnly &&
                        <>
                            <FlexContainer mg='0 0 8px 0'>
                                <FilterTagSelector selectedOptions={filterTags} tags={tags} onChange={handleSetTags} />
                            </FlexContainer>
                        </>
                    }
                    <CustomSwitch checked={onlyMine} onChange={setOnlyMine} text='only mine' />
                </FlexContainer>
                :
                <FlexContainer fullWidth justifyContent='space-between'>
                    <FlexContainer>
                        <FilterTagSelector selectedOptions={filterTags} tags={tags} onChange={handleSetTags} />
                    </FlexContainer>
                    <CustomSwitch checked={onlyMine} onChange={setOnlyMine} text='only mine' />
                </FlexContainer>
        )
    };

    const getActiveFilters = () => {
        let filters = [];
        if (selectedRating) {
            const rating = selectedRating.split("-");
            if (_.isArray(rating) && rating.length === 2) {
                const ratingValue = parseInt(rating[1]);
                filters.push(`Rating > ${ratingValue}`);
            }
        }
        if (filterTags.length) {
            for (const tag of filterTags) {
                filters.push(tag);
            }
        }
        if (onlyMine) {
            filters.push('Mine only');
        }
        return filters;
    }

    const handleSelectPictureForCover = (picture) => {
        setPictureToCrop(picture.src)
    }

    const handleClosePlaylistCoverGenerator = () => {
        setPictureToCrop(null)
        setBookCover(null)
    }

    const cardContent = () => (
        <>
            {playlists.length > 0 ?
                <NwCardContent
                    pagination={(drawerMode || packageEditView || breakpoints === 1) ? null : { total: displayedBooks.length, current: activePage, pageSize: itemsPerPage, onChange: handlePageChange }}
                    padding="no"
                >
                    {(booksToRender && (booksToRender.length > 0)) ?
                        (isDrawer ?
                            displayedBooks.map((book, index) => (
                                <GridCard key={index}>
                                    <div>
                                        <BookItem
                                            addedPlaylists={addedPlaylists}
                                            book={book}
                                            isDrawer={isDrawer}
                                            onAddBook={onAddBook}
                                            onChangeFastPackageStatus={onChangeFastPackageStatus}
                                            onDeleteBook={onDeleteBook}
                                            onEditBook={onEditBook}
                                            onRemoveBook={onRemoveBook}
                                            packageEditView={packageEditView}
                                            readOnly={readOnly}
                                            showBookInfo={showBookInfo}
                                            showBookPreview={showBookPreview}
                                            showCoverSelector={showCoverSelector}
                                            removeCover={removeCover}
                                            showPublishBook={publishBook}
                                            spinning={spinning}
                                            websiteData={websiteData ? websiteData : []}
                                        />
                                    </div>
                                </GridCard>
                            ))
                            : <StyledList
                                itemLayout="horizontal"
                                $mobileView={breakpoints === 1}
                                dataSource={booksToRender}
                                renderItem={book => {
                                    return (
                                        <BookItem
                                            addedPlaylists={addedPlaylists}
                                            book={book}
                                            onAddBook={onAddBook}
                                            onChangeFastPackageStatus={onChangeFastPackageStatus}
                                            onDeleteBook={onDeleteBook}
                                            onEditBook={onEditBook}
                                            onRemoveBook={onRemoveBook}
                                            packageEditView={packageEditView}
                                            readOnly={readOnly}
                                            showBookInfo={showBookInfo}
                                            showBookPreview={showBookPreview}
                                            showCoverSelector={showCoverSelector}
                                            removeCover={removeCover}
                                            showPublishBook={publishBook}
                                            websiteData={websiteData ? websiteData : []}
                                        />
                                    )
                                }}
                            />)
                        :
                        <EmptyState message="no books" />
                    }
                </NwCardContent>
                : <EmptyState message="no books" />
            }
        </>
    )

    const renderCard = () => {
        if (isInCardTab) {
            return (
                <CardWrapper isInCardTab={isInCardTab} viewModelBooks={viewModelBooks}>
                    <NwCardTabsContent
                        filtersContent={!isDrawer && breakpoints !== 1 && viewModelBooks && renderFilters()}
                        activeFilters={!isDrawer && breakpoints !== 1 && viewModelBooks && getActiveFilters()}
                    >
                        {viewModelBooks ? cardContent() :
                            <LockContainer $readOnly={readOnly} justifyContent='center' alignItems='center'>
                                <NwIcon icon={light('lock-alt')} />
                            </LockContainer>
                        }
                    </NwCardTabsContent>
                </CardWrapper>
            )
        } else {
            return (
                <CardWrapper viewModelBooks={viewModelBooks}>
                    <NwCard
                        title={(!drawerMode && breakpoints !== 1) ? "Books" : ""}
                        titleAction={
                            <NwButton
                                minimal
                                icon={light('expand-wide')}
                                solid
                                size="small"
                                onClick={goToMediaPage} />
                        }
                        counter={viewModelBooks ? playlists.length : null}
                        icon={(!drawerMode && breakpoints !== 1) ? light("images") : null}
                        //minheight={580}
                        fullheight
                        filtersContent={breakpoints !== 1 && viewModelBooks && renderFilters()}
                        activeFilters={breakpoints !== 1 && viewModelBooks && getActiveFilters()}
                        extra={viewModelBooks && <NwAddButton size="small" primary={drawerMode ? true : false} ghost={!drawerMode ? true : false} onClick={goToBooksPage} label="new book" />}
                    >
                        {viewModelBooks ? cardContent() :
                            <LockContainer $readOnly={readOnly} justifyContent='center' alignItems='center'>
                                <NwIcon icon={light('lock-alt')} />
                            </LockContainer>
                        }
                    </NwCard>
                </CardWrapper>
            )
        }
    };

    return (
        (playlists && !refreshing) ?
            <>
                {(!isDrawer && breakpoints === 1) &&
                    <BookFilters>
                        {renderFilters()}
                    </BookFilters>
                }
                {renderCard()}
                {isPublishDrawer && dataforPublishDrawer && websiteData &&
                    <ModelBookPublishDrawer
                        onClose={() => {
                            setIsPublishDrawer(false);
                            setDataforPublishDrawer();
                        }}
                        book={dataforPublishDrawer}
                        websiteData={websiteData}
                        books={booksToRender}
                    />
                }
                {isBookDrawer && bookPreview &&
                    <PlayListPreviewDrawer
                        playlist={bookPreview}
                        contactID={contactID}
                        onClose={() => {
                            setIsBookDrawer(false);
                            setBookPreview();
                        }}
                    />
                }
                {isBookDrawer && bookInfo &&
                    <PlayListInfoDrawer
                        playlist={bookInfo}
                        contactID={contactID}
                        onEditPlaylist={onEditBook}
                        onClose={() => {
                            setIsBookDrawer(false);
                            setBookInfo();
                        }}
                        onUpdatePlaylists={() => {
                            onUpdatePlaylists(contactID);
                        }}
                        onDeletePlaylists={onDeleteBook}
                        onPublishBook={publishBook}
                    />
                }
                {bookCover &&
                    (pictureToCrop
                        ?
                        <PlaylistCoverGenerator
                            playlistID={bookCover.ID}
                            contactID={contactID}
                            imageUrl={pictureToCrop}
                            onClose={handleClosePlaylistCoverGenerator}
                            coverFormatCode='Standard'
                        />
                        :
                        <SelectPictureDrawer modelID={contactID} onSelectPicture={handleSelectPictureForCover} onClose={() => setBookCover(false)} />
                    )
                }
                {editBookDrawer &&
                    <NwDrawer
                        width="90vw"
                        title='Edit Book'
                        padding="0"
                        onClose={() => {
                            if (isBookTouched)
                                confirm({
                                    title: <div>Discard changes and close the drawer?</div>,
                                    onOk() {
                                        onCloseEditBookDrawer()
                                    },
                                    onCancel() { },
                                });
                            else
                                onCloseEditBookDrawer()
                        }}
                    >
                        {/* <PlaylistEditor
                selectedModel={contactID}
                bookToEdit={editBookDrawer}
                onClose={() => onCloseEditBookDrawer()}
                onBookTouched={setIsBookTouched}
            /> */}

                        <ModelMedia
                            isDrawer
                            selectedModel={contactID}
                            bookToEdit={editBookDrawer}
                            onCloseBookEditor={() => onCloseEditBookDrawer()}
                            onBookTouched={setIsBookTouched}
                        />
                    </NwDrawer>
                }
            </>
            : <Loading textBlack />
    );
}

export default withRouter(ModelDetailsBooks);
