import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Alert } from 'antd';
import Axios from 'axios';
import { forceReloadProjectAccountingDetails } from 'Hooks/Project/UseProjectAccountingDetails';
import { forceReloadProjectTransactions } from 'Hooks/Project/UseProjectTransactions';
import { forceReloadProjectStatements } from 'Hooks/Project/UseProjectStatements';
import { forceReloadTransaction } from 'Hooks/Transaction/UseTransaction';
import { forceReloadContactStatements } from 'Hooks/Contact/UseContactStatements';
import { getContactBillingInfo } from 'Components/Project/ProjectTransactions/Transactions/TransactionCreateFormUtils';
import { Loading } from 'Components/Gui/Loading';
import NwIcon from 'Components/Gui/NwIcon';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NwButton } from 'Components/Gui/NwButton';
import ContactSelector from 'Components/ContactSelector/ContactSelector';
import NwDivider from 'Components/Gui/NwDivider';

const BillingInfoContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    padding: 1rem 1rem;
    border-bottom: ${props => props.theme.DefaultBorder};

    &.selected {
    
    }

    .billinginfo-container-icon {
        font-size: 2.5rem;
        padding: 0 1rem;
        opacity: .75;
    }
    .billinginfo-container-details {
        flex: 1 1 0;
        padding-left: 1rem;
        h2 {
            font-weight: 600;
            font-size: 1.15rem;
            margin: 0 0 .15rem 0;
            line-height: 1.2em;
            text-transform: uppercase;
        }
        p {
            font-size: 1rem;
            margin: 0 0 .15rem 0;
            line-height: 1em;
        }
    }
    .billinginfo-container-actions {
        min-width: 8rem;
        font-size: 1rem;
        text-transform: uppercase;
    }
`

const TransactionContact = ({
    contactID,
    contactType,
    billingInfoID,
    onChangeTransactionContact,
    transaction,
    projectID,
    transactionIDs,
    transactionFamily
}) => {  //onChangeForm

    //const ContactToBill = { ContactID: clientID, ContactBillingInfoID: billingInfoID }

    console.log("transaction", transaction)
    console.log("contactID", contactID)
    console.log("contactType", contactType)
    console.log("transactionIDs", transactionIDs)

    const [contactBillingInfos, setContactBillingInfos] = useState([])
    const [selectedContactID, setSelectedContactID] = useState(contactID ? contactID : null)
    const [selectedBillingInfoID, setSelectedBillingInfoID] = useState();
    const [loadingBillingInfo, setLoadingBillingInfo] = useState(false)
    const [errorMessage,  setErrorMessage] = useState(null)

    const getSelectedBillingInfo = () => {
        const selectedBillingInfo = contactBillingInfos.find(item => (item.ID === billingInfoID))
        return selectedBillingInfo
    }

    useEffect(() => {
        const fetchBillingInfo = async (contactID) => {
            setLoadingBillingInfo(true)
            const fetchedBillingInfos = await getContactBillingInfo(contactID)
            setContactBillingInfos(fetchedBillingInfos)
            setLoadingBillingInfo(false)
        }
        if (selectedContactID) {
            // onChange(
            //     {
            //         ...value,
            //         ContactID: selectedContactID,
            //     }
            // )
            fetchBillingInfo(selectedContactID)
        }
    }, [selectedContactID])

    useEffect(() => {
        if (selectedBillingInfoID) {
            
        }
    }, [selectedBillingInfoID])

    useEffect(() => {
        if (contactBillingInfos && contactBillingInfos.length > 0) {
            if (contactID === selectedContactID) {
                const selectedBillingInfo = getSelectedBillingInfo()
                setSelectedBillingInfoID(selectedBillingInfo.ID)
            }
            // } else {
            //     const defaultBillingInfo = getDefaultBillingInfo()
            //     setSelectedBillingInfoID(defaultBillingInfo.ID)
            // }
        }
    }, [contactBillingInfos])



    const onChangeContact = (id) => {
        setSelectedContactID(id)
    }

    const changeClient = async (selectedBillingInfoID) => {
        let statementIds = []
        if (transaction) {
            if (contactType.toLowerCase() === 'agency') {
                if (!transaction.AgencyID) {
                    statementIds = transaction.AgencyStatements.filter(statement => statement.ContactID === contactID).map(statement => statement.ID)
                }
            }
        }
        
        let apiData = {
            ContactType: contactType,
            ContactID: selectedContactID,
            BillingInfoID: selectedBillingInfoID,
            TransactionIDs: [...transactionIDs],
            StatementIDs: statementIds
        }

        try {
            await Axios.put(`/transactions/${transactionFamily}/modifyContact`, apiData);
            if (transaction) {
                forceReloadTransaction(transaction.ID)
                if (transaction.ProjectID) {
                    forceReloadProjectTransactions(transaction.ProjectID)
                    forceReloadProjectAccountingDetails(transaction.ProjectID)
                    forceReloadProjectStatements(transaction.ProjectID)
                }
            }
            if (projectID) {
                forceReloadProjectTransactions(projectID)
                forceReloadProjectAccountingDetails(projectID)
                forceReloadProjectStatements(projectID)
            }
            forceReloadContactStatements(contactID)
            if (selectedContactID !== contactID) {
                forceReloadContactStatements(selectedContactID)
            }
            onChangeTransactionContact()
        } catch (error) {
            if (error.errorCode === "TransactionLocked") {
                setErrorMessage("You can't change the client on some of these transactions because they have already been included in an invoice or a payment.")
            }
        }
    };

    const handleSelectBillingInfo = (billingInfo) => {
        changeClient(billingInfo.ID)
    }

    const BillingInfoSelector = ({ data, editable, onClick, selected }) => {
        return (
            <BillingInfoContainer className={`billinginfo-container${selected ? ' selected' : ''}`}>
                <div className="billinginfo-container-icon">
                    <NwIcon icon={light("file-invoice-dollar")} />
                </div>
                <div className="billinginfo-container-details">
                    <h2>{data.CompanyName}</h2>
                    <p>invoice type: {data.InvoiceType}</p>
                    <p>{data.City}, {data.Country}</p>
                    {data.VatNumber && <p>VAT#: {data.VatNumber}</p>}
                    {data.FiscalID && <p>Fiscal ID: {data.FiscalID}</p>}
                    {data.isDefault && <p><NwIcon icon={solid("bookmark")} /> default billing profile</p>}
                </div>
                {editable &&
                    <div className="billinginfo-container-actions">
                        <p>
                            {selected
                            ?
                                <span><NwIcon icon={light("check")} /> selected</span>
                            :
                                <NwButton primary label="Select" onClick={onClick} />
                            }
                        </p>
                    </div>
                }
            </BillingInfoContainer>
        );
    };

    return (
        <div>
            {errorMessage &&
                <Alert style={{margin: "2rem 0"}} message={errorMessage} type="error" showIcon />
            }
            {(contactType.toLowerCase() === 'client') &&
                <>
                    <ContactSelector
                        value={selectedContactID}
                        activeTypes={['cli', 'age', 'srv']}
                        onChange={onChangeContact}
                        //topListContacts={contacts}
                    />
                    <br/><br/>
                </>
            }
            
            <NwDivider>Billing profiles</NwDivider>
            {loadingBillingInfo 
            ?
                <Loading />
            :
                (contactBillingInfos.map(billingInfo => (
                    <BillingInfoSelector 
                        selected={billingInfo.ID === selectedBillingInfoID}
                        key={billingInfo.ID}
                        data={billingInfo}
                        editable={true}
                        buttonLabel="select"
                        onClick={() => handleSelectBillingInfo(billingInfo)} 
                    />
                )))
            }
        </div>
    )
};

export default TransactionContact;