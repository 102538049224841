import React, { useState, useEffect, useContext, Fragment } from 'react';
import styled from 'styled-components';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Card } from 'antd';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

import { NwButton, NwEditButton, NwRemoveButton, NwAddButton } from 'Components/Gui/NwButton';
import NwDrawer from 'Components/Gui/NwDrawer';
import TagSelector from 'Components/TagSelector';
import MultiMediaInfoForm from 'Components/ModelMedia/MultiMediaActions/MultiMediaInfoForm';
import FlexContainer from 'Components/Gui/FlexContainer';
import { forceReloadContactDocuments, useDeleteMediaList } from 'Hooks/Contact/UseContactMedia';
import { UserContext } from 'Contexts/UserContext';
import Axios from 'axios';
import { forceReloadContact } from 'Hooks/Contact/UseContact';

const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;

    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 16px 24px;
    box-sizing: border-box;
    z-index: 4;
    width: 100vw;
    font-weight: bold;
    text-transform: uppercase;
`;

const AddTagContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const RemoveTagContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const MultiMediaActions = ({
    contactID,
    mediaList,
    onClearSelection,
    onDeleteErrors,
    onOpenBookList,
    onSelectAll,
}) => {
    const [addTag, setAddTag] = useState();
    const [isShowTagDrawer, setIsShowTagDrawer] = useState(false);
    const [isShowInfoDrawer, setIsShowInfoDrawer] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const { mutateAsync: onDeleteMediaList } = useDeleteMediaList();
    const [touchForm, setTouchForm] = useState(false);
    const { currentUser } = useContext(UserContext);
    const userPolicies = currentUser.UserProfile.Policies;
    const deletePicturesPermission = userPolicies && userPolicies.find(policy => policy.Name === 'DeleteMedia').LevelName;
    
    useEffect(() => {
        const updatedTags = [];
        mediaList.map(media => {
            media.Tags.map(tag => {
                const findIndex = updatedTags.findIndex(item => item.ID === tag.ID);
                if (findIndex === -1) {
                    updatedTags.push(tag);
                }
                return null;
            });
            return null;
        });
        setTags(updatedTags);
    }, [mediaList]);

    const handleDeleteMultiMedia = async () => {
        const documentIDs = mediaList.map(media => media.ID);
        try {
            await onDeleteMediaList({ documentIDs, contactID: contactID });
            forceReloadContactDocuments(contactID)
        } catch (error) {
            onDeleteErrors(error.data)
            forceReloadContactDocuments(contactID)
        }
    };

    const handleOpenTagDrawer = () => {
        setIsShowTagDrawer(true);
    };

    const handleCloseTagDrawer = () => {
        setIsShowTagDrawer(false);
    };

    const handleOpenInfoDrawer = () => {
        setIsShowInfoDrawer(true);
    };

    const handleCloseInfoDrawer = () => {
        setIsShowInfoDrawer(false);
    }

    const handleChangeTag = tag => {
        setAddTag(tag)
    }

    const handleAddTag = async () => {
        setAddLoading(true)
        const documentIDs = mediaList.map(media => media.ID)
        try {
            await Axios.post(`/documents/addtagmulti?tagID=${addTag}&contactID=${contactID}`, documentIDs)
            setAddLoading(false)
            handleCloseTagDrawer()
        } catch (error) {
            console.log('ant: Add multi tag error => ', error)
            setAddLoading(false)
            handleCloseTagDrawer()
        }
    }

    const handleRemoveTag = tagID => async () => {
        setAddLoading(true)
        const documentIDs = mediaList.map(media => media.ID);
        try {
            await Axios.post(`/documents/removetagmulti?tagID=${tagID}&contactID=${contactID}`, documentIDs)
            setAddLoading(false)
            handleCloseTagDrawer()
        } catch (error) {
            console.log('ant : Remove multi tag error => ', error)
            setAddLoading(false)
            handleCloseTagDrawer()
        }
    }

    const getIsMediaListRemovable = () => {
        switch (deletePicturesPermission) {
            case "All":
                return true;
            case "User":
                if (mediaList.find(media => media.UserCreated !== currentUser.UserID)) {
                    return false;
                }
                return true;
            case 0:
            default:
                return false;
        }
    };

    const isMediaListRemovable = getIsMediaListRemovable();

    return (
        <ActionContainer>
            <FlexContainer>
                <div>{mediaList.length} Media selected</div>&nbsp;
                <FlexContainer mg='0 0 0 16px'>
                    <NwButton ghost icon={light('check-double')} label='select all' onClick={onSelectAll} />
                    &nbsp;
                    <NwButton ghost icon={light('times')} label='clear selection' onClick={onClearSelection} />
                </FlexContainer>
            </FlexContainer>
            {mediaList.length > 0 &&
                <FlexContainer>
                    <NwAddButton ghost size='small' onClick={() => onOpenBookList()} label='Create a book' />
                    &nbsp;
                    <NwEditButton ghost size='small' label='tags' onClick={handleOpenTagDrawer} />
                    &nbsp;
                    <NwEditButton ghost size='small' label='info' onClick={handleOpenInfoDrawer} />
                    {isMediaListRemovable &&
                        <>
                            &nbsp;
                            <NwRemoveButton confirmationPlacement="topRight" ghost size="small" onClick={handleDeleteMultiMedia} />
                        </>
                    }
                </FlexContainer>
            }
            {isShowTagDrawer &&
                <NwDrawer title={`${mediaList.length} Media`} onClose={handleCloseTagDrawer}>
                    <Card title="Add Tag" size="small">
                        <AddTagContainer>
                            <TagSelector tagMode='item' value={addTag} itemType="Document" onChange={handleChangeTag} />
                            &nbsp;
                            <NwButton
                                primary
                                label='Add tag'
                                onClick={handleAddTag}
                                loading={addLoading}
                                loadingLabel='Adding tag' />
                        </AddTagContainer>
                    </Card>
                    &nbsp;
                    <Card title="Remove Tags" size="small">
                        <RemoveTagContainer>
                            {tags.map(tag => (
                                <Fragment key={`media-tag-${tag.Name}`}>
                                    <Button size="small" onClick={handleRemoveTag(tag.ID)}>
                                        {tag.Name}
                                        <LegacyIcon type="close" />
                                    </Button>
                                    &nbsp;
                                </Fragment>
                            ))}
                        </RemoveTagContainer>
                    </Card>
                </NwDrawer>
            }
            {isShowInfoDrawer &&
                <NwDrawer
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title={`${mediaList.length} Media`}
                    onClose={handleCloseInfoDrawer}>
                    <MultiMediaInfoForm
                        onTouchForm={setTouchForm}
                        mediaList={mediaList}
                        contactID={contactID} />
                </NwDrawer>
            }
        </ActionContainer>
    );
};

export default MultiMediaActions;