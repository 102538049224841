import React, { useState, useContext, useEffect, Suspense } from 'react';
import { Route, Switch } from "react-router-dom";
import ls from 'local-storage';

//not used
//import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import 'react-vis/dist/style.css';
import { ConfigProvider } from 'antd';
import Axios from 'axios';
import 'moment/locale/en-nz';
import 'moment/locale/en-gb';
import en_GB from 'antd/lib/locale-provider/en_GB';
//import en_US from 'antd/lib/locale-provider/en_US';

import NwLayout from 'Components/_LayoutComponents/Layout/NwLayout';
import DocumentLayout from 'Components/_LayoutComponents/Layout/DocumentLayout';
import { RouteLoading } from "Components/Gui/Loading";
import PrivateRoute from 'Components/PrivateRoute/PrivateRoute';
import { UserContext } from 'Contexts/UserContext';
import { ListContext } from 'Contexts/ListContext';
//import { BreakpointProvider } from 'Contexts/BreakpointContext'; //REMOVED - TESTING NEW BREAKPOINTS

import { isDocumentPage } from 'Libs/NwUtils';
import DocEditor from 'Containers/Booking/DocEditor/DocEditor';
import './App.less';
import Interceptor from 'Libs/Interceptors';
import { prefetchModels, prefetchCustomers, prefetchContacts as prefetchContactList, prefetchPlaylistsCount } from 'Hooks/Contact/UseContactsList';
import { prefetchActivePackages } from 'Hooks/Packages/UsePackages';
import { prefetchFeatures, prefetchFeatureSets, prefetchConversions } from 'Hooks/Features/UseFeatures';
import { prefetchRoomConnections } from 'Hooks/Contact/UseContactConnections';
import { ConfigContext } from 'Contexts/ConfigContext';
import { useStateValue } from 'Contexts/StateProvider';

import { ThemeProvider } from 'styled-components';
import StyleVariables from 'Components/Gui/StyleVariables';
import QueryClientProvider from 'QueryClientProvider';
import constants from 'Constants';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5adkZiWH9bdHFTRGlf');

//const AddBookingEvent = React.lazy(() => import('Containers/Booking/AddBookingEvent'));
const AccountingAdmin = React.lazy(() => import('Containers/Accounting/Admin'));
const AccountingCustomers = React.lazy(() => import('Containers/Accounting/Customers'));
const AccountingDashboard = React.lazy(() => import('Containers/Accounting/Dashboard'));
const AccountingInvoices = React.lazy(() => import('Containers/Accounting/Invoices'));
const AccountingModels = React.lazy(() => import('Containers/Accounting/Models'));
const ActivePackages = React.lazy(() => import('Containers/Booking/Packages/ActivePackages'));
const AddConnectionsToMailingList = React.lazy(() => import('Components/MailingLists/AdvancedSearch'));
const AddModelsToEventDate = React.lazy(() => import('Containers/Booking/AddModelsToEventDate'));
const AgenciesDepartments = React.lazy(() => import('Containers/Management/AgenciesDepartments'));
const ArtDashboard = React.lazy(() => import('Containers/Art/Dashboard'));
const ArtModels = React.lazy(() => import('Containers/Art/Models'));
const BillingCommissions = React.lazy(() => import('Containers/Accounting/Billing/Commissions/BillingCommissions'));
const BillingExpenses = React.lazy(() => import('Containers/Accounting/Billing/Expenses/BillingExpenses'));
const BillingProjects = React.lazy(() => import('Containers/Accounting/Billing/Projects/BillingProjects'));
const BookingCustomers = React.lazy(() => import('Containers/Booking/Customers'));
const BookingDashboard = React.lazy(() => import('Containers/Booking/Dashboard'));
const BookingModels = React.lazy(() => import('Containers/Booking/Models'));
const Bookings = React.lazy(() => import('Containers/Booking/Bookings'));
const Configuration = React.lazy(() => import('Containers/Management/Configuration'));
const CustomersArchive = React.lazy(() => import('Containers/ContactsArchive/CustomersArchive'));
const ForgotPassword = React.lazy(() => import('Containers/Authentication/ForgotPassword'));
const ImportData = React.lazy(() => import('Containers/Management/ImportData/ImportData'));
const Login = React.lazy(() => import('Containers/Authentication/Login'));
const MailingLists = React.lazy(() => import('Components/MailingLists'));
const MailReport = React.lazy(() => import('Containers/Management/MailReport'));
const ManageFolders = React.lazy(() => import('Containers/Booking/ManageFolders'));
const ManagementCustomers = React.lazy(() => import('Containers/Management/Customers'));
const ManagementDashboard = React.lazy(() => import('Containers/Management/Dashboard'));
const ManagementModels = React.lazy(() => import('Containers/Management/Models'));
const ManagementReports = React.lazy(() => import('Containers/Accounting/Reports'));
const ModelsArchive = React.lazy(() => import('Containers/ContactsArchive/ModelsArchive'));
const ModelsLegalSearch = React.lazy(() => import('Containers/Booking/ModelsLegalSearch/ModelsLegalSearch'));
const Packages = React.lazy(() => import('Containers/Booking/Packages/Packages'));
const ResetPassword = React.lazy(() => import('Containers/Authentication/ResetPassword'));
const Rooms = React.lazy(() => import('Containers/Booking/Rooms'));
const Schedule = React.lazy(() => import('Containers/Booking/Schedule/Schedule'));
const ScoutingCustomers = React.lazy(() => import('Containers/Scouting/Customers'));
const ScoutingDashboard = React.lazy(() => import('Containers/Scouting/Dashboard'));
const ScoutingModels = React.lazy(() => import('Containers/Scouting/Models'));
const SearchEvents = React.lazy(() => import('Containers/Booking/SearchEvents'));
const SearchScoutingMeetings = React.lazy(() => import('Containers/Scouting/ScoutingMeetings'));
const SearchTodos = React.lazy(() => import('Containers/Booking/SearchTasks'));
const TravelPlans = React.lazy(() => import('Containers/Scouting/TravelPlans'));
const User = React.lazy(() => import('Containers/User'));
const UserMailReport = React.lazy(() => import('Containers/User/UserMailReport'));
const UserManagement = React.lazy(() => import('Containers/Management/UserManagement'));
const UserPolicies = React.lazy(() => import('Containers/Management/UserPolicies'));
const WebsiteManagement = React.lazy(() => import('Containers/Management/WebsiteManagement'));
//const AccountingStatements = React.lazy(() => import('Containers/Accounting/Statements'));
const ImageEditorTest = React.lazy(() => import('Components/MediaDrawer/EditImageNew'));

const App = () => {
    const userContext = useContext(UserContext)
    const listContext = useContext(ListContext)

    const [isLoading, setIsLoading] = useState(false)

    const [prefetched, setPrefetched] = useState(false)
    const { userGuiConfig, updateUserConfig } = useContext(ConfigContext)

    const [{ areaContext }, dispatch] = useStateValue();
    
    useEffect(() => {
        if (prefetched) {
            listContext.LoadData().catch((error) => { console.log("ERROR", error) })
        }
    }, [prefetched])

    useEffect(() => {
        if (listContext.cachedListLoaded) {
            setIsLoading(false)
        }
    }, [listContext.cachedListLoaded]);

    const getUserClientConfigurations = async conversions => {
        try {
            const userGuiConfigurations = await Axios.get('/userclientconfigurations');
            if (userGuiConfigurations && userGuiConfigurations.length > 0) {
                const foldersData = userGuiConfigurations.find(configuration => configuration.Name === 'folders');
                if (foldersData && foldersData.JsonData.openFolders.length > 0) {
                    dispatch({
                        type: 'updateFolders',
                        openFolders: foldersData ? [...foldersData.JsonData.openFolders] : []
                    });
                }
                
                const settingData = userGuiConfigurations.find(configuration => configuration.Name === 'settings');

                if (settingData && settingData.JsonData && settingData.JsonData.settings) {
                    var savedSettings = { ...settingData.JsonData.settings }
                    updateUserConfig(savedSettings)
                } else {
                    updateUserConfig({
                        ...userGuiConfig,
                        metrics: conversions ? conversions.map(conversion => {
                            return {
                                ...conversion,
                                name: conversion.Name,
                                metrics: conversion.AvailableMetrics.split(',')
                            }
                        }) : []
                    })
                }

                const alertsData = userGuiConfigurations.find(configuration => configuration.Name === 'alerts');
                if (alertsData) {
                    for (const area of constants.AREAS) {
                        ls.set(`nw_config_${area}-read-alerts`, alertsData.JsonData[area] || []);
                    }
                }

                const modelSmartListData = userGuiConfigurations.find(configuration => configuration.Name === 'model-smart-list');
                if (modelSmartListData) {
                    dispatch({
                        type: "ModelSaveCustomSets",
                        customSets: modelSmartListData.JsonData.smartList
                    });
                }

                const eventSmartListData = userGuiConfigurations.find(configuration => configuration.Name === 'event-smart-list');
                if (eventSmartListData) {
                    dispatch({
                        type: "EventSaveCustomSets",
                        customSets: eventSmartListData.JsonData.smartList
                    });
                }

                const jobSmartListData = userGuiConfigurations.find(configuration => configuration.Name === 'job-smart-list');
                if (jobSmartListData) {
                    dispatch({
                        type: "JobSaveCustomSets",
                        customSets: jobSmartListData.JsonData.smartList
                    });
                }

                const customermartListData = userGuiConfigurations.find(configuration => configuration.Name === 'customer-smart-list');
                if (customermartListData) {
                    dispatch({
                        type: "CustomerSaveSets",
                        customSets: customermartListData.JsonData.smartList
                    });
                }

                const travelPlanSmartListData = userGuiConfigurations.find(configuration => configuration.Name === 'travelplan-smart-list');
                if (travelPlanSmartListData) {
                    dispatch({
                        type: "TravelPlanSaveCustomSets",
                        customSets: travelPlanSmartListData.JsonData.smartList
                    });
                }
            } else {
                updateUserConfig(
                    {
                        ...userGuiConfig,
                        metrics: conversions ? conversions.map(conversion => {
                            return {
                                ...conversion,
                                name: conversion.Name,
                                metrics: conversion.AvailableMetrics.split(',')
                            }
                        }) : []
                    }
                );
            }
        } catch (error) {   
            console.log('ant : Get User Client Configurations Error => ', error);
        }
    };

    useEffect(() => {
        const prefetchContacts = async () => {
            setIsLoading(true);

            if (!prefetched) {
                await prefetchModels();
                await prefetchPlaylistsCount();
                await prefetchContactList();
                await prefetchActivePackages(userContext.currentUser.UserID);
                await prefetchCustomers();
                await prefetchFeatures();
                await prefetchFeatureSets();
                await prefetchRoomConnections();
                const conversions = await prefetchConversions();
                
                getUserClientConfigurations(conversions); 
                setPrefetched(true);
            }
        }

        if (userContext.currentUser) {
            prefetchContacts();
        } else {
            if (prefetched) {
                setPrefetched(false);
            }
        }
    }, [userContext.currentUser])
    
    return (
        <QueryClientProvider>
            {/* locale={userGuiConfig.calendar.weekFormat === 'monday' ? en_GB : en_US} */}
            <ConfigProvider locale={en_GB}>
                <ThemeProvider theme={StyleVariables}>
                    {/* <BreakpointProvider> */}
                        <Interceptor />
                        {isDocumentPage() ? (
                            isLoading && !listContext.cachedListLoaded ?
                                <DocumentLayout>
                                    <RouteLoading />
                                </DocumentLayout>
                                : <PrivateRoute path="/document/:documentId/objectId/:objectId" component={DocEditor} />
                        ) : 
                        ((isLoading && !listContext.cachedListLoaded) || areaContext.areaContactsLoading )?
                            <NwLayout>
                                <RouteLoading />
                            </NwLayout>
                            :
                            <div>
                                <NwLayout>
                                    <Suspense fallback={<RouteLoading />}>
                                        <Switch>
                                            <PrivateRoute path="/models" component={BookingModels} />
                                            <PrivateRoute exact path="/" component={BookingDashboard} />
                                            <PrivateRoute path="/dashboard" component={BookingDashboard} />
                                            <PrivateRoute path="/mailing-lists" component={MailingLists} />
                                            <PrivateRoute path="/user" component={User} />
                                            <PrivateRoute path="/bookings" component={Bookings} />
                                            <PrivateRoute path="/billing-projects" component={BillingProjects} />
                                            <PrivateRoute path="/billing-expenses" component={BillingExpenses} />
                                            <PrivateRoute path="/billing-commissions" component={BillingCommissions} />
                                            {/* <PrivateRoute path="/accounting-statements" component={AccountingStatements} /> */}
                                            <PrivateRoute path="/packages" component={Packages} />
                                            <PrivateRoute path="/active-packages" component={ActivePackages} />
                                            <PrivateRoute path="/rooms" component={Rooms} />
                                            <PrivateRoute path="/schedule" component={Schedule} />
                                            <PrivateRoute path="/manage-folders" component={ManageFolders} />
                                            <PrivateRoute path="/contacts" component={BookingCustomers} />
                                            <Route path="/login" component={Login} />
                                            <Route path="/forgot-password" component={ForgotPassword} />
                                            <Route path="/reset-password" component={ResetPassword} />
                                            {/* <PrivateRoute path="/addBookingEvent" component={AddBookingEvent} /> */}
                                            <PrivateRoute path="/add-models-eventdate" component={AddModelsToEventDate} />
                                            <PrivateRoute path="/addconnectionstomailinglist" component={AddConnectionsToMailingList} />
                                            <PrivateRoute exact path="/scouting" component={ScoutingDashboard} />
                                            <PrivateRoute path="/scouting-dashboard" component={ScoutingDashboard} />
                                            <PrivateRoute path="/scouting-models" component={ScoutingModels} />
                                            <PrivateRoute path="/scouting-contacts" component={ScoutingCustomers} />
                                            <PrivateRoute exact path="/accounting" component={AccountingDashboard} />
                                            <PrivateRoute path="/accounting-dashboard" component={AccountingDashboard} />
                                            <PrivateRoute path="/accounting-models" component={AccountingModels} />
                                            <PrivateRoute path="/accounting-invoices" component={AccountingInvoices} />
                                            <PrivateRoute path="/accounting-contacts" component={AccountingCustomers} />
                                            <PrivateRoute path="/accounting/admin" component={AccountingAdmin} />
                                            <PrivateRoute exact path="/art" component={ArtDashboard} />
                                            <PrivateRoute path="/art-dashboard" component={ArtDashboard} />
                                            <PrivateRoute path="/art-models" component={ArtModels} />
                                            <PrivateRoute exact path="/management" component={ManagementDashboard} />
                                            <PrivateRoute path="/management-dashboard" component={ManagementDashboard} />
                                            <PrivateRoute path="/management-models" component={ManagementModels} />
                                            <PrivateRoute path="/management-contacts" component={ManagementCustomers} />
                                            <PrivateRoute path="/management-reports" component={ManagementReports} />
                                            <PrivateRoute path="/management-website" component={WebsiteManagement} />
                                            <PrivateRoute path="/events-search" component={SearchEvents} />
                                            <PrivateRoute path="/search-todos" component={SearchTodos} />
                                            <PrivateRoute path="/search-scouting-meetings" component={SearchScoutingMeetings} />
                                            <PrivateRoute path="/travel-plans" component={TravelPlans} />
                                            <PrivateRoute path="/customers-archive" component={CustomersArchive} />
                                            <PrivateRoute path="/models-archive" component={ModelsArchive} />
                                            <PrivateRoute path="/models-legal" component={ModelsLegalSearch} />
                                            <PrivateRoute path="/user-management" component={UserManagement} />
                                            <PrivateRoute path="/user-policies" component={UserPolicies} />
                                            <PrivateRoute path="/configuration" component={Configuration} />
                                            <PrivateRoute path="/import" component={ImportData} />
                                            <PrivateRoute path="/agencies-departments" component={AgenciesDepartments} />
                                            <PrivateRoute path="/mail-report" component={MailReport} />
                                            <PrivateRoute path="/user-mail-report" component={UserMailReport} />
                                            <PrivateRoute path="/image-editor-test" component={ImageEditorTest} />
                                        </Switch>
                                    </Suspense>
                                </NwLayout>
                            </div>}
                    {/* </BreakpointProvider> */}
                </ThemeProvider>
            </ConfigProvider>
        </QueryClientProvider>
    )
}
export default App;
