import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ModelMediaHeader from 'Components/ModelMedia/ModelMediaPanel/ModelMediaHeader';
import ModelMediaList from 'Components/ModelMedia/ModelMediaPanel/ModelMediaList/ModelMediaList';
import NwDrawer from 'Components/Gui/NwDrawer';
import ModelDetailsBooks from "Components/ContactDetails/ModelDetails/CommonComponents/ModelDetailsBooks";
import AddBookForm from 'Components/ModelMedia/ModelMediaPanel/ModelBooksDrawer/AddBookForm';

const ModelMediaPanel = ({ 
    bookToEdit,
    contactID,
    contactPlaylists,
    displayedDocuments,
    isDrawer,
    onBookTouched,
    onChangeUploadedSince,
    onChangeUploadedUntil,
    onChangeUploader,
    onClearFilters,
    onCloseBookEditor,
    onMediaUpdate,
    onUpdateShowMedia,
    onUpdateTags,
    packageBook,
    showMedia,
    tags,
    uploadedSince,
    uploadedUntil,
    uploader,
 }) => {
    const [sort, setSort] = useState('time');
    const [size, setSize] = useState('medium');
    const [isMediaSelectable, setIsMediaSelectable] = useState(false);
    const [isDrawerBookList, setDrawerBookList] = useState(false);
    const [selectedBook, setSelectedBook] = useState();
    const [mediaList, setMediaList] = useState([]); 
    const location = useLocation();
    const [touchForm, setTouchForm] = useState(false);
    const [createBook, setCreateBook] = useState(false);
    const [bookCreated, setBookCreated] = useState(false);
    
    useEffect(() => {
        if(location.state && location.state.bookId && location.state.bookId === "new" && !bookCreated) {
            setDrawerBookList(true);
            setCreateBook(true);
        }
        if (bookToEdit && contactPlaylists) {
            const book = contactPlaylists.find(playlist => playlist.ID === bookToEdit)
            setSelectedBook(book)
        }
        if (packageBook) {
            setSelectedBook(packageBook);
        }
    }, [contactPlaylists, packageBook, bookToEdit, bookCreated]);
    
    useEffect(() => {
        const updatedMediaList = displayedDocuments.map(document => {
            const media = mediaList.find(media => media.ID === document.ID);
            return {
                ...document,
                isSelected: (isMediaSelectable && media) ? media.isSelected : false
            };
        });
        setMediaList(updatedMediaList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMediaSelectable, displayedDocuments]);

    const handleUpdateSort = value => {
        setSort(value);
    };

    const handleUpdateSize = value => () => {
        setSize(value);
    };

    const handleChangeMediaSelectable = value => {
        if (!selectedBook) {
            setIsMediaSelectable(value);
        }
    }

    const handleCloseBooksDrawer = () => {
        setCreateBook(false)
        setDrawerBookList(false);
    };

    const handleSelectBook = book => {
        setSelectedBook(book);
        setIsMediaSelectable(false);
        setDrawerBookList(false);
    };

    const handleCancelBook = () => {
        setSelectedBook(null)
        setDrawerBookList(true)
    };

    const handleEditBook = () => {
        setDrawerBookList(true);
    };

    const handleOpenBookList = () => {
        setDrawerBookList(true)
    };

    const handleUpdateMediaList = updatedMediaList => {
        setMediaList(updatedMediaList);
    };

    const handleCloseCreateBook = () => {
        setCreateBook(false)
        setDrawerBookList(true)
    }

    const handleCreateBook = () => {
        setBookCreated(false)
        setCreateBook(true)
    }

    return (
        <div>
            <ModelMediaHeader
                books={contactPlaylists?contactPlaylists:[]}
                contactID={contactID}
                documents={displayedDocuments} 
                isDrawer={isDrawer}
                isMediaSelectable={isMediaSelectable}
                onChangeSize={handleUpdateSize}
                onChangeSort={handleUpdateSort}
                onChangeUploadedSince={onChangeUploadedSince}
                onChangeUploadedUntil={onChangeUploadedUntil}
                onChangeUploader={onChangeUploader}
                onClearFilters={onClearFilters}
                onOpenBookList={handleOpenBookList}
                onSelectMedia={handleChangeMediaSelectable}
                onUpdateShowMedia={onUpdateShowMedia}
                onUpdateTags={onUpdateTags}
                packageBook={packageBook}
                selectedBook={selectedBook}
                showMedia={showMedia}
                size={size}
                sort={sort} 
                tags={tags}
                uploadedSince={uploadedSince}
                uploadedUntil={uploadedUntil}
                uploader={uploader}
            />
            <ModelMediaList 
                isMediaSelectable={isMediaSelectable}
                sort={sort}
                size={size}
                contactID={contactID}
                selectedBook={selectedBook}
                mediaList={mediaList}
                onCancelBook={handleCancelBook} 
                onEditBook={handleEditBook}
                onMediaUpdate={onMediaUpdate}
                onUpdateMediaList={handleUpdateMediaList}
                onUpdateSelectedBook={book => setSelectedBook(book)}
                onOpenBookList={handleOpenBookList}
                packageBook={packageBook}
                onCloseBookEditor={onCloseBookEditor}
                isDrawer={packageBook || bookToEdit}
                onBookTouched={onBookTouched}
            />
            {isDrawerBookList &&
                <NwDrawer 
                    touchForm={touchForm}
                    onTouchForm={setTouchForm}
                    title={createBook?"Add new book" : `${contactPlaylists?contactPlaylists.length: 0} Books`} 
                    onClose={handleCloseBooksDrawer}
                    padding={0}
                >
                    {createBook ?
                        <div style={{padding: "1.6rem"}}>
                            <AddBookForm 
                                isAdd={true} 
                                selectedBook={selectedBook} 
                                mediaList={mediaList} 
                                contactID={contactID} 
                                onClose={handleCloseCreateBook} 
                                onSelectBook={handleSelectBook}
                                onTouchForm={setTouchForm} 
                                afterCreate={() => setBookCreated(true)}
                            />
                        </div>: 
                        <ModelDetailsBooks
                            contactID={contactID}
                            modelPlaylists={contactPlaylists}
                            onSelectBook={handleSelectBook}
                            onAddNewBook={handleCreateBook}
                            drawerMode={true}
                        />
                    }
                </NwDrawer>
            }
        </div>
    );
};

export default ModelMediaPanel;