import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography, Button, Select, Dropdown, Upload, Alert } from 'antd';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router-dom';
import NwButtonOption from "Components/Gui/NwButtonOption";
import NwUpload from 'Components/Gui/NwUpload';
import NwIcon from 'Components/Gui/NwIcon';
import FlexContainer from 'Components/Gui/FlexContainer';
import { onContactMediaUpdate } from 'Hooks/Contact/UseContactMedia';
import { getContactName } from 'Hooks/Contact/UseContactsList'
import { useStateValue } from 'Contexts/StateProvider';
import StyleVariables from 'Components/Gui/StyleVariables';
import CustomSwitch from 'Components/Gui/CustomSwitch';
import BookerSelector from 'Components/BookerSelector/BookerSelector';
import InputTag from 'Components/Gui/NWForm/InputTag';
import FilterDateInput from "Components/Filters/FilterDateInput";

const ButtonGroup = Button.Group;
const { Option } = Select;

const HeaderContainer = styled.div`
    margin: 0 0 0 16px;
    padding: 1em;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    font-size: .9rem;

    position: sticky;
    top: ${props => props.$isDrawer ? '1rem' : '80px'};
    z-index: 999;

    @media (max-width: 480px) {
        border-radius: 0;
    }
    @media only screen and (max-width: 960px) {
        flex-direction: column;
        position: relative;
        top: auto;
    }
    .info-block {
        display: flex;
        flex-direction: row;
        border-right: ${StyleVariables.DefaultBorder};
        padding-right: 20px;
        @media only screen and (max-width: 960px) {
            border-right: none;
        }
        .back-icon {
            font-size: 1.6rem;
            margin: auto 15px auto 0;
            cursor: pointer;
            user-select: none;
        }
        .model-info {
            .model-name {
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
    }
    .filter-block {
        @media only screen and (max-width: 960px) {
            margin: 10px 0;
            padding: 10px 0;
            border-top: ${StyleVariables.DefaultBorder};
            border-bottom: ${StyleVariables.DefaultBorder};
        }
    }
    .action-block {
        border-left: ${StyleVariables.DefaultBorder};
        padding: 0 20px;
        margin-left: auto;
        @media only screen and (max-width: 960px) {
            border-left: none;
            margin-left: 0;
        }
        .upload-icon {
            font-size: 1.3rem;
            cursor: pointer;
            margin-right: 30px;
        }   
        .book-icon {
            font-size: 1.3rem;
            cursor: pointer;
        }            
    }
`;

const FilterContainer = styled.div`
    margin: 0 0 0 16px;
    padding: 1em;
    background: #fff;
    border-radius: 6px;
    .filter-row {
        display: flex;
        flex-direction: row;
        .individual-filter {
            padding-right: 30px;
            width: 400px;
        }
    }
`;

const UploadContainer = styled.div`
    margin-left: 16px;
    margin-top: 16px;
    background: white;
    padding: 16px;
    border-radius: 8px;
`;

const Label = styled(Typography)`
    margin-right: 8px;
    min-width: 50px;
`;

const StyledAlert = styled(Alert)`
    margin-bottom: 8px;
`;

const ModelMediaHeader = ({
    books,
    contactID,
    documents,
    isDrawer,
    isMediaSelectable,
    onChangeSize,
    onChangeSort,
    onChangeUploadedSince,
    onChangeUploadedUntil,
    onChangeUploader,
    onClearFilters,
    onOpenBookList,
    onSelectMedia,
    onUpdateShowMedia,
    onUpdateTags,
    packageBook,
    selectedBook,
    showMedia,
    size,
    sort,
    tags,
    uploadedSince,
    uploadedUntil,
    uploader
}) => {
    const [{ areaContext }] = useStateValue();
    const history = useHistory();
    const [uploadingPictures, setUploadingPictures] = useState([]);
    const [invalidUploadingPictures, setInvalidUploadingPictures] = useState([]);
    // const [isStartUploadPictures, setStartUploadPictures] = useState(false);
    const [uploadingVideos, setUploadingVideos] = useState([]);
    const [invalidUploadingVideos, setInvalidUploadingVideos] = useState([]);
    // const [isStartUploadVideos, setStartUploadVideos] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    const afterUploadingPictures = () => { setUploadingPictures([]); /*setStartUploadPictures(false);*/ setInvalidUploadingPictures([]); }
    const afterUploadingVideos = () => { setUploadingVideos([]); /*setStartUploadVideos(false);*/ setInvalidUploadingVideos([]); }

    const handleChangeMediaSelectable = () => {
        if (isMediaSelectable) {
            onSelectMedia(false);
        } else {
            onSelectMedia(true);
        }
    };

    const showHideFilters = () => {
        onClearFilters()
        setShowFilters(!showFilters)
    }

    const photoCount = documents.filter(document => document.Type === 'Photo').length;
    const videoCount = documents.filter(document => document.Type === 'Video').length;

    const handleMoveBack = () => {
        if (areaContext.area === 'booking') {
            history.push(`/models/${contactID}`);
        } else {
            history.push(`/${areaContext.area}-models/${contactID}`);
        }
    };

    const handleUpdateShowMedia = (type, value) => {
        if (type === 'picture') {
            onUpdateShowMedia({
                ...showMedia,
                isShowPictures: value
            });
        } else if (type === 'video') {
            onUpdateShowMedia({
                ...showMedia,
                isShowVideos: value
            });
        } else {
            onUpdateShowMedia({
                ...showMedia,
                isShowDocuments: value
            });
        }
    };

    const menuItems = [
        {
            key: "Pictures",
            label: (
                <NwUpload
                    maxSize={50}
                    showList={false}
                    types={["image"]}
                    onUploadStart={(uploadInfo) => {
                        if (uploadInfo.event) {
                            setUploadingPictures(uploadInfo.fileList)
                        }
                    }}
                    onShowInvalidFiles={invalidFiles => setInvalidUploadingPictures(invalidFiles)}
                    onUploadEnd={() => {
                        onContactMediaUpdate(contactID, afterUploadingPictures);
                        onChangeSort('time');
                        onClearFilters();
                    }}
                    endpoint={`documents/uploadsingle/2?contactID=${contactID}`}
                >Pictures</NwUpload>
            )
        },
        {
            key: "Videos",
            label: (
                <NwUpload
                    maxSize={50}
                    showList={false}
                    types={["video"]}
                    onUploadStart={(uploadInfo) => {
                        if (uploadInfo.event) {
                            setUploadingVideos(uploadInfo.fileList)
                        }
                    }
                    }
                    onShowInvalidFiles={invalidFiles => setInvalidUploadingVideos(invalidFiles)}
                    onUploadEnd={() => {
                        onContactMediaUpdate(contactID, afterUploadingVideos);
                        onChangeSort('time');
                        onClearFilters();
                    }}
                    endpoint={`documents/uploadsingle/3?contactID=${contactID}`}
                >Videos</NwUpload>
            )
        }
    ]

    return (
        <>
            <HeaderContainer $isDrawer={isDrawer}>
                <div className="info-block">
                    {!isDrawer &&
                        <div className="back-icon" onClick={handleMoveBack}>
                            <NwIcon icon={solid('left-long')} />
                        </div>
                    }
                    <div className="model-info">
                        <div className="model-name">{getContactName(contactID)}</div>
                        {packageBook ? <div>{`${photoCount} PICTURES, ${videoCount} VIDEOS`}</div> :
                            <div>{`${photoCount} PICTURES, ${videoCount} VIDEOS, ${books.length} BOOKS`}</div>}
                    </div>
                </div>
                <div className="filter-block">
                    <FlexContainer mg="0 0 10px 16px">
                        <Label>Size</Label>
                        <ButtonGroup size="small" style={{ zIndex: "0" }}>
                            <NwButtonOption active={size === "small"} onClick={onChangeSize("small")}>S</NwButtonOption>
                            <NwButtonOption active={size === "medium"} onClick={onChangeSize("medium")}>M</NwButtonOption>
                            <NwButtonOption active={size === "large"} onClick={onChangeSize("large")} >L</NwButtonOption>
                            <NwButtonOption active={size === "xlarge"} onClick={onChangeSize("xlarge")} >XL</NwButtonOption>
                        </ButtonGroup>
                    </FlexContainer>
                    <FlexContainer>
                        <FlexContainer mg='0 16px'>
                            <Label>Sort By</Label>
                            <Select autoComplete='off' style={{ width: 160 }} value={sort} onChange={onChangeSort}>
                                <Option value="time">time</Option>
                                <Option value="myrating">my rating</Option>
                                <Option value="globalrating">global rating</Option>
                            </Select>
                        </FlexContainer>
                        <CustomSwitch checked={showFilters} onChange={showHideFilters} text='Filter' />
                    </FlexContainer>
                </div>
                {!packageBook && <div className="action-block">
                    <FlexContainer style={{ marginBottom: "15px" }}>
                        <div className="upload-icon">
                            <Dropdown menu={{ items: menuItems }}>
                                <NwIcon icon={solid("cloud-arrow-up")} />
                            </Dropdown>
                        </div>
                        <div className="book-icon" onClick={!selectedBook ? onOpenBookList : null}>
                            <NwIcon disabled={selectedBook} icon={solid("books")} />
                        </div>
                    </FlexContainer>
                    <CustomSwitch checked={isMediaSelectable} onChange={handleChangeMediaSelectable} text='MULTIPLE SELECTION' />
                </div>}
            </HeaderContainer>
            {showFilters &&
                <FilterContainer>
                    <FlexContainer mg="0 0 20px 0">
                        <CustomSwitch
                            checked={showMedia.isShowPictures}
                            onChange={val => handleUpdateShowMedia('picture', val)}
                            text='Pictures'
                        />
                        <CustomSwitch
                            checked={showMedia.isShowVideos}
                            onChange={val => handleUpdateShowMedia('video', val)}
                            text='Videos'
                            style={{ marginLeft: "30px" }}
                        />
                    </FlexContainer>
                    <div className="filter-row" style={{ marginBottom: "20px" }}>
                        <div className="individual-filter">
                            <div>Uploaded By</div>
                            <BookerSelector value={uploader} style={{ width: '100%' }} onChange={onChangeUploader} />
                        </div>
                        <div className="individual-filter">
                            <div>Tags</div>
                            <InputTag value={tags} contactType='Document' onChange={onUpdateTags} />
                        </div>
                    </div>
                    <div className="filter-row">
                        <div className="individual-filter">
                            <div>Uploaded Since</div>
                            <FilterDateInput allowClear value={uploadedSince} style={{ width: '100%' }} onChange={onChangeUploadedSince} />
                        </div>
                        <div className="individual-filter">
                            <div>Uploaded Until</div>
                            <FilterDateInput allowClear value={uploadedUntil} style={{ width: '100%' }} onChange={onChangeUploadedUntil} />
                        </div>
                    </div>
                </FilterContainer>
            }
            {uploadingPictures.length > 0 &&
                <UploadContainer>
                    {invalidUploadingPictures.map(file => {
                        return (
                            <StyledAlert key={file.uid} message={`${file.name} ${file.reason}`} type="error" showIcon />
                        )
                    })}
                    <Upload
                        name="files"
                        showUploadList={{
                            showRemoveIcon: false
                        }}
                        listType="picture"
                        multiple={true}
                        fileList={uploadingPictures}
                    />
                </UploadContainer>
            }
            {uploadingVideos.length > 0 &&
                <UploadContainer>
                    {invalidUploadingVideos.map(file => {
                        return (
                            <StyledAlert key={file.uid} message={`${file.name} ${file.reason}`} type="error" showIcon />
                        )
                    })}
                    <Upload
                        name="files"
                        showUploadList={{
                            showRemoveIcon: false
                        }}
                        listType="picture"
                        multiple={true}
                        fileList={uploadingVideos}
                    />
                </UploadContainer>
            }
        </>
    );
};

export default ModelMediaHeader;