import { getNow } from 'Libs/NwMoment';

export const MODELS_FILTERS_SET_BASE = {
    activeSet: ("v0"),
    filterType: ("SAVED_SEARCH"),
    customSets: ([]),
    staticSets: [
        {
            id: 'v0',
            name: 'all models',
            fixed: true,
            filters: []
        },
        {
            id: 'v1',
            name: 'models working today',
            fixed: true,
            filters: [
                { name: "WorkingOn", value: '{today_today}' }
            ]
        },
        {
            id: 'v2',
            name: 'in town',
            fixed: true,
            filters: [
                {
                    name: 'InTown',
                    value: true,
                },
            ],
        },
        {
            id: 'v3',
            name: 'my models',
            fixed: true,
            filters: [
                {
                    name: 'Booker',
                    value: '{me}',
                }
            ],
        },
        {
            id: 'v4',
            name: 'new search',
            default: true,
            fixed: true,
            filters: [],
        }
    ]
};

export const SCOUTING_MODELS_FILTERS_SET_BASE = {
    activeSet: ("v0"),
    filterType: ("SAVED_SEARCH"),
    customSets: ([]),
    staticSets: [
        {
            id: "v0",
            name: "all models",
            fixed: true,
            filters: []
        },
        {
            id: "v1",
            name: "added last month",
            fixed: true,
            filters: [
                { name: "AddedSince", value: getNow().subtract(1,'months').startOf('month') },
                { name: 'AddedUntil', value: getNow().subtract(1,'months').endOf('month') }
            ]
        },
        {
            id: 'v2',
            name: 'added by me',
            fixed: true,
            filters: [
                {
                    name: 'Scouter',
                    value: '{me}',
                },
            ],
        }
    ]
};

export const MODELS_FILTERS_SETS = {
    booking: MODELS_FILTERS_SET_BASE,
    scouting: SCOUTING_MODELS_FILTERS_SET_BASE,
    accounting: MODELS_FILTERS_SET_BASE,
    art: MODELS_FILTERS_SET_BASE,
    management: MODELS_FILTERS_SET_BASE
}

export const MODELS_FILTERS_SET_SEARCHES = {
    activeSet: ("v0"),
    customSets: ([]),
    staticSets: [
        {
            id: 'v0',
            name: 'all models',
            fixed: true,
            filters: []
        },
        {
            id: 'v1',
            name: 'models working today',
            fixed: true,
            filters: [
                { name: "WorkingOn", value: '{today_today}' }
            ]
        },
        {
            id: 'v2',
            name: 'in town',
            fixed: true,
            filters: [
                {
                    name: 'InTown',
                    value: true,
                },
            ],
        },
        {
            id: 'v3',
            name: 'my models',
            fixed: true,
            filters: [
                {
                    name: 'Booker',
                    value: '{me}',
                }
            ],
        }
    ]
};


export const MODELS_SEARCH_TYPES = {
    booking: { activeSet: ("v0"), filterType: ("SAVED_SEARCH") },
    scouting: { activeSet: ("v0"), filterType: ("SAVED_SEARCH") },
    accounting: { activeSet: ("v0"), filterType: ("SAVED_SEARCH") },
    art: { activeSet: ("v0"), filterType: ("SAVED_SEARCH") },
    management: { activeSet: ("v0"), filterType: ("SAVED_SEARCH") }
}