import React, { useEffect, useState } from 'react';
import { Alert, Divider, InputNumber } from 'antd';
import { NwLinkButton } from "Components/Gui/NwButton";
import Switch from 'Components/Gui/CustomSwitch';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import { ShowMultiTimePeriod, showDateNoTime, showPeriod } from 'Libs/NwMoment'
import { ModelSelectorContainer } from '../../../Project/ProjectTransactions/Transactions/ProjectTransactionsUI';
import ModelEventTypeTag from 'Components/EventDetails/ModelEventTypeTag';


const ModelSelectorWorkDates = ({ typeQuantity, models, updateModels }) => {  //onChangeForm

    const [showAllBookingEvents, setShowAllBookingEvents] = useState(false);
    const [groupWorkDays, setGroupWorkDays] = useState(false);
    const [allHours, setAllHours] = useState(8);
    const [allUnits, setAllUnits] = useState(1);
    const [activeItemsCount, setActiveItemsCount] = useState(0);


    useEffect(() => {
        console.log("models", models);
    }, [models]);

    const handleChangeGroupModelJobEvents = (active, groupModelIndex) => {
        let newModels = [...models]
        newModels[groupModelIndex].ActiveJobEvents = active
        if (active) {
            for (let i = 0; i < newModels[groupModelIndex].EventDates.length; i++) {
                newModels[groupModelIndex].EventDates[i].Active = false;
            }
        }
        updateModels(newModels);
    }

    const handleChangeGroupModelJobEventsQuantity = (quantity, groupModelIndex) => {
        let newModels = [...models] //JSON.parse(JSON.stringify(models));
        newModels[groupModelIndex].ActiveJobEventsQuantity = quantity;
        updateModels(newModels);
    };

    const handleChangeModelEventDate = (active, groupModelIndex, modelDate) => {
        let newModels = [...models] //JSON.parse(JSON.stringify(models));
        const modelDateIndex = newModels[groupModelIndex].EventDates.findIndex(eventDate => eventDate.EventDateID === modelDate.EventDateID);
        newModels[groupModelIndex].EventDates[modelDateIndex].Active = active;
        updateModels(newModels);
    };

    const handleChangeModelEventDateQuantity = (quantity, groupModelIndex, modelDate) => {
        let newModels = [...models] //JSON.parse(JSON.stringify(models));
        const modelDateIndex = newModels[groupModelIndex].EventDates.findIndex(eventDate => eventDate.EventDateID === modelDate.EventDateID);
        newModels[groupModelIndex].EventDates[modelDateIndex].Quantity = quantity;
        updateModels(newModels);
    };

    const handleChangeModelQuantity = (quantity, groupModelIndex) => {
        let newModels = [...models] //JSON.parse(JSON.stringify(models));
        newModels[groupModelIndex].Quantity = quantity;
        updateModels(newModels);
    };


    const handleSetAllHours = quantity => {
        let newModels = [...models] //JSON.parse(JSON.stringify(models));
        for (let modelIndex = 0; modelIndex < newModels.length; modelIndex++) {
            for (let i = 0; i < newModels[modelIndex].EventDates.length; i++) {
                newModels[modelIndex].EventDates[i].Quantity = quantity;
            }
        }
        updateModels(newModels);
    };

    const handleSetAllUnits = quantity => {
        let newModels = [...models] //JSON.parse(JSON.stringify(models));
        for (let modelIndex = 0; modelIndex < newModels.length; modelIndex++) {
            newModels[modelIndex].Quantity = quantity;
        }
        updateModels(newModels);
    };

    const handleShowAllBookingEvents = (value) => {
        if (value) {
            setGroupWorkDays(false);
        }
        setShowAllBookingEvents(value);
    };

    const getEventCount = (groupedModel) => {
        const confirmedJobEvents = groupedModel.EventDates.filter(eventDate => eventDate.EventType === 'Job' && eventDate.OptionNumber === 10 && !eventDate.Cancel);
        const optionEvents = groupedModel.EventDates.filter(eventDate => eventDate.EventType === 'Job' && !eventDate.Cancel && eventDate.OptionNumber < 10);
        const cancelledEvents = groupedModel.EventDates.filter(eventDate => eventDate.EventType === 'Job' && eventDate.Cancel);
        return `${confirmedJobEvents.length} confirmed jobs, ${optionEvents.length} option, ${cancelledEvents.length} cancelled events`;
    }

    const activateAllModel = (modelID) => {
        let newModels = [...models];
        const modelIndex = newModels.findIndex(model => model.ModelID === modelID);
        for (let i = 0; i < newModels[modelIndex].EventDates.length; i++) {
            newModels[modelIndex].EventDates[i].Active = true;
        }
        updateModels(newModels);
    }

    const deactivateAllModel = (modelID) => {
        let newModels = [...models];
        const modelIndex = newModels.findIndex(model => model.ModelID === modelID);
        for (let i = 0; i < newModels[modelIndex].EventDates.length; i++) {
            newModels[modelIndex].EventDates[i].Active = false;
        }
        updateModels(newModels);
    }

    return (
        <ModelSelectorContainer>
            <h1>Models in current project</h1>
            <div className="button-bar">
                <Switch text="Show also castings" checked={showAllBookingEvents} onChange={handleShowAllBookingEvents} />
                {typeQuantity === 'Hours' &&
                    <div className="set-hours">
                        set <InputNumber value={allHours} min={0.5} max={23.5} step={0.5} onChange={value => setAllHours(value)} /> hour(s) for all models <NwLinkButton primary size='small' label='apply' onClick={() => handleSetAllHours(allHours)} />
                    </div>
                }
                {typeQuantity === 'Units' &&
                    <div className="set-units">
                        set <InputNumber value={allUnits} step={1} onChange={value => setAllUnits(value)} /> unit(s) for all models <NwLinkButton primary size='small' label='apply' onClick={() => handleSetAllUnits(allUnits)} />
                    </div>
                }
                {typeQuantity === 'Days' &&
                    <div className="group-days">
                        <Switch text="Group work days in a single fee" disabled={showAllBookingEvents} checked={groupWorkDays} onChange={value => setGroupWorkDays(value)} />
                    </div>
                }
            </div>
            <div className="models">
                {typeQuantity === 'Units' &&
                    <Alert type="info" message="Models in bold are confirmed in at least a workdate" showIcon />
                }
                {models.map((groupedModel, index) => {
                    const jobEvents = groupedModel.EventDates.filter(eventDate => eventDate.EventType === 'Job');
                    const modelDates = (showAllBookingEvents) ? [...groupedModel.EventDates] : [...jobEvents];
                    return (
                        <div key={groupedModel.ModelID}>
                            {modelDates.length > 0 && typeQuantity === 'Units' &&
                                <div className={`model${groupedModel.AlwaysCancelled ? ' is-cancelled' : ''}${groupedModel.HasConfirmation ? ' is-confirmed' : ''}`}>
                                    <Switch
                                        text={getContactName(groupedModel.ModelID)}
                                        checked={groupedModel.ActiveJobEvents}
                                        onChange={value => handleChangeGroupModelJobEvents(value, index)} 
                                    />
                                    {groupedModel.ActiveJobEvents &&
                                        <div className='model-units-quantity'>
                                            <InputNumber
                                                value={groupedModel.Quantity}
                                                min={1}
                                                step={1}
                                                onChange={value => handleChangeModelQuantity(value, index)}
                                            /> items
                                        </div>
                                    }
                                </div>
                            }
                            {modelDates.length > 0 && typeQuantity === 'Flat' &&
                                <div className={`model${groupedModel.AlwaysCancelled ? ' is-cancelled' : ''}${groupedModel.HasConfirmation ? ' is-confirmed' : ''}`}>
                                    <Switch
                                        text={getContactName(groupedModel.ModelID)}
                                        checked={groupedModel.ActiveJobEvents}
                                        onChange={value => handleChangeGroupModelJobEvents(value, index)} />
                                </div>
                            }
                            {modelDates.length > 0 && typeQuantity === 'Days' && groupWorkDays &&
                                <div className='model'>
                                    <Switch
                                        text={getContactName(groupedModel.ModelID)}
                                        checked={groupedModel.ActiveJobEvents}
                                        onChange={value => handleChangeGroupModelJobEvents(value, index)}
                                    />
                                    {groupedModel.ActiveJobEvents &&
                                        <div className='event-details'>
                                            <div className='event-details-count'>
                                                <div>{getEventCount(groupedModel)}</div>
                                            </div>
                                            <div className='event-details-days-quantity'>
                                                <InputNumber
                                                    value={groupedModel.ActiveJobEventsQuantity}
                                                    min={0}
                                                    max={99}
                                                    step={0.5}
                                                    onChange={value => handleChangeGroupModelJobEventsQuantity(value, index, groupedModel)}
                                                /> days
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {modelDates.length > 0 && typeQuantity === 'Days' && !groupWorkDays &&
                                <div className='model'>
                                    <div className="model-name-wrapper">
                                        <h2>{getContactName(groupedModel.ModelID)}</h2>
                                        <div className="all-selectors">
                                            <NwLinkButton size="small" onClick={() => activateAllModel(groupedModel.ModelID)} label="select all" />
                                            <Divider type="vertical" />
                                            <NwLinkButton size="small" onClick={() => deactivateAllModel(groupedModel.ModelID)} label="deselect all" />
                                        </div>
                                    </div>
                                    {/* <h2>{getContactName(groupedModel.ModelID)}</h2>
                                    <div className="button-bar button-bar-mini">
                                        <NwLinkButton onClick={() => activateAllModel(groupedModel.ModelID)} label="select all" />
                                        <Divider type="vertical" />
                                        <NwLinkButton onClick={() => deactivateAllModel(groupedModel.ModelID)} label="deselect all" />
                                    </div> */}
                                    {modelDates.map(modelDate => {
                                        return (
                                            <div className="model-date" key={`${groupedModel.ModelID}-${modelDate.EventDateID}`}>
                                                <Switch
                                                    disabled={groupedModel.ActiveJobEvents}
                                                    checked={modelDate.Active}
                                                    onChange={value => handleChangeModelEventDate(value, index, modelDate)}
                                                />
                                                <div className="event-details">
                                                    <div className={`event-details-name${modelDate.Cancel ? ' is-cancelled' : ''}`}>
                                                        <ModelEventTypeTag event={modelDate} />
                                                        <div>{modelDate.EventName}</div>
                                                    </div>
                                                    <div className='event-details-date'>
                                                        {showDateNoTime(modelDate.FromDate)}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            {modelDates.length > 0 && typeQuantity === 'Hours' &&
                                <div className='model'>
                                    <div className="model-name-wrapper">
                                        <h2>{getContactName(groupedModel.ModelID)}</h2>
                                        <div className="all-selectors">
                                            <NwLinkButton size="small" onClick={() => activateAllModel(groupedModel.ModelID)} label="select all" />
                                            <Divider type="vertical" />
                                            <NwLinkButton size="small" onClick={() => deactivateAllModel(groupedModel.ModelID)} label="deselect all" />
                                        </div>
                                    </div>
                                    {modelDates.map(modelDate => {
                                        return (
                                            <div className="model-date" key={`${groupedModel.ModelID}-${modelDate.EventDateID}`}>
                                                <Switch
                                                    disabled={groupedModel.ActiveJobEvents}
                                                    checked={modelDate.Active}
                                                    onChange={value => handleChangeModelEventDate(value, index, modelDate)}
                                                />
                                                <div className='event-details'>
                                                    <div className={`event-details-name${modelDate.Cancel ? ' is-cancelled' : ''}`}>
                                                        <ModelEventTypeTag event={modelDate} />
                                                        <div>{modelDate.EventName}</div>
                                                    </div>
                                                    <div className='event-details-date'>
                                                        {(modelDate.StartBreak && modelDate.EndBreak)
                                                            ?
                                                            <ShowMultiTimePeriod event={modelDate} />
                                                            :
                                                            showPeriod(modelDate.FromDate, modelDate.ToDate)
                                                        }
                                                    </div>
                                                    {modelDate.Active &&
                                                        <div className='event-details-hours-quantity'>
                                                            <InputNumber
                                                                value={modelDate.Quantity}
                                                                min={0.1}
                                                                max={24}
                                                                step={0.01}
                                                                onChange={value => handleChangeModelEventDateQuantity(value, index, modelDate)}
                                                            /> hours
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        </ModelSelectorContainer>
    )
};

export default ModelSelectorWorkDates;