import React from 'react';
import styled from 'styled-components';
import { PageHeader } from 'antd';

import { removeStatementLog } from 'Libs/NwLogs';
import { getContactName } from 'Hooks/Contact/UseContactsList';
import StyleVariables from 'Components/Gui/StyleVariables';
import NwDropDownMenu from 'Components/Gui/NwDropDownMenu';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDeleteInvoice } from "Hooks/Invoices/UseInvoices";

const InvoiceHeader = styled.div`
    z-index: 1030;
    top: ${props => props.$inDrawer ? "0" : "5rem"};
    width: 100%;
    position: ${props => props.$inDrawer ? "relative" : "sticky"};

    .ant-page-header {
        margin: 1rem;
        padding: 0;
        border-bottom: ${props => props.theme.DefaultBorder};
        height: 4rem;
        
        .invoice-header-wrapper {
            padding: 10px 0 0 340px;

            span.invoice-label {
                position: absolute;
                top: 0;
                left: 0;
                width: 316px;
                padding: 1rem 1.5rem;
                font-weight: 300;
                height: 4rem;
                font-size: ${props => props.theme.FontSizeSm};
                text-transform: uppercase;
                border-right: ${props => props.theme.DefaultBorder};
            }

            h1 {
                font-size: ${props => props.theme.FontSizeLg};
            }
        }

        .ant-page-header-heading-extra {
            padding: 10px 20px 0 0;
        }
    }

    .confirm-button {
        color: ${StyleVariables.PrimaryColor};
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
        font-size: 14px;
        font-weight: 500;
    }
`;

const InvoicePageHeader = ({
    invoiceData,
    handleShowEditDrawer,
    inDrawer,
    locked,
    onDelete
}) => {
    ///const { data : invoiceData } = useInvoice(invoiceId);
    const { mutateAsync: onDeleteInvoice } = useDeleteInvoice();


    const onDeleteWholeInvoice = async () => {
        try {
            await onDeleteInvoice(invoiceData.ID);
            removeStatementLog(invoiceData.ID);
            onDelete()
        } catch (e) {
            console.log("error deleting", e);
        }
    }



    const getInvoiceLabel = () => {
        if (invoiceData.TypeName === "Model") {
            return "Model Expenses Invoice"
        }
        if (invoiceData.TypeName === "Agency") {
            return "Moth. Ag. Comm. Invoice"
        }
        return "CLient Invoice"
    }

    return (
        <InvoiceHeader $inDrawer={inDrawer}>
            <PageHeader
                title={
                    <div className="invoice-header-wrapper">
                        <span className="invoice-label">
                            {getInvoiceLabel()}
                        </span>
                        <h1>
                            <strong>{getContactName(invoiceData.ContactID)}</strong> <em>/</em> {invoiceData.Name}
                        </h1>
                    </div>
                }
                subTitle={false}
                extra={[
                    <NwDropDownMenu
                        size="small"
                        key="extra"
                        actions={[
                            { 
                                name: "",
                                key: "edit",
                                disabled: locked,
                                pinned: true,
                                icon: light("pen"),
                                action: () => handleShowEditDrawer()
                            },
                            { 
                                name: "Delete",
                                key: "delete",
                                disabled: locked,
                                action: () => onDeleteWholeInvoice()
                            },
                            { 
                                name: "Create Credit Note",
                                disabled: true,
                                key: "creditnote",
                                action: () => { }
                            },
                        ]}
                    />
                ]}
            >
            </PageHeader>
        </InvoiceHeader>
    )
}

export default InvoicePageHeader;
